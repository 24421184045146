import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import './EmpDash.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import { NavLink } from "react-router-dom";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import { removeUserSession } from '../../../Utils/Common';

const EmpDash = () => {
	const [loading,setLoading] = useState(true);
	const [inputs,setInputs] = useState({employee_name:'',employee_email:'',employee_image:'',order_total:'',order_last_week_total:'',order_last_month_total:'',income_forecast_total:'',income_forecast_last_week_total:'',income_forecast_last_month_total:''});
   const [commissionhistory,setCommissionhistory] = useState([]);
   
useEffect(() => {
      document.title = `Employee Dashbord | Blue Faucet`;
      document.getElementById('bodywrap').className='Homepgmain mdsbrdpgmain edsbord eds grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });
	
	/* Start get dashboard */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('employee_dashboard', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			  setInputs({
				  employee_name:response.data.data.employee_name,
				  employee_email:response.data.data.employee_email,
				  employee_image:response.data.data.employee_image,
				  order_total:response.data.data.order_total,
				  order_last_week_total:response.data.data.order_last_week_total,
				  order_last_month_total:response.data.data.order_last_month_total,
				  income_forecast_total:response.data.data.income_forecast_total,
				  income_forecast_last_week_total:response.data.data.income_forecast_last_week_total,
				  income_forecast_last_month_total:response.data.data.income_forecast_last_month_total,
			  });
			 setCommissionhistory(response.data.data.commission_history);
			}			  
		}).catch(error => {
			 
		});  
	 /* End get dashboard */
	
},[]) 

/* Start logout */
const logout = () => {
    removeUserSession();
		window.location.href = "/";
	}
/* End logout */
 
    return (
        <>
        {/* ========== Employee Dashbord Page Start ========== */}
            <section className="gen-wrap">
        {/* ========== Navbar Start ========== */}
              <Navbar/>
        {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="mx-0 mianhmdwncnt">
                   <Col className="mnhmcnt">
        {/* ========== Employee Dashbord Content Start ========== */}
                    <NavLink to="/emp-profile-after-login">
                     <Row className="mx-0 edsbrdtp">
                       <Col md={2} xs={2} className="img-cnt"><img src= {inputs.employee_image} className="img-fluid" alt="Mcust Img"/></Col>
                       <Col md={8} xs={8} className="text-cnt">
                         <h3>{inputs.employee_name}</h3>
                         <p>{inputs.employee_email}</p>
						 
						 <p className="mt-2"><NavLink to="#" className="fnav-link efnav-link" onClick={logout}><img src={process.env.REACT_APP_BASE_URL + '/assets/images/svg/sidbar-icn9.svg'} className="img-fluid" alt="Side Nav Icon"/> Logout</NavLink></p>
                       </Col>
                       <Col md={2} xs={2} className="edt-icn text-end"><img src= {'assets/images/employee/edt-icn.png'} className="img-fluid" alt="Mcust Img"/></Col>
                     </Row>
                    </NavLink>
        {/* ========== Employee Dashbord 4 box Content Start ========== */}
                   <Row xs={{ cols:2, gutter: 2 }} lg={{ cols: 2, gutter: 3}} className="mt-2">

                    <Col>
                      <Card className="maindvmdsbrd">
                      <Card.Body className="p-0">
                          <Row className="rw mx-0">
                      <Col md={12} xs={12} className="mdtp-toptxt"><h5>Orders / Appointment</h5></Col>
                          <Col md={3} xs={4} className="mdtp-lft text-center">
                          <img src="assets/images/merchant/svg/mdsbrd-icn1.svg" className="img-fluid" alt="LOGO IMG"/></Col>
                          <Col md={9} xs={8} className="mdtp-rgt">
                           <h3>{inputs.order_total}</h3>
                           <NavLink to="/emp-order"><p>New Orders / Appointments</p></NavLink>
                          </Col>
                          <div className="mbrdr-spce my-2"></div>
                          <Col md={12} xs={12} className="mdcnt-lft">
                          <NavLink to="/emp-order-appointment-statistics"><p>Orders / Appointments Statistics</p></NavLink>
                          </Col>
                          
                          </Row>
                      </Card.Body>
                      {/*<Card.Footer className="text-center border-0 p-0 grfdv">
                        <img src="assets/images/merchant/svg/grph-img1.svg" className="img-fluid" alt="Graph Img"/>
                      </Card.Footer>*/}
                      </Card>
                    </Col>

                    <Col>
                      <Card className="maindvmdsbrd">
                      <Card.Body className="p-0">
                          <Row className="rw mx-0">
                      <Col md={12} xs={12} className="mdtp-toptxt"><h5>Income & Forecast</h5></Col>
                          <Col md={3} xs={4} className="mdtp-lft text-center"><img src="assets/images/merchant/svg/mdsbrd-icn2.svg" className="img-fluid" alt="LOGO IMG"/></Col>
                          <Col md={9} xs={8} className="mdtp-rgt">
                            <h3>${inputs.income_forecast_total}</h3>
                          </Col>
                          <div className="mbrdr-spce my-2"></div>
                          <Col md={12} xs={12} className="mdcnt-lft"><NavLink to="/emp-income-forecast"><p>Income & Forecast</p></NavLink></Col>
                          
                          </Row>
                      </Card.Body>
                      {/*<Card.Footer className="text-center border-0 p-0 grfdv">
                      <img src="assets/images/merchant/svg/grph-img2.svg" className="img-fluid" alt="Graph Img"/>
                      </Card.Footer>*/}
                      </Card>
                    </Col>
                    </Row>
        {/* ========== Employee Dashbord 4 box Content End ========== */}
        {/* ========== Employee Dashbord Customer box Content Start ========== */}
                    {commissionhistory.length >0 && (
					<div>
					<Col md={12} xs={12} className="edsbrdhist mt-3">
                        <p>Commission History</p>
                    </Col>
					{commissionhistory && commissionhistory.map((val,index) => {
						return(
                    <Col md={12} xs={12} className="mcusbx">
                        <Row className="mx-0">
                          <Col md={2} xs={2} className="align-self-center px-1"><img src= {val.image} className="img-fluid" alt="Mcust Img"/></Col>
                          <Col md={6} xs={7} className="align-self-center mcusbx-txtcnt ps-md-0 ">
                            <h3>{val.name}</h3>
                            <p>{val.email}</p>
                          </Col>
                          <Col md={4} xs={3} className="align-self-center text-end">
						  <div className="demo">
							${val.commission}
						  </div>
							  {/*<div className="demo">
                             <select>
                                <option>Normal</option>
                                <option>Vip</option>
                                <option>Block</option>
                             </select>
							  </div>*/}
                          </Col>
                        </Row>
                      </Col>
                       )
					  })} 
</div>					  
                    )}  
      {/* ========== Employee Dashbord Customer box Content End ========== */}
      {/* ========== Employee Dashbord Content End ========== */}
                     </Col>
                  </Row>
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )}  
               </Container>
            </section>
      {/* ========== Navbar Start ========== */}
            <Footer/>
      {/* ========== Navbar End ========== */}
      {/* ========== Employee Dashbord Page End ========== */}
        </>
    )
}
export default EmpDash;