import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Card, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerSubscr.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardForm } from "./CardForm";
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';
import {validatelogin} from '../../../Utils/validate';

const MerSubscr = () => {
	const [showcancellogin, setShowCancelLogin] = useState(false);
const [showcancel, setShowCancel] = useState(false);
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);

const [showfree, setShowfree] = useState(false);
const handleClosefree = () => setShowfree(false);

const [loading,setLoading] = useState(true);

const [subscription, setSubscription] = useState([]);

const [inputs,setInputs] = useState({price:0,subscription_id:'',plan_id:0});

const [inputslogin,setInputsLogin] = useState({email_or_phone:'',password:''});

const [stripeset,setStripeset] = useState('');

const [inputsact,setInputsact] = useState({active_plan:false,plan_name:'',paln_image:'',plan_price:'',day_left:'',plan_id:'',subscription_id:'',status:1,end_date:''});
const [errors,setErrors] = useState({});

const [values, setValues] = useState({
        password: "",
        showPassword: false,
    });

useEffect(() => {
      document.title = `Merchant Subscription | Blue Faucet`;
      document.getElementById('bodywrap').className='msbsrptnpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
	/* Start get subscription */
	let formData = new FormData(); 
	  formData.append('token', localStorage.getItem("token"));
		API.post('get_subscription_plan', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				var active_plan=false;
				if(response.data.data.active_plan===1)
				{
					active_plan=true;
				}
			  setInputsact({active_plan:active_plan,plan_name:response.data.data.plan_name,paln_image:response.data.data.paln_image,plan_price:response.data.data.plan_price,day_left:response.data.data.day_left,plan_id:response.data.data.plan_id,subscription_id:response.data.data.subscription_id,status:response.data.data.status,end_date:response.data.data.end_date,recurring:response.data.data.recurring});
			  setSubscription(response.data.data.subscription);
			 
			}                  
		}).catch(error => {
			 
		});  
	/* End get subscription */
	/* Start get stripe details */
	let formData1 = new FormData(); 
		formData1.append('token', localStorage.getItem("token"));
		API.post('get_keys', formData1)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				const stripePromise = loadStripe(response.data.data.admin_stripe_public_key);
					setStripeset(stripePromise);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get stripe details */
	
},[])  


const handleShowfree = (id) => {
	if(inputsact.status===0){
	setLoading(true);
	setShowfree(true);
	let formData = new FormData(); 
	formData.append('id', id);
	API.post('get_subscription_plan_id', formData)
		.then(response => {
			setLoading(false);
		if(response.data.status === 0){
			
		}else{
		   setInputs({price:response.data.data.price,subscription_id:response.data.data.subscription_id,plan_id:response.data.data.plan_id});
		}                  
	}).catch(error => {
		 
	});  
	}
}
const handleShow = (id) => {
	if(inputsact.status===0){
		setLoading(true);
		setShow(true);
		let formData = new FormData(); 
		formData.append('id', id);
		API.post('get_subscription_plan_id', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			   setInputs({price:response.data.data.price,subscription_id:response.data.data.subscription_id,plan_id:response.data.data.plan_id});
			}                  
		}).catch(error => {
			 
		});  
	}
}
 const handleSubmit = async () => {
	let formData = new FormData();
		formData.append('token', localStorage.getItem("token"));
		formData.append('subscription_id', inputs.subscription_id);
		formData.append('amount',inputs.price);
		formData.append('plan_id',inputs.plan_id);
		
		API.post('subscription_payment', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				//alert(response.data.message);
				gettoastwarning(response.data.message,'');
				setLoading(false); 
			}else{
				//setLoading(false);
				//alert(response.data.message);
				//window.location.href = "/mer-subscription";
				setShowfree(false);
			   gettoastsuccess(response.data.message,'/mer-subscription');
			}                  
		}).catch(error => {
			 setLoading(false);
			  //alert("Something went wrong. Please try again later.");
			  gettoastwarning("Something went wrong. Please try again later.",'');
		});  
 }
  const cancel_data = async () => {
	  let formData = new FormData();
		formData.append('token', localStorage.getItem("token"));
		formData.append('subscription_id', inputsact.subscription_id);

		API.post('cancel_subscription', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				//alert(response.data.message);
				gettoastwarning(response.data.message,'');
				setLoading(false); 
			}else{
				//setLoading(false);
				//alert(response.data.message);
				//window.location.href = "/mer-subscription";
				gettoastsuccess(response.data.message,'/mer-subscription');
			}                  
		}).catch(error => {
			 setLoading(false);
			  //alert("Something went wrong. Please try again later.");
			  gettoastwarning("Something went wrong. Please try again later.",'');
		});  
  }
  const cancel_confirm = async () => {
		setShowCancel(true);
  }
	const handleCloseCancel = () => setShowCancel(false);
	
	const handleCancelLogin  = async () => {
			setShowCancel(false);
			setShowCancelLogin(true);
	  }
	const handleCloseCancelLogin = () => setShowCancelLogin(false);

/* Start Value set */
const handleInputChange = (event) => {
  event.persist();
  setInputsLogin(inputslogin => ({...inputslogin, [event.target.name]: event.target.value}));       
}
/* End Value set */

/* Start Password Visible */
 const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
 }
/* End Password Visible */
	
//Start Login check
const handleSubmitLogin = (event) => {
	event.preventDefault();
	
	const validationErrors = validatelogin(inputslogin);
	const noErrors = Object.keys(validationErrors).length === 0;

	setErrors({});
	setErrors(validationErrors);
	if(noErrors){
		setLoading(true);
		let email_or_phone=inputslogin.email_or_phone;
		let password=inputslogin.password;
		
		let formData = new FormData(); 
		formData.append('email_or_phone', email_or_phone);
		formData.append('password', password);
		formData.append('type', 'merchant');
		API.post('login', formData)
			.then(response => {
				
			if(response.data.status === 0){
				gettoastwarning('Your ID and Password do not match our record. Please try again.','');
				setLoading(false); 
			}else{
			   
			   setLoading(true);
			   //console.log(response.data.data);
			   
			  cancel_data();
			}                  
		}).catch(error => {
			 setLoading(false);
			 gettoastwarning("Something went wrong. Please try again later.",'');
		});  
	}
}
//End Login check
	
    return (
      <>
      {/* ========== Merchant Subscription Page Start ========== */}
            <section className="gen-wrap">
			<ToastContainer />
      {/* ========== Navbar Start ========== */}
               <Navbar headerText={'My Subscription'}/>
      {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="mx-0 mianhmdwncnt">
      {/* ========== Subscription Box Start ========== */}
                     <Col md={12} xs={12} className="mnhmcnt my-3">
					{inputsact.active_plan && (
					<>
					 <Col md={12} xs={12} className="mainttl-subtn mb-3"><h3>Active Plan</h3></Col>
                     <Col md={12} xs={12} className="py-2">
                     
                        <Row className="mx-0 ucl-box">
                          <Col md={1} xs={2} className="align-self-center px-0 text-center"><NavLink to="/mer-subscription-history"><img src= {inputsact.paln_image} className="img-fluid" alt="Sld Img"/></NavLink></Col>
                          <Col md={8} xs={7} className="align-self-center px-3 px-md-3">
						  
                          <h3>{inputsact.plan_name}</h3>
                          <p>{inputsact.day_left}<span> ${inputsact.plan_price}</span></p>
						  
						  <p>The next membership recurrent charge date: {inputsact.end_date}</p>
						  <p>Recurrent setting: {inputsact.recurring}</p>
						  <NavLink to="/mer-subscription-history" className="mt-2 btn-disabled">Payment history</NavLink>
                          </Col>
                          <Col md={3} xs={3} className="align-self-center text-end px-0">
							  {inputsact.subscription_id!=='' && (
							  <>
						  <NavLink to="#" onClick={() => cancel_confirm()}><Button className="blc" variant="">Cancel</Button></NavLink>
						  </>
							  )}
							  
							  
						  </Col>
                     </Row>
                     
                     </Col>
					 </>
					)}
                     <Col md={12} xs={12} className="mainttl-subtn my-3">
                     <h3>Subscription Plan </h3>
                     </Col>
                     <Row xs={{ cols:1, gutter: 2 }} lg={{ cols: 2, gutter: 3}} className="mt-2">
					 
					 {subscription.map((val) => {
                      return (
                     <Col className="mb-4">
                     <NavLink to="#">
                        <Card className="msubtn-mainbox">
                           <Card.Body>
                              <Card.Title><h3>{val.name}</h3></Card.Title>
                              <img src= {val.image} className="img-fluid" alt="Sld Img"/>
                              <Col>
                                 <h4>${val.price} </h4>
                                 <ul>
                                    <li className="chekk"> <span>{val.detail[0].days}</span></li>
									{val.detail[0].restriction!=='' && (
										<li className="chekk"> <span>{val.detail[0].restriction}</span></li>
									)}
									
									{val.detail[0].customer!=='' && (
										<li className="chekk"> <span>{val.detail[0].customer}</span></li>
									)}
                                    {val.detail[0].employee_setup!=='' && (
										<li className="chekk"> <span>{val.detail[0].employee_setup}</span></li>
									)}
									{val.detail[0].new_employee_setup!=='' && (
										<li className="chekk"> <span>{val.detail[0].new_employee_setup}</span></li>
									)}
									{val.detail[0].auto!=='' && (
										<li className="chekk"> <span>{val.detail[0].auto}</span></li>
									)}
                                 </ul>
								 <p className="mt-4 sub-text-note">{val.detail[0].description}</p>
                              </Col>
                           </Card.Body>
                           
						   {inputsact.plan_id===val.id ?(
						   <Card.Footer>
						   <Button className="active" variant="" type="submit">Active</Button>
						    </Card.Footer>
						   ):(
						   <Card.Footer>
						   {val.price==='0.00' ?(
                              <Button variant="" type="Button" onClick={() => handleShowfree(val.id)}>Purchase</Button>
						   ):(
								<Button variant="" type="Button" onClick={() => handleShow(val.id)}>Purchase</Button>
						   )}
						   </Card.Footer>
						   )}
                           
                        </Card>
                     </NavLink>
                     </Col>
					 )
					})
					}
                     
                  </Row>
                  </Col>
      {/* ========== Subscription Box End ========== */}
                  <Col className="popupmsunscptn">
      {/* ========== Subscription Plan Popup Start ========== */}
                 <Modal show={show} onHide={handleClose} className="popupwrngcnt">
                        <Modal.Header closeButton>
                           <h3 className="mdlttltxt">Subscription Plan</h3>
                        </Modal.Header>
                        <Modal.Body>
                           <Col md={12} xs={12} className="ppinpt">
                           <h3 className="sbsrptn-tital">Price : ${inputs.price}</h3>
                           <Form>
						   <Elements stripe={stripeset}>
								<CardForm data={inputs} />
							</Elements>
                              
                           </Form>
                           </Col>
                          
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                     </Modal>
      {/* ========== Subscription Plan Popup End ========== */}
	  {/* ========== Subscription Plan Free Popup Start ========== */}
                 <Modal show={showfree} onHide={handleClosefree} className="popupwrngcnt">
                        <Modal.Header closeButton>
                           <h3 className="mdlttltxt">Subscription Plan</h3>
                        </Modal.Header>
                        <Modal.Body>
                           <Col md={12} xs={12} className="ppinpt">
                           
                           <Form>
							<Row className="pp-btn1 mx-0">
                              <Col><NavLink to="#" onClick={() => handleSubmit()}><Button className="blc" variant="">Pay Now</Button></NavLink></Col>
                           </Row>
                              
                           </Form>
                           </Col>
                          
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                     </Modal>
      {/* ========== Subscription Plan Free Popup End ========== */}
	  {/* ========== Cancel Popup Start ========== */}
                    <Modal show={showcancel} onHide={handleCloseCancel} className="popupwrngcnt">
                       <Modal.Header>
							<p>Please note that non-subscribers can only retain up to 50 members in your membership; the rest will not be kept in the database, and there is no way to restore them back. </p>
                          
                       </Modal.Header>
                       <Modal.Body>
                          <h3 className="mdlttltxt mb-4">Do you want to continue?</h3>
						  <Row>
						  
                             <Col className="btn-clr">
                             <NavLink to="#" onClick={handleCancelLogin}><Button variant="">Yes</Button></NavLink>
                             </Col>
                             <Col className="btn-dv">
                             <NavLink to="#" onClick={handleCloseCancel}><Button variant="">No</Button></NavLink>
                             </Col>
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Cancel Popup End ========== */}
				  {/* ========== Cancel Login Popup Start ========== */}
                    <Modal show={showcancellogin} onHide={handleCloseCancelLogin} className="popupwrngcnt">
                       <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Login</h3>
                       </Modal.Header>
                       <Modal.Body>
                          <Row>
                             <Col>
							 
								<Form method="post" onSubmit={handleSubmitLogin} enctype="multipart/form-data">
								   <Form.Group className="mb-3">
									  <Form.Label>Email or Phone<span>*</span></Form.Label>
									  <Form.Control type="text" name="email_or_phone" placeholder="Enter email or phone number" onChange={handleInputChange} value={inputslogin.email_or_phone} autoComplete="off"  />
									  {errors.email_or_phone && (<p className="text-danger">{errors.email_or_phone}</p>)}
								   </Form.Group>
								   <Form.Group className="mb-3 pswinpt">
									  <Form.Label>Password<span>*</span></Form.Label>
									  
									  <Form.Control type={values.showPassword ? "text" : "password"} name="password" id="password" placeholder="At least 8 characters" onChange={handleInputChange} value={inputslogin.password} autoComplete="off" />
									  {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
									  {errors.password && (<p className="text-danger">{errors.password}</p>)}
								   </Form.Group>
								   
								   <div className="mb-3 btn-dv">
									  <Button type="submit">Login</Button>
								   </div>
								   
								</Form>
                             </Col>
                            
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Cancel Login Popup End ========== */}
                  </Col>
                  </Row>
				  
				  
				  {loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
               </Container>
            </section>
      {/* ========== Footer Start ========== */}
            <Footer/>
      {/* ========== Footer End ========== */}
      {/* ========== Merchant Subscription Page End ========== */}
        </>
    )
}

export default MerSubscr;