import React, { useEffect, useState,useRef } from 'react';
import { Row, Col, Container, Button, Form, Modal} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './MerProfile.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { MultiSelect } from "react-multi-select-component";
import loader from '../../../assests/loader.gif';
import API from '../../../Utils/Api';
import PlacesAutocomplete, { geocodeByAddress,getLatLng  } from 'react-places-autocomplete';
import {validateprofilemer,validatelogin} from '../../../Utils/validate';
import 'intl-tel-input/build/css/intlTelInput.css'; // Import the CSS
import intlTelInput from 'intl-tel-input';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';

const MerProfile = () => {
	const [showdeletelogin, setShowDeleteLogin] = useState(false);
	const [showdelete, setShowDelete] = useState(false);
	const [inputslogin,setInputsLogin] = useState({email_or_phone:'',password:''});
	
const inputRef = useRef(null);
const navigate = useNavigate();	
const [loading,setLoading] = useState(true);
const [loading1,setLoading1] = useState(false);
const [othertext,setOtherText] = useState(false);

//const [locationchk,setLocationchk] = useState(true);

const [image, setImage] = useState({preview: '', raw: ''});
const [inputs,setInputs] = useState({store_name:'',email:'',store_owner_name:'',store_location:'',street_name:'',area:'',state:'',city:'',zip_code:'',lat:'',long:'',country_code:'91',country_short_code:'in',store_phone_number:'',business_category:'',other_text:'',store_policy:'',sales_tax_rate:'',password:'',password_confirmation:'',accepted_per_time_slot:0,auto_accept_during_working_hours:'',auto_refuse:0});
const [image1, setImage1] = useState({preview: '', raw: ''});
const [image2, setImage2] = useState({preview: '', raw: ''});
const [image3, setImage3] = useState({preview: '', raw: ''});
const [image4, setImage4] = useState({preview: '', raw: ''});
const [image5, setImage5] = useState({preview: '', raw: ''});


const [bsc,setBsc] = useState(true);
const [subcategory, setSubcategory] = useState([]);
const [optionSelected, setSelected] = useState([]);

const [optionSelectedval, setSelectedval] = useState([]);


const [category, setCategory] = useState([]);
  
const [show2, setShow2] = useState(false);
const [isChecked, setIsChecked] = useState(false);
const [isSunChecked, setSunIsChecked] = useState(false);
const [isMonChecked, setMonIsChecked] = useState(false);
const [isTueChecked, setTueIsChecked] = useState(false);
const [isWedChecked, setWedIsChecked] = useState(false);
const [isThuChecked, setThuIsChecked] = useState(false);
const [isFriChecked, setFriIsChecked] = useState(false);
const [isSatChecked, setSatIsChecked] = useState(false);

const [address1, setAddress1] = useState("");

const [inputsf, setInputsF] = useState({day:'sun',start_hours:'12',start_minutes:'00',end_hours:'12',end_minutes:'00',status:0});
const [inputsSunday, setInputsSunday] = useState({day:'sunday',sunstart_hours:'12',sunstart_minutes:'00',sunend_hours:'12',sunend_minutes:'00',status:0});
const [inputsMonday, setInputsMonday] = useState({day:'monday',monstart_hours:'12',monstart_minutes:'00',monend_hours:'12',monend_minutes:'00',status:0});
const [inputsTuesday, setInputsTuesday] = useState({day:'tuesday',tuestart_hours:'12',tuestart_minutes:'00',tueend_hours:'12',tueend_minutes:'00',status:0});
const [inputsWednesday, setInputsWednesday] = useState({day:'wednesday',wedstart_hours:'12',wedstart_minutes:'00',wedend_hours:'12',wedend_minutes:'00',status:0});
const [inputsThursday, setInputsThursday] = useState({day:'thursday',thustart_hours:'12',thustart_minutes:'00',thuend_hours:'12',thuend_minutes:'00',status:0});
const [inputsFriday, setInputsFriday] = useState({day:'friday',fristart_hours:'12',fristart_minutes:'00',friend_hours:'12',friend_minutes:'00',status:0});
const [inputsSaturday, setInputsSaturday] = useState({day:'saturday',satstart_hours:'12',satstart_minutes:'00',satend_hours:'12',satend_minutes:'00',status:0});


const [error,setError] = useState();
const [success,setSuccess] = useState();
const [errors,setErrors] = useState({});

const [values, setValues] = useState({password: "",showPassword: false,});
const [values1, setValues1] = useState({password: "",showPassword: false,});
const searchOptions = {
    componentRestrictions: { country: 'us' }
  };
const [values2, setValues2] = useState({
        password: "",
        showPassword: false,
    });
useEffect(() => {
      document.title = `Merchant Store Setup | Blue Faucet`;
      document.getElementById('bodywrap').className='strstppgmain grenicn grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
	/* Start get profile */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_profile', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				
				getcat(response.data.data.category_id);
				handleChangeGet(response.data.data.sub_cat);
				handleHourPro(response.data.data.hour);
				setImage({
					preview: response.data.data.image,
					raw: ''
				});
				setImage1({
					preview: response.data.data.image1,
					raw: ''
				});
				setImage2({
					preview: response.data.data.image2,
					raw: ''
				});
				setImage3({
					preview: response.data.data.image3,
					raw: ''
				});
				setImage4({
					preview: response.data.data.image4,
					raw: ''
				});
				setImage5({
					preview: response.data.data.image5,
					raw: ''
				});
				setInputs({
				  store_name: response.data.data.user_name,
				  email: response.data.data.email,
				  store_owner_name: response.data.data.name,
				  store_location: response.data.data.store_location,
				  street_name: response.data.data.address,
				  area: response.data.data.area,
				  state:response.data.data.state,
				  city:response.data.data.city,
				  zip_code:response.data.data.zip_code,
				  lat:response.data.data.lat,
				  long:response.data.data.long,
				  country_code:response.data.data.country_code,
				  country_short_code:response.data.data.country_short_code,
				  store_phone_number:response.data.data.mobile,
				  business_category:response.data.data.category_id,
				  other_text:response.data.data.other_text,
				  store_policy:response.data.data.store_policy,
				  sales_tax_rate:response.data.data.sales_tax_rate,
				  password:'',
				  password_confirmation:'',
				  accepted_per_time_slot:response.data.data.accepted_per_time_slot,
				  auto_accept_during_working_hours:response.data.data.auto_accept_during_working_hours,
				  auto_refuse:response.data.data.auto_refuse
			  });
			  setAddress1(response.data.data.address);
			  
			  if(response.data.data.store_location==="Store's Location"){
				  //setLocationchk(true);
			  }else{
				  //setLocationchk(false);
			  }
			  
			  const input = inputRef.current;
					const iti = intlTelInput(input, {
						initialCountry:response.data.data.country_short_code,
					  separateDialCode:true,
					});
					
					// Listen for changes
					input.addEventListener('countrychange', () => {
					  const selectedCountryData = iti.getSelectedCountryData();
					  var country_code_lbl='country_code';
					  setInputs(inputs => ({...inputs, [country_code_lbl]: selectedCountryData.dialCode}));
					  var country_short_code_lbl='country_short_code';
					  setInputs(inputs => ({...inputs, [country_short_code_lbl]: selectedCountryData.iso2}));
					});

					// Clean up when the component unmounts
					return () => {
					  iti.destroy();
					};
			  
			}                  
		}).catch(error => {
			 
		});  
	 /* End get profile */
	
	
	
	/* Start get category */
		API.get('category')
			.then(response => {
				
			if(response.data.status === 0){
				
			}else{
			  setCategory(response.data.data);
			}                  
		}).catch(error => {
			 
		});  
	/* End get state */
},[]) 

/* Start Value set */
  const handleInputChange = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));       
    }
 
	
	const handleInputChangeLoc = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));  
		if(event.target.value==="Store's Location")
		{
			//setLocationchk(true);
		}else{
			
			//setLocationchk(false);
		}
    }
	const handleInputChangeSlot = (event) => {
      event.persist();
	  var slot = (typeof event.target.value !== 'undefined' && event.target.value !== '' && !isNaN(event.target.value)) ? parseFloat(event.target.value) : 0;
      if(slot >0)
	  {
		setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
      }else{
			setInputs(inputs => ({...inputs, [event.target.name]: 4}));
	  }		  
    }
  /* End Value set */
/* Start image set */
const handleChangeImg = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		 setImage({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}
/* Start image set */
/* Start image set */
const handleChange1 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImage1({
				preview: URL.createObjectURL(event.target.files[0]),
				raw: event.target.files[0]
			});
	}
}
const handleChange2 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
	  setImage2({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}
const handleChange3 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImage3({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}
const handleChange4 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImage4({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}
const handleChange5 = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		  setImage5({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}
/* End image set */
/* Start Select Subcategory */
const handleChangeGet = (selected: Option[]) => {
	setSelected(selected);
	setSelectedval([]);
	selected.forEach((item) => {
		
		setSelectedval(optionSelectedval => [...optionSelectedval,item.value] );
		if(item.label==='Other')
		{
			setOtherText(true);
		}
	});
  };
const handleChange = (selected: Option[]) => {
	setSelected(selected);
	setSelectedval([]);
	setOtherText(false);
	var other_text_lbl='other_text';
	var other_text='';
	  setInputs(inputs => ({...inputs, [other_text_lbl]: other_text}));
	selected.forEach((item) => {
		
		setSelectedval(optionSelectedval => [...optionSelectedval,item.value] );
		if(item.label==='Other')
		{
			setOtherText(true);
		}
	});
  };
/* End Select Subcategory */

/* Start get subcategory */
function getcat(val) {
     setBsc(true);
	 var catlbl="business_category";
	 setInputs(inputs => ({...inputs, [catlbl]: val}));
	 
	    let formData = new FormData(); 
		formData.append('category_id', val);
		API.post('subcategories',formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			  setSubcategory(response.data.subcategories);
			}                  
		}).catch(error => {
			 
		});       
}
/* End get subcategory */

/* Start Working hours Modal open and close */
const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);
/* End Working hours Modal open and close */

/* Start working hour checked */
const handleCheckboxChange = (event) => {
	setIsChecked(!isChecked);
	var lbl='status';
	if(event.target.checked)
	{
		
		setInputsF(inputsf => ({...inputsf, [lbl]: 1}));
	}else{
		
		setInputsF(inputsf => ({...inputsf, [lbl]: 0}));
		
		
	}
};
const handleSunCheckboxChange = (event) => {
	setSunIsChecked(!isSunChecked);
	var sunlbl='status';
	if(event.target.checked)
	{
		
		setInputsSunday(inputsSunday => ({...inputsSunday, [sunlbl]: 1}));
	}else{
		
		setInputsSunday(inputsSunday => ({...inputsSunday, [sunlbl]: 0}));
		
		var starthlbl='sunstart_hours';
		var start_hours='';
		setInputsSunday(inputsSunday => ({...inputsSunday, [starthlbl]: start_hours})); 
		
		var startmlbl='sunstart_minutes';
		var start_minutes='';
		setInputsSunday(inputsSunday => ({...inputsSunday, [startmlbl]: start_minutes}));
		
		var endhlbl='sunend_hours';
		var end_hours='';
		setInputsSunday(inputsSunday => ({...inputsSunday, [endhlbl]: end_hours}));
		
		var endmlbl='sunend_minutes';
		var end_minutes='';
		setInputsSunday(inputsSunday => ({...inputsSunday, [endmlbl]: end_minutes}));
		
	}
};
const handleMonCheckboxChange = (event) => {
	setMonIsChecked(!isMonChecked);
	var monlbl='status';
	if(event.target.checked)
	{
		
		setInputsMonday(inputsMonday => ({...inputsMonday, [monlbl]: 1}));
	}else{
		setInputsMonday(inputsMonday => ({...inputsMonday, [monlbl]: 0}));
		
		var starthlbl='monstart_hours';
		var start_hours='';
		setInputsMonday(inputsMonday => ({...inputsMonday, [starthlbl]: start_hours})); 
		
		var startmlbl='monstart_minutes';
		var start_minutes='';
		setInputsMonday(inputsMonday => ({...inputsMonday, [startmlbl]: start_minutes}));
		
		var endhlbl='monend_hours';
		var end_hours='';
		setInputsMonday(inputsMonday => ({...inputsMonday, [endhlbl]: end_hours}));
		
		var endmlbl='monend_minutes';
		var end_minutes='';
		setInputsMonday(inputsMonday => ({...inputsMonday, [endmlbl]: end_minutes}));
		
	}
};
const handleTueCheckboxChange = (event) => {
	setTueIsChecked(!isTueChecked);
	var tuelbl='status';
	if(event.target.checked)
	{
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [tuelbl]: 1}));
	}else{
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [tuelbl]: 0}));
		
		var starthlbl='tuestart_hours';
		var start_hours='';
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [starthlbl]: start_hours})); 
		
		var startmlbl='tuestart_minutes';
		var start_minutes='';
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [startmlbl]: start_minutes}));
		
		var endhlbl='tueend_hours';
		var end_hours='';
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [endhlbl]: end_hours}));
		
		var endmlbl='tueend_minutes';
		var end_minutes='';
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [endmlbl]: end_minutes}));
	}
};
const handleWedCheckboxChange = (event) => {
	setWedIsChecked(!isWedChecked);
	var wedlbl='status';
	if(event.target.checked)
	{
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [wedlbl]: 1}));
	}else{
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [wedlbl]: 0}));
		
		var starthlbl='wedstart_hours';
		var start_hours='';
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [starthlbl]: start_hours})); 
		
		var startmlbl='wedstart_minutes';
		var start_minutes='';
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [startmlbl]: start_minutes}));
		
		var endhlbl='wedend_hours';
		var end_hours='';
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [endhlbl]: end_hours}));
		
		var endmlbl='wedend_minutes';
		var end_minutes='';
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [endmlbl]: end_minutes}));  
	}
};
const handleThuCheckboxChange = (event) => {
	setThuIsChecked(!isThuChecked);
	var thulbl='status';
	if(event.target.checked)
	{
		setInputsThursday(inputsThursday => ({...inputsThursday, [thulbl]: 1}));
	}else{
		setInputsThursday(inputsThursday => ({...inputsThursday, [thulbl]: 0}));
		
		var starthlbl='thustart_hours';
		var start_hours='';
		setInputsThursday(inputsThursday => ({...inputsThursday, [starthlbl]: start_hours})); 
		
		var startmlbl='thustart_minutes';
		var start_minutes='';
		setInputsThursday(inputsThursday => ({...inputsThursday, [startmlbl]: start_minutes}));
		
		var endhlbl='thuend_hours';
		var end_hours='';
		setInputsThursday(inputsThursday => ({...inputsThursday, [endhlbl]: end_hours}));
		
		var endmlbl='thuend_minutes';
		var end_minutes='';
		setInputsThursday(inputsThursday => ({...inputsThursday, [endmlbl]: end_minutes})); 
	}
};
const handleFriCheckboxChange = (event) => {
	setFriIsChecked(!isFriChecked);
	var frilbl='status';
	if(event.target.checked)
	{
		setInputsFriday(inputsFriday => ({...inputsFriday, [frilbl]: 1}));
	}else{
		setInputsFriday(inputsFriday => ({...inputsFriday, [frilbl]: 0}));
		
		var starthlbl='fristart_hours';
		var start_hours='';
		setInputsFriday(inputsFriday => ({...inputsFriday, [starthlbl]: start_hours})); 
		
		var startmlbl='fristart_minutes';
		var start_minutes='';
		setInputsFriday(inputsFriday => ({...inputsFriday, [startmlbl]: start_minutes}));
		
		var endhlbl='friend_hours';
		var end_hours='';
		setInputsFriday(inputsFriday => ({...inputsFriday, [endhlbl]: end_hours}));
		
		var endmlbl='friend_minutes';
		var end_minutes='';
		setInputsFriday(inputsFriday => ({...inputsFriday, [endmlbl]: end_minutes}));
	}
};
const handleSatCheckboxChange = (event) => {
	setSatIsChecked(!isSatChecked);
	var satlbl='status';
	if(event.target.checked)
	{
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [satlbl]: 1}));
	}else{
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [satlbl]: 0}));
		
		var starthlbl='satstart_hours';
		var start_hours='';
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [starthlbl]: start_hours})); 
		
		var startmlbl='satstart_minutes';
		var start_minutes='';
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [startmlbl]: start_minutes}));
		
		var endhlbl='satend_hours';
		var end_hours='';
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [endhlbl]: end_hours}));
		
		var endmlbl='satend_minutes';
		var end_minutes='';
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [endmlbl]: end_minutes}));
	}
};
const handleFInputChange = (event) => {
  event.persist();
  setInputsF(inputsf => ({...inputsf, [event.target.name]: event.target.value}));       
}
const handleSunInputChange = (event) => {
  event.persist();
  setInputsSunday(inputsSunday => ({...inputsSunday, [event.target.name]: event.target.value})); 
  /*if(event.target.name==='sunstart')
  {
	var sustartlbl='start';
	setInputsSunday(inputs => ({...inputs, [sustartlbl]: event.target.value})); 
  }else if(event.target.name==='sunend')
  {
	  var suendlbl='end';
	  setInputsSunday(inputs => ({...inputs, [suendlbl]: event.target.value})); 
  }*/
        
}
const handleMonInputChange = (event) => {
  event.persist();
  setInputsMonday(inputsMonday => ({...inputsMonday, [event.target.name]: event.target.value})); 
  /*if(event.target.name==='monstart')
  {
	var mostartlbl='start';
	setInputsMonday(inputs => ({...inputs, [mostartlbl]: event.target.value}));
  }else if(event.target.name==='monend')
  {
	  var monendlbl='end';
	  setInputsMonday(inputs => ({...inputs, [monendlbl]: event.target.value}));
  }*/
         
}
const handleTueInputChange = (event) => {
  event.persist();
  setInputsTuesday(inputsTuesday => ({...inputsTuesday, [event.target.name]: event.target.value})); 
  /*if(event.target.name==='tuestart')
  {
	var tustartlbl='start';
	setInputsTuesday(inputs => ({...inputs, [tustartlbl]: event.target.value}));
  }else if(event.target.name==='tueend')
  {
	  var tuendlbl='end';
	  setInputsTuesday(inputs => ({...inputs, [tuendlbl]: event.target.value}));
  }*/
         
}
const handleWedInputChange = (event) => {
  event.persist();
  setInputsWednesday(inputsWednesday => ({...inputsWednesday, [event.target.name]: event.target.value}));
  /*if(event.target.name==='wedstart')
  {
	var westartlbl='start';
	setInputsWednesday(inputs => ({...inputs, [westartlbl]: event.target.value}));
  }else if(event.target.name==='wedend')
  {
	  var weendlbl='end';
	  setInputsWednesday(inputs => ({...inputs, [weendlbl]: event.target.value}));
  }*/
         
}
const handleThuInputChange = (event) => {
  event.persist();
  setInputsThursday(inputsThursday => ({...inputsThursday, [event.target.name]: event.target.value}));
  /*if(event.target.name==='thustart')
  {
	var thstartlbl='start';
	setInputsThursday(inputs => ({...inputs, [thstartlbl]: event.target.value})); 
  }else if(event.target.name==='thuend')
  {
	  var tuendlbl='end';
	  setInputsThursday(inputs => ({...inputs, [tuendlbl]: event.target.value})); 
  } */      
}
const handleFriInputChange = (event) => {
  event.persist();
  setInputsFriday(inputsFriday => ({...inputsFriday, [event.target.name]: event.target.value}));
  /*if(event.target.name==='fristart')
  {
	var frstartlbl='start';
	setInputsFriday(inputs => ({...inputs, [frstartlbl]: event.target.value})); 
  }else if(event.target.name==='friend')
  {
	  var frendlbl='end';
	  setInputsFriday(inputs => ({...inputs, [frendlbl]: event.target.value})); 
  }*/
        
}
const handleSatInputChange = (event) => {
  event.persist();
  setInputsSaturday(inputsSaturday => ({...inputsSaturday, [event.target.name]: event.target.value}));
  /*if(event.target.name==='satstart')
  {
	var sastartlbl='start';
	setInputsSaturday(inputs => ({...inputs, [sastartlbl]: event.target.value})); 
  }else if(event.target.name==='satend')
  {
	  var satendlbl='end';
	  setInputsSaturday(inputs => ({...inputs, [satendlbl]: event.target.value})); 
  }*/
        
}
const handleHourPro = (val) => {
   
	val.forEach((item) => {
		if(item.day==='sunday')
		{
			const sunval= {
				day:'sunday',
				sunstart_hours:item.start_hr,
				sunstart_minutes:item.start_min,
				sunend_hours:item.end_hr,
				sunend_minutes:item.end_min,
				status:item.status
			  };
			setInputsSunday(sunval);
			if(item.status===1){
				setSunIsChecked(item.status);
			}
		}
		if(item.day==='monday')
		{
			const sunval= {
				day:'monday',
				monstart_hours:item.start_hr,
				monstart_minutes:item.start_min,
				monend_hours:item.end_hr,
				monend_minutes:item.end_min,
				status:item.status
			  };
			setInputsMonday(sunval);
			if(item.status===1){
				setMonIsChecked(item.status);
			}
		}
		if(item.day==='tuesday')
		{
			const sunval= {
				day:'tuesday',
				tuestart_hours:item.start_hr,
				tuestart_minutes:item.start_min,
				tueend_hours:item.end_hr,
				tueend_minutes:item.end_min,
				status:item.status
			  };
			setInputsTuesday(sunval);
			if(item.status===1){
				setTueIsChecked(item.status);
			}
		}
		if(item.day==='wednesday')
		{
			const sunval= {
				day:'wednesday',
				wedstart_hours:item.start_hr,
				wedstart_minutes:item.start_min,
				wedend_hours:item.end_hr,
				wedend_minutes:item.end_min,
				status:item.status
			  };
			setInputsWednesday(sunval);
			if(item.status===1){
				setWedIsChecked(item.status);
			}
		}
		if(item.day==='thursday')
		{
			const sunval= {
				day:'thursday',
				thustart_hours:item.start_hr,
				thustart_minutes:item.start_min,
				thuend_hours:item.end_hr,
				thuend_minutes:item.end_min,
				status:item.status
			  };
			setInputsThursday(sunval);
			if(item.status===1){
				setThuIsChecked(item.status);
			}
		}
		if(item.day==='friday')
		{
			const sunval= {
				day:'friday',
				fristart_hours:item.start_hr,
				fristart_minutes:item.start_min,
				friend_hours:item.end_hr,
				friend_minutes:item.end_min,
				status:item.status
			  };
			setInputsFriday(sunval);
			if(item.status===1){
				setFriIsChecked(item.status);
			}
		}
		if(item.day==='saturday')
		{
			const sunval= {
				day:'saturday',
				satstart_hours:item.start_hr,
				satstart_minutes:item.start_min,
				satend_hours:item.end_hr,
				satend_minutes:item.end_min,
				status:item.status
			  };
			setInputsSaturday(sunval);
			if(item.status===1){
				setSatIsChecked(item.status);
			}
		}
	});
	
  };
/* End working hour checked */
/* Start get lat lng */
 const handleSelect = async (addres) => {
	 let street_namelbl='street_name';
	setInputs(inputs => ({...inputs, [street_namelbl]: addres}));
    setAddress1(addres);
	 try {
      const results = await geocodeByAddress(addres);
	  let zip_codelbl='zip_code';
	  setInputs(inputs => ({...inputs, [zip_codelbl]: ''}));
	  const postalCodeComponent = results[0].address_components.find(component => component.types.includes('postal_code'));
        if (postalCodeComponent) {
          
		  
		  let zip_code=postalCodeComponent.short_name;
		 
			setInputs(inputs => ({...inputs, [zip_codelbl]: zip_code}));
         }
	  
	  let area_lbl='area';
	  let city_lbl='city';
		let state_lbl='state';
		setInputs(inputs => ({...inputs, [area_lbl]: ''}));
		setInputs(inputs => ({...inputs, [city_lbl]: ''}));
		setInputs(inputs => ({...inputs, [state_lbl]: ''}));
		for (var i = 0; i < results[0].address_components.length; i++) {
			
			  for (var j = 0; j < results[0].address_components[i].types.length; j++) {
				 
				 
				 if (results[0].address_components[i].types[j] === "route") {
				  
				  let area=results[0].address_components[i].long_name;
					setInputs(inputs => ({...inputs, [area_lbl]: area}));
				}
				 
				if (results[0].address_components[i].types[j] === "locality") {
				  
				  let city=results[0].address_components[i].long_name;
					setInputs(inputs => ({...inputs, [city_lbl]: city}));
				}
				if (results[0].address_components[i].types[j] === "administrative_area_level_1") {
				  
				  let state=results[0].address_components[i].long_name;
					setInputs(inputs => ({...inputs, [state_lbl]: state}));
				}
			  }
		}
	  
	  
      const latLng = await getLatLng(results[0]);
      let latitudelbl='lat';
	  let longitudelbl='long';
	  let lat=latLng.lat;
	  let lng=latLng.lng;
	   setInputs(inputs => ({...inputs, [latitudelbl]: lat}));
	   setInputs(inputs => ({...inputs, [longitudelbl]: lng}));
    } catch (error) {
    }
 }
/* End get lat lng */
/* Start Submit Sign */
	const handleSubmit = (event) => {
		event.preventDefault();
		const validationErrors = validateprofilemer(inputs,optionSelectedval,othertext,image,inputsSunday,inputsMonday,inputsTuesday,inputsWednesday,inputsThursday,inputsFriday,inputsSaturday);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setSuccess('');
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let store_name=inputs.store_name;
			let email=inputs.email;
			let store_owner_name=inputs.store_owner_name;
			let store_location=inputs.store_location;
				let street_name=inputs.street_name;
				let lat=inputs.lat;
				let long=inputs.long;
				let area=inputs.area;
			    let state=inputs.state;
			   let city=inputs.city;
			   let zip_code=inputs.zip_code;
	
			let country_code=inputs.country_code;
			let country_short_code=inputs.country_short_code;
			let store_phone_number=inputs.store_phone_number;
			let business_category=inputs.business_category;
			let store_policy=inputs.store_policy;
			let sales_tax_rate=inputs.sales_tax_rate;
			let password=inputs.password;
			
			let password_confirmation=inputs.password_confirmation;
		   
			let accepted_per_time_slot=inputs.accepted_per_time_slot;
			let auto_accept_during_working_hours=inputs.auto_accept_during_working_hours;
			let auto_refuse=inputs.auto_refuse;
			let other_text=inputs.other_text;
			
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('store_name', store_name);
			formData.append('email', email);
			formData.append('store_owner_name', store_owner_name);
			formData.append('store_location', store_location);
			formData.append('street_name', street_name);
			formData.append('area', area);
			formData.append('state',state);
			formData.append('city', city);
			formData.append('zip_code', zip_code);
			formData.append('lat', lat);
			formData.append('long', long);
			formData.append('country_code', country_code);
			formData.append('country_short_code', country_short_code);
			formData.append('store_phone_number', store_phone_number);
			formData.append('business_category', business_category);
			formData.append('other_text', other_text);
			formData.append('store_policy', store_policy);
			formData.append('sales_tax_rate', sales_tax_rate);
			formData.append('subcategory', optionSelectedval);
			formData.append('password', password);
			formData.append('password_confirmation', password_confirmation);
			formData.append('accepted_per_time_slot', accepted_per_time_slot);
			formData.append('auto_accept_during_working_hours', auto_accept_during_working_hours);
			formData.append('auto_refuse', auto_refuse);
			formData.append('sunday', JSON.stringify(inputsSunday));
			formData.append('monday', JSON.stringify(inputsMonday));
			formData.append('tuesday', JSON.stringify(inputsTuesday));
			formData.append('wednesday', JSON.stringify(inputsWednesday));
			formData.append('thursday', JSON.stringify(inputsThursday));
			formData.append('friday', JSON.stringify(inputsFriday));
			formData.append('saturday', JSON.stringify(inputsSaturday));
			
			if(image.raw!=='')
			{
				formData.append('profile_image', image.raw);
			}
			if(image1.raw!=='')
			{
				formData.append('image1', image1.raw);
			}
			if(image2.raw!=='')
			{
				formData.append('image2', image2.raw);
			}
			if(image3.raw!=='')
			{
				formData.append('image3', image3.raw);
			}
			if(image4.raw!=='')
			{
				formData.append('image4', image4.raw);
			}
			if(image5.raw!=='')
			{
				formData.append('image5', image5.raw);
			}
			API.post('update_marchant_detail', formData)
                .then(response => {
					setLoading(false);
                if(response.data.status === 0){
                    setError(response.data.message);
                    setLoading(false); 
                }else{
					//setLoading(false); 
					//alert(response.data.message);
					//window.location.href = "/mer-profile";
					gettoastsuccess(response.data.message,'/mer-profile');
				   //navigate('/mer-profile');
                }                  
            }).catch(error => {
                 setLoading(false);
				  setError("Something went wrong. Please try again later.");
			});  
		}
   } 
  /* End Submit Sign */
const handleOnChangerefuse = (event) => {
	var refuselbl="auto_refuse";
	if(event.target.checked)
	{	
		setInputs(inputs => ({...inputs, [refuselbl]: 1}));
	}else{
		setInputs(inputs => ({...inputs, [refuselbl]: 0}));
	}
}
 /* Start Phone Number */
const formatAsPhoneNumber = (event) => {
	event.persist();
	var value=event.target.value;
	value = value.replace(/ /g, '');
	value = value.replace(/\D/g, '');
	if (value.length > 10) {
		value = value.slice(0, 10);
	}
	
	if(value.length > 3 && value.length < 7)
	{
	    value = value.slice(0, 3) + ' ' + value.slice(3, 6);
	}else if(value.length > 6)
	{
		
		value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6);
	}
	setInputs(inputs => ({...inputs, [event.target.name]: value})); 
}
/* End Phone Number */

/* Start Password Visible */
const handleClickShowPassword = () => {setValues({ ...values, showPassword: !values.showPassword });}
const handleClickShowPassword1 = () => {setValues1({ ...values1, showPassword: !values1.showPassword });}
/* End Password Visible */

const handleSetVal = () => {
	
	var start_hours=inputsf.start_hours;
	var start_minutes=inputsf.start_minutes;
	var end_hours=inputsf.end_hours;
	var end_minutes=inputsf.end_minutes;
	
	setSunIsChecked(true);
	setMonIsChecked(true);
	setTueIsChecked(true);
	setWedIsChecked(true);
	setThuIsChecked(true);
	setFriIsChecked(true);
	setSatIsChecked(true);
	
	var sunlbl='status';
	setInputsSunday(inputsSunday => ({...inputsSunday, [sunlbl]: 1}));
	
	var monlbl='status';
	setInputsMonday(inputsMonday => ({...inputsMonday, [monlbl]: 1}));
	
	var tuelbl='status';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [tuelbl]: 1}));
	
	var wedlbl='status';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [wedlbl]: 1}));
	
	var thulbl='status';
	setInputsThursday(inputsThursday => ({...inputsThursday, [thulbl]: 1}));
	
	var frilbl='status';
	setInputsFriday(inputsFriday => ({...inputsFriday, [frilbl]: 1}));
	
	var satlbl='status';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [satlbl]: 1}));
	
	var starthlbl='sunstart_hours';
	setInputsSunday(inputsSunday => ({...inputsSunday, [starthlbl]: start_hours})); 
	
	var startmlbl='sunstart_minutes';
	setInputsSunday(inputsSunday => ({...inputsSunday, [startmlbl]: start_minutes}));
	
	
	var endhlbl='sunend_hours';
	setInputsSunday(inputsSunday => ({...inputsSunday, [endhlbl]: end_hours}));
	
	var endmlbl='sunend_minutes';
	setInputsSunday(inputsSunday => ({...inputsSunday, [endmlbl]: end_minutes}));
	
	
	
	var starthlbl1='monstart_hours';
	setInputsMonday(inputsMonday => ({...inputsMonday, [starthlbl1]: start_hours})); 
	
	var startmlbl1='monstart_minutes';
	setInputsMonday(inputsMonday => ({...inputsMonday, [startmlbl1]: start_minutes}));
	
	var endhlbl1='monend_hours';
	setInputsMonday(inputsMonday => ({...inputsMonday, [endhlbl1]: end_hours}));
	
	var endmlbl1='monend_minutes';
	setInputsMonday(inputsMonday => ({...inputsMonday, [endmlbl1]: end_minutes}));
	
	
	var starthlbl2='tuestart_hours';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [starthlbl2]: start_hours})); 
	
	var startmlbl2='tuestart_minutes';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [startmlbl2]: start_minutes}));
	
	var endhlbl2='tueend_hours';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [endhlbl2]: end_hours}));
	
	var endmlbl2='tueend_minutes';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [endmlbl2]: end_minutes}));
	
	
	var starthlbl3='wedstart_hours';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [starthlbl3]: start_hours})); 
	
	var startmlbl3='wedstart_minutes';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [startmlbl3]: start_minutes}));
	
	
	var endhlbl3='wedend_hours';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [endhlbl3]: end_hours}));
	
	var endmlbl3='wedend_minutes';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [endmlbl3]: end_minutes}));
	
	
	var starthlbl4='thustart_hours';
	setInputsThursday(inputsThursday => ({...inputsThursday, [starthlbl4]: start_hours})); 
	
	var startmlbl4='thustart_minutes';
	setInputsThursday(inputsThursday => ({...inputsThursday, [startmlbl4]: start_minutes}));
	
	
	var endhlbl4='thuend_hours';
	setInputsThursday(inputsThursday => ({...inputsThursday, [endhlbl4]: end_hours}));
	
	var endmlbl4='thuend_minutes';
	setInputsThursday(inputsThursday => ({...inputsThursday, [endmlbl4]: end_minutes}));
	
	
	var starthlbl5='fristart_hours';
	setInputsFriday(inputsFriday => ({...inputsFriday, [starthlbl5]: start_hours})); 
	
	var startmlbl5='fristart_minutes';
	setInputsFriday(inputsFriday => ({...inputsFriday, [startmlbl5]: start_minutes}));
	
	
	var endhlbl5='friend_hours';
	setInputsFriday(inputsFriday => ({...inputsFriday, [endhlbl5]: end_hours}));
	
	var endmlbl5='friend_minutes';
	setInputsFriday(inputsFriday => ({...inputsFriday, [endmlbl5]: end_minutes}));
	
	
	var starthlbl6='satstart_hours';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [starthlbl6]: start_hours})); 
	
	var startmlbl6='satstart_minutes';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [startmlbl6]: start_minutes}));
	
	
	var endhlbl6='satend_hours';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [endhlbl6]: end_hours}));
	
	var endmlbl6='satend_minutes';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [endmlbl6]: end_minutes}));
}


const delete_data = async () => {
	  let formData = new FormData();
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', 'merchant');
		API.post('delete_account', formData)
			.then(response => {
				setLoading1(false);
			if(response.data.status === 0){
			
				gettoastwarning(response.data.message,'');
				//setLoading(false); 
			}else{
				gettoastsuccess(response.data.message,'/mer-dashboard');
			}                  
		}).catch(error => {
			 setLoading1(false);
			  //alert("Something went wrong. Please try again later.");
			  gettoastwarning("Something went wrong. Please try again later.",'');
		}); 
  }

  const delete_confirm = async () => {
		setShowDelete(true);
  }
	const handleCloseDelete = () => setShowDelete(false);
	
	const handleDeleteLogin  = async () => {
			setShowDelete(false);
			setShowDeleteLogin(true);
	  }
	const handleCloseDeleteLogin = () => setShowDeleteLogin(false);

/* Start Value set */
const handleInputChangeLogin = (event) => {
  event.persist();
  setInputsLogin(inputslogin => ({...inputslogin, [event.target.name]: event.target.value}));       
}
/* End Value set */

/* Start Password Visible */
 const handleClickShowPassword2 = () => {
        setValues2({ ...values2, showPassword: !values2.showPassword });
 }
/* End Password Visible */
	
//Start Login check
const handleSubmitLogin = (event) => {
	event.preventDefault();
	const validationErrors = validatelogin(inputslogin);
	const noErrors = Object.keys(validationErrors).length === 0;

	setErrors({});
	setErrors(validationErrors);
	if(noErrors){
		setLoading1(true);
		let email_or_phone=inputslogin.email_or_phone;
		let password=inputslogin.password;
		
		let formData = new FormData(); 
		formData.append('email_or_phone', email_or_phone);
		formData.append('password', password);
		formData.append('type', 'merchant');
		API.post('login', formData)
			.then(response => {
				
			if(response.data.status === 0){
				gettoastwarning('Your ID and Password do not match our record. Please try again.','');
				setLoading1(false); 
			}else{
			   
			   //setLoading(false);
			   //console.log(response.data.data); 
			  delete_data();
			}                  
		}).catch(error => {
			 setLoading1(false);
			 gettoastwarning("Something went wrong. Please try again later.",'');
		});  
	}
}
//End Login check


    return (
        <>
      {/* ========== Merchant Store Setup Page Start ========== */}
         <section className="gen-main">
		 <ToastContainer />
      {/* ========== Navbar Start ========== */}
         <Row className="maincntmrcnt mx-0">
            <Col xs={2} md={2} className="align-self-center px-0">
            <button onClick={() =>
               navigate(-1)} className="back-btn">
               <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
               </svg>
            </button>
            </Col>
            <Col xs={8} md={8} className="align-self-center text-center">
            <h3>Store Setup</h3>
            </Col>
            <Col xs={2} md={2} className="align-self-center">
            <p></p>
            </Col>
         </Row>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row md={12} className="genscrnmrcnt">
               <Col>
               <Row md={12} className="maincntsrcnmrcnt">
                  <Col className="px-0">
      {/* ========== Profile Upload Content Start ========== */}
	              {error && (
					<div className="alert alert-danger">
					  {error}
					</div>)}
				  {success && (
					<div className="alert alert-success">
					  {success}
					</div>)}
                  <Col className="prflupld">
				  <Form.Label className="text-center w-100">Store Logo</Form.Label>
                  <div className="avatar-upload">
                     <div className="avatar-edit">
                        <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={handleChangeImg}/>
                        <label htmlFor="imageUpload"></label>
                     </div>
                     <div className="avatar-preview">
                        <div >
						{image.preview ? (
                          <> 
							<img src={image.preview} id="imagePreview" className="img-fluid img-mer-pro" alt="Logo"/>
						  </>
							) : (
							  <>
							<img src="assets/images/merchant/usrbgup.png" id="imagePreview" className="img-fluid img-mer-pro" alt="Logo"/>
						</>
                          )}
						</div>
						
                     </div>
					 
                  </div>
				  {errors.image && (<p className="text-danger">{errors.image}</p>)}
                  </Col>
      {/* ========== Profile Upload Content Start ========== */}
                  <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                     <Form.Group className="mb-3">
                        <Form.Label>Store Name<span>*</span></Form.Label>
                        <Form.Control type="text" name="store_name" placeholder="Enter your store name" onChange={handleInputChange} value={inputs.store_name} />
						{errors.store_name && (<p className="text-danger">{errors.store_name}</p>)}
                     </Form.Group>
                     <Form.Group className="mb-3">
                        <Form.Label>Enter Email<span>*</span></Form.Label>
                        <Form.Control type="text" name="email" placeholder="Enter your Email ID" onChange={handleInputChange} value={inputs.email} />
						{errors.email && (<p className="text-danger">{errors.email}</p>)}
					 </Form.Group>
                     <Form.Group className="mb-3">
                        <Form.Label>Store Owner Name<span>*</span></Form.Label>
                        <Form.Control type="text" name="store_owner_name" placeholder="Enter store owner name" onChange={handleInputChange} value={inputs.store_owner_name}/>
						{errors.store_owner_name && (<p className="text-danger">{errors.store_owner_name}</p>)}
					 </Form.Group>
                     <Form.Group className="mb-3">
                        <Form.Label>Service Location<span>*</span></Form.Label>
                        <Form.Select name="store_location" className="mb-2" aria-label="Default select example" value={inputs.store_location} onChange={handleInputChangeLoc}>

                           <option value="Store's Location">Store's Location</option>
                           <option value="Customer's Location">Customer's Location</option>
                        </Form.Select>
						{errors.store_location && (<p className="text-danger">{errors.store_location}</p>)}
						
                        <Form.Group className="mb-2">
                           
							<PlacesAutocomplete value={address1} onChange={setAddress1}  onSelect={handleSelect} searchOptions={searchOptions}>
							  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div>
								  
								  <Form.Control type="location" {...getInputProps({ placeholder: 'Enter address' })} placeholder="Number and Street Name" />
								  <div>
									{loading && <div>Loading...</div>}
									{suggestions.map((suggestion) => {
									  const style = {
										backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
									  };
									  return (
										<div {...getSuggestionItemProps(suggestion, { style })}>
										  {suggestion.description}
										</div>
									  );
									})}
								  </div>
								</div>
							  )}
							</PlacesAutocomplete>
							{errors.street_name && (<p className="text-danger">{errors.street_name}</p>)}
						</Form.Group>
                        <Form.Group className="mb-2">
                           <Form.Control name="area" placeholder="Area/apt" value={inputs.area} onChange={handleInputChange}/>
						   {errors.area && (<p className="text-danger">{errors.area}</p>)}
                        </Form.Group>
						
                         <Form.Control  type="text" className="mb-2" name="city" placeholder="City" value={inputs.city} onChange={handleInputChange}/>
						
						{errors.city && (<p className="text-danger">{errors.city}</p>)}
						 <Form.Control  type="text" className="mb-2" name="state" placeholder="State" value={inputs.state} onChange={handleInputChange}/>
						{errors.state && (<p className="text-danger">{errors.state}</p>)}
						
                        <Form.Group>
                           <Form.Control name="zip_code" placeholder="Zip code" value={inputs.zip_code} onChange={handleInputChange}/>
						   {errors.zip_code && (<p className="text-danger">{errors.zip_code}</p>)}
                        </Form.Group>
                     </Form.Group>
                     <Form.Group className="mb-3">
                        <Form.Label>Store Phone Number<span>*</span></Form.Label>
                        
						<Form.Control type="tel" name="store_phone_number" id="store_phone_number" placeholder="000 000 0000" ref={inputRef} onChange={formatAsPhoneNumber} value={inputs.store_phone_number}/>
						{errors.store_phone_number && (<p className="text-danger">{errors.store_phone_number}</p>)}
					 </Form.Group>
      {/* ========== Select Business Category Content Start ========== */}
                     <Form.Label>Select Business Category<span>*</span></Form.Label>
					 {
				category.length >0 &&(
                     <OwlCarousel items={2.4}  className='owl-theme mb-3 mstrstpsld' margin={10} >
					 {category.map((val) => {
                        return (
                        <div className="csrd1" onClick={() => getcat(val.id)}>
                           <div className="slctbox mer-prof">
                              <div className="checkbox">
                                 <label className="checkbox-wrapper">
                                 <input className="checkbox-input" type="radio" name="slctbtnstrstp" id="one" checked={inputs.business_category === val.id} />
                                 <span className="checkbox-tile">
                                 <span className="checkbox-icon">
                                 <img src={val.image} className="img-fluid" alt="Sld Img"/>
                                 </span>
                                 <span className="checkbox-label">{val.name}</span>
                                 </span>
                                 </label>
                              </div>
                           </div>
                        </div>
						)
					})
					}
                     </OwlCarousel>
					 )
					}
					{errors.business_category && (<p className="text-danger">{errors.business_category}</p>)}
      {/* ========== Select Business Category Content End ========== */}
                     {bsc && (
                     <Form.Group className="mb-3">
                       <Form.Label>Select Subcategory For Business<span></span></Form.Label>
					 <MultiSelect key="example_id" options={subcategory} value={optionSelected} onChange={handleChange}  />
                     </Form.Group>
                      )}  
					  {errors.subcategory && (<p className="text-danger">{errors.subcategory}</p>)}
					  {othertext && (
					  <Form.Group className="mb-2">
                           <Form.Control name="other_text" placeholder="Other Text" value={inputs.other_text} onChange={handleInputChange}/>
						   {errors.other_text && (<p className="text-danger">{errors.other_text}</p>)}
                       </Form.Group>
					  )}  
                     <Col md={12} xs={12} className="mb-3">
                     
                     <div className="custm-add">
                        <NavLink to="#">
                           <Button variant="primary" onClick={handleShow2}>
                              Working Hours Setup
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                 <path d="M7.62132 0.0863132C7.43385 0.183171 7.27138 0.373764 7.21826 0.558107C7.19952 0.633095 7.18702 1.7829 7.18702 3.92941V7.18822H3.93134C1.78172 7.18822 0.631923 7.20072 0.556936 7.21947C0.0945177 7.34757 -0.139816 7.92872 0.0882688 8.37864C0.182002 8.56611 0.372594 8.72858 0.556936 8.7817C0.631923 8.80045 1.78172 8.81295 3.93134 8.81295H7.18702V12.0686C7.18702 14.2183 7.19952 15.3681 7.21826 15.4431C7.34637 15.9055 7.92751 16.1398 8.37743 15.9117C8.5649 15.818 8.72737 15.6274 8.78049 15.4431C8.79923 15.3681 8.81173 14.2183 8.81173 12.0686V8.81295H12.0705C14.217 8.81295 15.3668 8.80045 15.4418 8.7817C15.6262 8.72858 15.8167 8.56611 15.9136 8.37864C16.1386 7.92872 15.9042 7.34757 15.4418 7.21947C15.3668 7.20072 14.217 7.18822 12.0705 7.18822H8.81173V3.92941C8.81173 1.7829 8.79923 0.633095 8.78049 0.558107C8.68988 0.233163 8.36181 0.00195217 7.99938 0.00195217C7.83378 0.00195217 7.75567 0.0206995 7.62132 0.0863132Z" fill="#3F9534"/>
                              </svg>
                           </Button>
                        </NavLink>
						{errors.sunstart && (<p className="text-danger">{errors.sunstart}</p>)}
						{errors.sunend && (<p className="text-danger">{errors.sunend}</p>)}
						{errors.monstart && (<p className="text-danger">{errors.monstart}</p>)}
						{errors.monend && (<p className="text-danger">{errors.monend}</p>)}
						{errors.tuestart && (<p className="text-danger">{errors.tuestart}</p>)}
						{errors.tueend && (<p className="text-danger">{errors.tueend}</p>)}
						{errors.wedstart && (<p className="text-danger">{errors.wedstart}</p>)}
						{errors.wedend && (<p className="text-danger">{errors.wedend}</p>)}
						{errors.thustart && (<p className="text-danger">{errors.thustart}</p>)}
						{errors.thuend && (<p className="text-danger">{errors.thuend}</p>)}
						{errors.fristart && (<p className="text-danger">{errors.fristart}</p>)}
						{errors.friend && (<p className="text-danger">{errors.friend}</p>)}
						{errors.satstart && (<p className="text-danger">{errors.satstart}</p>)}
						{errors.satend && (<p className="text-danger">{errors.satend}</p>)}
                     </div>
                     
                     </Col>
                     <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Store Policy and Refund Policy</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter your store policy and Refund Policy..." name="store_policy" value={inputs.store_policy} onChange={handleInputChange}/>
						{errors.store_policy && (<p className="text-danger">{errors.store_policy}</p>)}
					 </Form.Group>
					 <Form.Group className="mb-3">
                        <Form.Label>Sales Tax Rate (%)<span>*</span></Form.Label>
                        <Form.Control type="Number" name="sales_tax_rate" placeholder="Please enter your Sales Tax Rate." onChange={handleInputChange} value={inputs.sales_tax_rate}/>
						{errors.sales_tax_rate && (<p className="text-danger">{errors.sales_tax_rate}</p>)}
					 </Form.Group>
                     <Col className="upladcustm">
                        <Form.Label>Select Image for Store Banner</Form.Label>
                        <Row>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {image1.preview ? (
							  <> 
								<img src={image1.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="image1" accept=".png, .jpg, .jpeg" onChange={handleChange1} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {image2.preview ? (
							  <> 
								<img src={image2.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							  </>
								)}
							  </div>
                              <input type="file" name="image2" accept=".png, .jpg, .jpeg" onChange={handleChange2}/>
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							  {image3.preview ? (
							  <> 
								<img src={image3.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
								<img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/>
							   </>
								)}
							  </div>
                              <input type="file" name="image3" accept=".png, .jpg, .jpeg" onChange={handleChange3} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							   {image4.preview ? (
							  <> 
								<img src={image4.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
							     <img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> 
							   </>
								)}
							  </div>
                              <input type="file" name="image4" accept=".png, .jpg, .jpeg" onChange={handleChange4} />
                           </div>
                           </Col>
                           <Col>
                           <div id="dropzone">
                              <div>
							   {image5.preview ? (
							  <> 
								<img src={image5.preview} className="img-fluid" alt="Sld Img"/>
							  </>
								) : (
								<>
							    <img src= {'assets/images/merchant/svg/upldimg.svg'} className="img-fluid" alt="Sld Img"/> 
							   </>
								)}
							  </div>
                              <input type="file" name="image5" accept=".png, .jpg, .jpeg" onChange={handleChange5} />
                           </div>
						   
                           </Col>  
											   
                        </Row>
                        </Col>
                      <Form.Group className="mb-3 pswinpt">
                        <Form.Label>Change Password<span>*</span></Form.Label>
                        <Form.Control type={values.showPassword ? "text" : "password"} name="password" id="enpswd" placeholder="At least 8 characters" onChange={handleInputChange} value={inputs.password}/>
                        {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
						{errors.password && (<p className="text-danger">{errors.password}</p>)}
						
					 </Form.Group>
                     <Form.Group className="mb-3 pswinpt">
                        <Form.Label>Confirm Password<span>*</span></Form.Label>
                        <Form.Control type={values1.showPassword ? "text" : "password"}  name="password_confirmation" id="renpswd" placeholder="At least 8 characters" onChange={handleInputChange} value={inputs.password_confirmation}/>
                        {values1.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword1}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword1}></i>}
						{errors.password_confirmation && (<p className="text-danger">{errors.password_confirmation}</p>)}
					 </Form.Group>
					 
                     <div className="mb-3 btn-dv">
                        <button type="submit" className="btn"> Save Profile</button>
                     </div>
                     <div className="mb-3 btn-dv">
						<NavLink to="#"><Button variant="" type="submit" onClick={() => delete_confirm()}>Delete My Account</Button></NavLink>
					 </div>
                  </Form>
                  </Col>
               </Row>
               </Col>
               <div className="popupcntmrcntflw">
                  
                  <div className="inmodal">
      {/* ========== Working Hours Setup Popup Content Start ========== */}
                     <Modal show={show2} onHide={handleClose2} className="popupwrngcnt mainpopwork">
                        <Modal.Header closeButton>
                           <h3 className="mdlttltxt">Working Hours Setup</h3>
                        </Modal.Header>
                        <Modal.Body>
                           <Row className="chkbtn chkbtnmain mx-0 mb-1">
						    <Col xs={10} className="align-self-center mb-1">
                              <Form.Label className="fw-bold">Set timing for all days together</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end mb-1">
                               <Form.Check type="switch" id="custom-switch" label="" checked={inputsf.status} onChange={handleCheckboxChange}/>
                              </Col>
							 
								{isChecked &&
								<Col xs={12}>
								<Row>
								 <Col xs={12} className="align-self-center mt-2">
								 <Form.Label>Start </Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="start_hours" className="mb-2" aria-label="Default select example" value={inputsf.start_hours} onChange={handleFInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="start_minutes" className="mb-2" aria-label="Default select example" value={inputsf.start_minutes} onChange={handleFInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								 
								 </Row>
								 
								 </Col>
								 <Col xs={12} className="align-self-center mt-2">
								 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="end_hours" className="mb-2" aria-label="Default select example" value={inputsf.end_hours} onChange={handleFInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="end_minutes" className="mb-2" aria-label="Default select example" value={inputsf.end_minutes} onChange={handleFInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								   <Col xs={12} className="align-self-center mb-3">
									<NavLink to="#" onClick={() => handleSetVal()}><Button className="blc profilefirst" variant="">Submit</Button></NavLink>
								   </Col>
								 </Row>

								 </Col>

								</Row>
								</Col>
								}
						     </Row>
							  <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label className="fw-bold">Sunday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsSunday.status} onChange={handleSunCheckboxChange} />
                              </Col>
                              {isSunChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start </Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="sunstart_hours" className="mb-2" aria-label="Default select example" value={inputsSunday.sunstart_hours} onChange={handleSunInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="sunstart_minutes" className="mb-2" aria-label="Default select example" value={inputsSunday.sunstart_minutes} onChange={handleSunInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="sunstart" value={inputsSunday.start} onChange={handleSunInputChange} />*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="sunend_hours" className="mb-2" aria-label="Default select example" value={inputsSunday.sunend_hours} onChange={handleSunInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="sunend_minutes" className="mb-2" aria-label="Default select example" value={inputsSunday.sunend_minutes} onChange={handleSunInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="sunend" value={inputsSunday.end} onChange={handleSunInputChange} />*/}
                                 </Col>
                              </Row>
                              </Col>
                              }
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label className="fw-bold">Monday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsMonday.status} onChange={handleMonCheckboxChange} />
                              </Col>
                              {isMonChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="monstart_hours" className="mb-2" aria-label="Default select example" value={inputsMonday.monstart_hours} onChange={handleMonInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="monstart_minutes" className="mb-2" aria-label="Default select example" value={inputsMonday.monstart_minutes} onChange={handleMonInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="monstart" value={inputsMonday.start} onChange={handleMonInputChange}  />*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="monend_hours" className="mb-2" aria-label="Default select example" value={inputsMonday.monend_hours} onChange={handleMonInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="monend_minutes" className="mb-2" aria-label="Default select example" value={inputsMonday.monend_minutes} onChange={handleMonInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="monend" value={inputsMonday.end} onChange={handleMonInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }                              
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label className="fw-bold">Tuesday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsTuesday.status} onChange={handleTueCheckboxChange} />
                              </Col>
                              {isTueChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="tuestart_hours" className="mb-2" aria-label="Default select example" value={inputsTuesday.tuestart_hours} onChange={handleTueInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="tuestart_minutes" className="mb-2" aria-label="Default select example" value={inputsTuesday.tuestart_minutes} onChange={handleTueInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM"  name="tuestart" value={inputsTuesday.start} onChange={handleTueInputChange}/>*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="tueend_hours" className="mb-2" aria-label="Default select example" value={inputsTuesday.tueend_hours} onChange={handleTueInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="tueend_minutes" className="mb-2" aria-label="Default select example" value={inputsTuesday.tueend_minutes} onChange={handleTueInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="tueend" value={inputsTuesday.end} onChange={handleTueInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }                                  
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label className="fw-bold">Wednesday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsWednesday.status} onChange={handleWedCheckboxChange}/>
                              </Col>
                              {isWedChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="wedstart_hours" className="mb-2" aria-label="Default select example" value={inputsWednesday.wedstart_hours} onChange={handleWedInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="wedstart_minutes" className="mb-2" aria-label="Default select example" value={inputsWednesday.wedstart_minutes} onChange={handleWedInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="wedstart" value={inputsWednesday.start} onChange={handleWedInputChange}/>*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="wedend_hours" className="mb-2" aria-label="Default select example" value={inputsWednesday.wedend_hours} onChange={handleWedInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="wedend_minutes" className="mb-2" aria-label="Default select example" value={inputsWednesday.wedend_minutes} onChange={handleWedInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="wedend" value={inputsWednesday.end} onChange={handleWedInputChange} />*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }    
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label className="fw-bold">Thursday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsThursday.status} onChange={handleThuCheckboxChange}/>
                              </Col>
                              {isThuChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="thustart_hours" className="mb-2" aria-label="Default select example" value={inputsThursday.thustart_hours} onChange={handleThuInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="thustart_minutes" className="mb-2" aria-label="Default select example" value={inputsThursday.thustart_minutes} onChange={handleThuInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="thustart" value={inputsThursday.start} onChange={handleThuInputChange} />*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="thuend_hours" className="mb-2" aria-label="Default select example" value={inputsThursday.thuend_hours} onChange={handleThuInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="thuend_minutes" className="mb-2" aria-label="Default select example" value={inputsThursday.thuend_minutes} onChange={handleThuInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="thuend" value={inputsThursday.end} onChange={handleThuInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }         
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label className="fw-bold">Friday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsFriday.status} onChange={handleFriCheckboxChange}/>
                              </Col>
                              {isFriChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2" >
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="fristart_hours" className="mb-2" aria-label="Default select example" value={inputsFriday.fristart_hours} onChange={handleFriInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="fristart_minutes" className="mb-2" aria-label="Default select example" value={inputsFriday.fristart_minutes} onChange={handleFriInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM"  name="fristart" value={inputsFriday.start} onChange={handleFriInputChange}/>*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="friend_hours" className="mb-2" aria-label="Default select example" value={inputsFriday.friend_hours} onChange={handleFriInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="friend_minutes" className="mb-2" aria-label="Default select example" value={inputsFriday.friend_minutes} onChange={handleFriInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="friend" value={inputsFriday.end} onChange={handleFriInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }    
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label className="fw-bold">Saturday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsSaturday.status} onChange={handleSatCheckboxChange}/>
                              </Col>
                              {isSatChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="satstart_hours" className="mb-2" aria-label="Default select example" value={inputsSaturday.satstart_hours} onChange={handleSatInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="satstart_minutes" className="mb-2" aria-label="Default select example" value={inputsSaturday.satstart_minutes} onChange={handleSatInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="satstart" value={inputsSaturday.start} onChange={handleSatInputChange} />*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="satend_hours" className="mb-2" aria-label="Default select example" value={inputsSaturday.satend_hours} onChange={handleSatInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="satend_minutes" className="mb-2" aria-label="Default select example" value={inputsSaturday.satend_minutes} onChange={handleSatInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="satend" value={inputsSaturday.end} onChange={handleSatInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }                                  
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={12} className="align-self-center">
                              <h3 className="mdlttltxt float-start">Orders/Appointments settings</h3>
                              <span style={{ }}>
                                 <OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={(props) => (
                                 <Tooltip {...props}>Get more information in FAQs!</Tooltip>
                                 )}placement="bottom">
                                 <NavLink to="/mer-help"> <span className="mdlttltxt text-danger">&nbsp;&#9432; </span></NavLink>
                                 </OverlayTrigger>
                              </span>
                              </Col>
                              <div className="linepop my-1"></div>
                              <Row className="chkbtn mx-0 mb-3 mt-3 px-0">
                                 <Col xs={8} className="align-self-center">
                                 <Form.Label>How Many customers can be accepted per time slot (per hour):</Form.Label>
                                 </Col>
                                 <Col xs={4} className="align-self-center">
                                  <Form.Control type="Number" placeholder="4" name="accepted_per_time_slot" min="1"  value={inputs.accepted_per_time_slot} onChange={handleInputChangeSlot}/>
                                 </Col>
                              </Row>
                              <Col xs={10} className="align-self-center mb-0">
                              <Form.Label>Auto accept during working hours</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end radio-text-dang mb-2">
                              <Form.Check type="radio" className="chk-radio" name="auto_accept_during_working_hours" id="custom-switch" label="" value="auto_accept_during_working_hours" onChange={handleInputChange} checked={inputs.auto_accept_during_working_hours === 'auto_accept_during_working_hours'}/>
                              </Col>
                              <Col xs={10} className="align-self-center mb-0">
                              <Form.Label>Need Confirmation</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end radio-text-dang mb-2">
                              <Form.Check type="radio" className="chk-radio" name="auto_accept_during_working_hours" id="custom-switch" label="" value="need_to_be_confirmed" onChange={handleInputChange} checked={inputs.auto_accept_during_working_hours === 'need_to_be_confirmed'}/>
                              </Col>
                              <Col xs={10} className="align-self-center mb-0">
                              <Form.Label>Auto Refuse (customers in the blocklist)</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end radio-text-dang">
                              <Form.Check type="switch" name="auto_refuse" id="custom-switch" label="" onChange={handleOnChangerefuse}/>
                              </Col>
                           </Row>
                           <Row className="pp-btn1 mx-0">
                              <Col>
                              <NavLink to="#" onClick={() => handleClose2()}><Button className="blc workhours-confirm" variant="">Confirm</Button></NavLink>
                              </Col>
                           </Row>
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                     </Modal>
      {/* ========== Working Hours Setup Popup Content End ========== */}
	  {/* ========== Delete Popup Start ========== */}
                    <Modal show={showdelete} onHide={handleCloseDelete} className="popupwrngcnt">
                       <Modal.Header >
					   <p>If you click on Yes, ALL of your data will be deleted.</p>
                         
                       </Modal.Header>
                       <Modal.Body>
					    <h3 className="mdlttltxt mb-4">Do you want to delete your Account?</h3>
                          <Row>
                             <Col className="btn-clr">
                             <NavLink to="#" onClick={handleDeleteLogin}><Button variant="">Yes</Button></NavLink>
                             </Col>
                             <Col className="btn-dv">
                             <NavLink to="#" onClick={handleCloseDelete}><Button variant="">No</Button></NavLink>
                             </Col>
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Delete Popup End ========== */}
				  {/* ========== Delete Login Popup Start ========== */}
                    <Modal show={showdeletelogin} onHide={handleCloseDeleteLogin} className="popupwrngcnt">
                       <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Login</h3>
                       </Modal.Header>
                       <Modal.Body>
                          <Row>
                             <Col>
							 
								<Form method="post" onSubmit={handleSubmitLogin} enctype="multipart/form-data">
								   <Form.Group className="mb-3">
									  <Form.Label>Email or Phone<span>*</span></Form.Label>
									  <Form.Control type="text" name="email_or_phone" placeholder="Enter email or phone number" onChange={handleInputChangeLogin} value={inputslogin.email_or_phone} autoComplete="off"  />
									  {errors.email_or_phone && (<p className="text-danger">{errors.email_or_phone}</p>)}
								   </Form.Group>
								   <Form.Group className="mb-3 pswinpt">
									  <Form.Label>Password<span>*</span></Form.Label>
									  
									  <Form.Control type={values2.showPassword ? "text" : "password"} name="password" id="password" placeholder="At least 8 characters" onChange={handleInputChangeLogin} value={inputslogin.password} autoComplete="off" />
									  {values2.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword2}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword2}></i>}
									  {errors.password && (<p className="text-danger">{errors.password}</p>)}
								   </Form.Group>
								   
								   <div className="mb-3 btn-dv">
									  <Button type="submit">Login</Button>
								   </div>
								   {loading1 && (
								   <div className="loadingClass">
										<img src={loader} alt="loader"/>
									</div>
								   )}  
								</Form>
                             </Col>
                            
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Delete Login Popup End ========== */}
                  </div>
                  
                  
               </div>
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
		 
      </section>
	  
      {/* ========== Merchant Store Setup Page Start ========== */}
      </>
    )
}
export default MerProfile;