import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button, Form, Nav } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import './UserPymt.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import {validatecard} from '../../../Utils/validate';
import loader from '../../../assests/loader.gif';
import API from '../../../Utils/Api';
import { setUserSession } from '../../../Utils/Common';
import InputMask from 'react-input-mask';

const UserPymt = () => {
const navigate = useNavigate();
const [key, setKey] = useState('second');
const [inputs,setInputs] = useState({debite_card_name:'',debite_card_number:'',debite_month_year:'',debite_month:'',debite_year:'',credit_card_name:'',credit_card_number:'',credit_month_year:'',credit_month:'',credit_year:''});
const [error,setError] = useState();
const [errors,setErrors] = useState({});
const [loading,setLoading] = useState(false);

useEffect(() => {
      document.title = `User Payment | Blue Faucet`;
      document.getElementById('bodywrap').className='userpgmain';

		window.scrollTo({
		  top: 0,
		  left: 0,
		  behavior: 'smooth', 
		}); 
},[])  
/* Start Value set */
const handleInputChange = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value})); 
}
/* End Value set */
/* Start Submit profile */
const handleSubmit = (event) => {
	event.preventDefault();
	
	  
	
	
	const validationErrors = validatecard(inputs);
	const noErrors = Object.keys(validationErrors).length === 0;
	setError('');
	setErrors({});
	setErrors(validationErrors);
	if(noErrors){
		setLoading(true);
		
			let credit_card_name=inputs.credit_card_name;
			let credit_card_number=inputs.credit_card_number;
			let credit_month=inputs.credit_month;
			let credit_year=inputs.credit_year;
			let debite_card_name=inputs.debite_card_name;
			let debite_card_number=inputs.debite_card_number;
			let debite_month=inputs.debite_month;
			let debite_year=inputs.debite_year;
			let type='';
			
			
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('credit_card_name', credit_card_name);
			formData.append('credit_card_number', credit_card_number);
			formData.append('credit_card_month', credit_month);
			formData.append('credit_card_year', credit_year);
			formData.append('debit_card_name', debite_card_name);
			formData.append('debit_card_number', debite_card_number);
			formData.append('debit_card_month', debite_month);
			formData.append('debit_card_year', debite_year);
			formData.append('type', type);
			API.post('payment_setup', formData)
				.then(response => {
				if(response.data.status === 0){
					setError(response.data.message);
					setLoading(false); 
				}else{
					setLoading(false); 
					
					setUserSession(response.data.data.token,response.data.data.profile_setup,response.data.data.type);
					navigate("/home");
				}                  
			}).catch(error => {
				 setLoading(false);
				  setError("Something went wrong. Please try again later.");
			}); 
			
		
		
		
	}
}
const handleSubmitskip = () => {

	setError('');
	setErrors({});

		setLoading(true);
		
			let credit_card_name='';
			let credit_card_number='';
			let credit_month='';
			let credit_year='';
			let debite_card_name='';
			let debite_card_number='';
			let debite_month='';
			let debite_year='';
			let type='skip';
			
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('credit_card_name', credit_card_name);
			formData.append('credit_card_number', credit_card_number);
			formData.append('credit_card_month', credit_month);
			formData.append('credit_card_year', credit_year);
			formData.append('debit_card_name', debite_card_name);
			formData.append('debit_card_number', debite_card_number);
			formData.append('debit_card_month', debite_month);
			formData.append('debit_card_year', debite_year);
			formData.append('type', type);
			API.post('payment_setup', formData)
				.then(response => {
				if(response.data.status === 0){
					setError(response.data.message);
					setLoading(false); 
				}else{
					setLoading(false); 
					
					setUserSession(response.data.data.token,response.data.data.profile_setup,response.data.data.type);
					navigate("/profile");
				}                  
			}).catch(error => {
				 setLoading(false);
				  setError("Something went wrong. Please try again later.");
			}); 

}
/* End Submit profile */
/* Start Debit Card Expiry value set */
const handleDabitExpiryChange = (event) => {
    // Remove non-numeric characters
    const cleanedValue = event.target.value.replace(/\D/g, '');
	
	const joinValue = cleanedValue.slice(0, 2) + '/' + cleanedValue.slice(2);
	const exp_val = joinValue.split("/");

      let maskedValue1 = exp_val[0];
	  let maskedValue2 = exp_val[1];
	  let debite_month_year_lbl='debite_month_year';
	  let debite_month_lbl='debite_month';
	  let debite_year_lbl='debite_year';
	  setInputs(inputs => ({...inputs, [debite_month_year_lbl]: event.target.value})); 
	  setInputs(inputs => ({...inputs, [debite_month_lbl]: maskedValue1}));
	  setInputs(inputs => ({...inputs, [debite_year_lbl]: maskedValue2}));
    
  }
/* End Debit Card Expiry value set */
/* Start Credit Card Expiry value set */
  const handleCreditExpiryChange = (event) => {
    // Remove non-numeric characters
    const cleanedValue = event.target.value.replace(/\D/g, '');
	
	const joinValue = cleanedValue.slice(0, 2) + '/' + cleanedValue.slice(2);
	const exp_val = joinValue.split("/");

	  
      let maskedValue1 = exp_val[0];
	  let maskedValue2 = exp_val[1];
	  let credit_month_year_lbl='credit_month_year';
	  let credit_month_lbl='credit_month';
	  let credit_year_lbl='credit_year';
	  setInputs(inputs => ({...inputs, [credit_month_year_lbl]: event.target.value})); 
	  setInputs(inputs => ({...inputs, [credit_month_lbl]: maskedValue1}));
	  setInputs(inputs => ({...inputs, [credit_year_lbl]: maskedValue2}));
    
  }
/* End Credit Card Expiry value set */
 
    return (
        <>
      {/* ========== User New Page Start ========== */}
         <section className="gen-wrap gen-main">
         {/* ========== Navbar Start ========== */}
         <Row className="maincnttpnw mx-0">
            <Col xs={2} md={2} className="align-self-center px-0">
            <button onClick={() =>
               navigate(-1)} className="back-btn">
               <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
               </svg>
            </button>
            </Col>
            <Col xs={8} md={8} className="align-self-center text-center">
            <h3>Payment Setup</h3>
            </Col>
            <Col xs={2} md={2} className="align-self-center">
            <p></p>
            </Col>
         </Row>
         {/* ========== Navbar End ========== */}
         <Container>
         {/* ========== Payment Setup Content Start ========== */}
            <Row className="genscrn">
               <Col className="usrnw px-0">
               <div className="maincntsrcn">
			   {error && (
				<div className="alert alert-danger">
				  {error}
				</div>)}
                 <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
				  <Tab.Container id="left-tabs-example" defaultActiveKey={key} onSelect={(k) => setKey(k)}>
                     <Nav variant="pills" className="flex-column">
                        <Row>
                           <Col>
                           <Nav.Item>
                              <Nav.Link eventKey="first" >Debit Card Setup</Nav.Link>
                           </Nav.Item>
                           </Col>
                           <Col>
                           <Nav.Item>
                              <Nav.Link eventKey="second" >Credit Card Setup </Nav.Link>
                           </Nav.Item>
                           </Col>
                        </Row>
                     </Nav>
                     <Tab.Content>
                        <Tab.Pane eventKey="first">
                           <Form.Group className="mb-2">
                              <Form.Control type="text" name="debite_card_name" placeholder="Cardholder name" value={inputs.debite_card_name} onChange={handleInputChange}/>
							  {errors.debite_card_name && (<p className="text-danger">{errors.debite_card_name}</p>)}
						   </Form.Group>
                           <Form.Group className="mb-2">
                              <Form.Control type="text" name="debite_card_number" placeholder="Card number" value={inputs.debite_card_number} onChange={handleInputChange} maxLength={16}/>
							  {errors.debite_card_number && (<p className="text-danger">{errors.debite_card_number}</p>)}
						   </Form.Group>                           
                           {/*<Form.Group className="mb-2">
                              <Form.Control type="text" placeholder="Account Number" />
                           </Form.Group>*/}
                           <Form.Group className="mb-2">
                              
								<InputMask
									mask="99/9999"
									name="debite_month_year"
									value={inputs.debite_month_year}
									onChange={handleDabitExpiryChange}
									placeholder="mm/yyyy exp year"
									className="form-control"
								  />
								{errors.debite_month_year && (<p className="text-danger">{errors.debite_month_year}</p>)}
						   </Form.Group>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                           <Form.Group className="mb-2">
                              <Form.Control type="text" name="credit_card_name" placeholder="Cardholder name" value={inputs.credit_card_name} onChange={handleInputChange} />
								{errors.credit_card_name && (<p className="text-danger">{errors.credit_card_name}</p>)}
						   </Form.Group>
                           <Form.Group className="mb-2">
                              <Form.Control type="text" name="credit_card_number" placeholder="Card number" value={inputs.credit_card_number} onChange={handleInputChange} maxLength={16} />
							  {errors.credit_card_number && (<p className="text-danger">{errors.credit_card_number}</p>)}
						   </Form.Group>                           
                          {/* <Form.Group className="mb-2">
                              <Form.Control type="text" placeholder="Account Number" />
						  </Form.Group> */}
                           <Form.Group className="mb-2">    
							  <InputMask
									mask="99/9999"
									name="credit_month_year"
									value={inputs.credit_month_year}
									onChange={handleCreditExpiryChange}
									placeholder="mm/yyyy exp year"
									className="form-control"
								  />
								  {errors.credit_month_year && (<p className="text-danger">{errors.credit_card_number}</p>)}
						  </Form.Group>

                           
                        </Tab.Pane>
                     </Tab.Content>
                  </Tab.Container>
				  <p className="mt-5 note-text-justify">BlueFaucet provides seamless billing and payment services. By adding your credit or debit card information, you can quickly pay merchants as soon as you receive an invoice. If a refund is needed, the money will automatically return to your original payment method, saving you time and effort.</p>
                  <p className="note-text-justify">Rest assured, BlueFaucet uses industry-leading security standards to protect and store your card information.</p>
				  <div>
				  {errors.card && (<p className="text-danger">{errors.card}</p>)}
                     <Row className="usrnwlstbtn">
                        <Col className="btn-clr d-none">
                        <Button variant="">Clear</Button>{' '}
                        </Col>
						<Col className="btn-dv">
                        <Button type="Button" onClick={handleSubmitskip}>Skip</Button>
                        </Col>
                        <Col className="btn-dv">
                        <Button type="Submit">Save</Button>
                        </Col>
                     </Row>
                  </div>
				  </Form>
               </div>
               </Col>
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         {/* ========== Payment Setup Content End ========== */}
         </Container>
      </section>
      {/* ========== User New Page End ========== */}
        </>
    )
}
export default UserPymt;