import React, { useEffect,useState } from 'react';
import { NavLink } from "react-router-dom";
import { Row, Col, Container, Table } from 'react-bootstrap';
import './MerSubreceiptsHistory.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';

const MerSubreceiptsHistory = () => {
const [loading,setLoading] = useState(true);
const [hist, setHist] = useState([]);	
	
useEffect(() => {
      document.title = `Merchant Receipts | Blue Faucet`;
      document.getElementById('bodywrap').className='usrrcptcpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
	/* Start get history data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_subscription_history', formData)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
			   setHist(response.data.data);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get history data */
},[]) 

    return (
      <>
      {/* ========== Merchant Order Statistics Page Start ========== */}
         <section className="gen-wrap">
        {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Payment History'}/>
        {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="py-3">
               <Row className="mx-0">
        {/* ========== Order Statistics Content Start ========== */}
                  <Col md={12} xs={12} className="px-0 payment_histcont">
				  
				  
				  <h3 className="payment_maintext">Membership Subscription Payment Records</h3>
						<Table responsive className="payment_histroytbl">
						<thead>
						<tr>
							<th>Date</th>
							<th>Items</th>
							<th>Amount</th>
							<th>Receipt #</th>
							<th>Recurrent date</th>
						</tr>
						  </thead>
						  {hist.length >0 ? (
						  <tbody>
						  {hist && hist.map((val,index) => {
							return(
									<tr>
										<td>{val.date}</td>
										<td>{val.plan_name}</td>
										<td>{val.amount}</td>
										<td><NavLink to={`/mer-subscription-receipt/${val.id}/${val.subscription_transaction_id}`}  className="">{val.recipt}</NavLink></td>
										<td>{val.end_date}</td>
									</tr>
								)
						})}  
						  </tbody>
						 ) : (
							<tbody>
								<tr><td colspan="5" className="text-center">No data found</td></tr>
							</tbody>
						 )} 
						</Table>
						
						
                  </Col>
        {/* ========== Order Statistics Content End ========== */}
               </Row>
               </Col>
               </Col>
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
        </section>
        {/* ========== Merchant Order Statistics Page End ========== */}
        </>
    )
}
export default MerSubreceiptsHistory;