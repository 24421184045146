//import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useState  } from 'react';
import {  Row, Col,Form,Button} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";


export const CardForm = (props) => {
	
  const stripe = useStripe();
  const elements = useElements();
  const [loading,setLoading] = useState(false);

 const [checkoutError, setCheckoutError] = useState(); 
 
 const [inputs,setInputs] = useState({recurring:true});
 
const cardNumberElementOptions = {
    // Set default values here
    placeholder: 'Enter card number',
    style: {
      base: {
        fontSize: '16px',
      },
    },
	
  };
 
  const handleSubmit = async () => {

	const cardElement = elements.getElement(CardNumberElement)
   stripe.createToken(cardElement)
      .then((payload) => {
		  if(payload.error){
			  alert(payload.error.message);
		  }else{
			  setLoading(true);
			  var stripeToken=payload.token.id;
			  var card_name='';
				var card_number=payload.token.card.last4;
				var card_month=payload.token.card.exp_month;
				var card_year=payload.token.card.exp_year;
				
			  var type=0;
			  if(inputs.recurring)
			  {
				  type=1;
			  }
			let formData = new FormData();
				formData.append('token', localStorage.getItem("token"));
				formData.append('stripeToken', stripeToken);
				formData.append('subscription_id', props.data.subscription_id);
				formData.append('card_name', card_name);
				formData.append('card_number', card_number);
				formData.append('card_month', card_month);
				formData.append('card_year',card_year);
				formData.append('amount',props.data.price);
				formData.append('type',type);
			    formData.append('plan_id',props.data.plan_id);
				
				API.post('subscription_payment', formData)
					.then(response => {
					if(response.data.status === 0){
						alert(response.data.message);
						setLoading(false); 
					}else{
						setLoading(false);
						alert(response.data.message);
					  window.location.href = "/mer-subscription";
					}                  
				}).catch(error => {
					 setLoading(false);
					  alert("Something went wrong. Please try again later.");
				});
				
		  }
	  
	  });
	  
  };
  
 const handleChange = (e) => {
	 console.log(e.error);
    e.error ? setCheckoutError(e.error.message) : setCheckoutError();
  };
  
  const handleCheckboxChange = (event) => {
    
	 var recurring_lbl='recurring';
     setInputs(inputs => ({...inputs, [recurring_lbl]: event.target.checked}));
    
  };
 
  
  return (
    
	<Row xs={{ gutterX: 1 }} className="mx-0 mt-4">
		<Col md={12} xs={12} >
		 <div className="mb-3">
			<CardNumberElement  className="mb-3 form-control"  options={cardNumberElementOptions}  onChange={(e) => handleChange(e)} />
			
		 </div>
		</Col>
	   <Col md={12} xs={12} >
		 <div className="mb-3">
			<CardExpiryElement  className="mb-3 form-control"  onChange={(e) => handleChange(e)} />
			
		 </div>
		</Col>
		<Col md={12} xs={12} >
		 <div className="mb-3">
			<CardCvcElement  className="mb-3 form-control" onChange={(e) => handleChange(e)}/>
			
		 </div>
		</Col>
	          <div className="text-danger mb-2">{checkoutError && <div>{checkoutError}</div>}</div>
			
			
			<Form.Group className="mb-3">
                                 <Form.Label> </Form.Label>
                                 <Form.Check aria-label="option 1" id="" label="Enable Recurring" checked={inputs.recurring} onChange={handleCheckboxChange} />
                              </Form.Group>
							   <Row className="pp-btn1 mx-0">
                              <Col><NavLink to="#" onClick={() => handleSubmit()}><Button className="blc" variant="">Pay Now </Button></NavLink></Col>
                           </Row>
			
			
	 {loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
	 </Row>
	
  );
}
