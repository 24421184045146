//import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useEffect,useState,useRef  } from 'react';
import {  Row, Col,Form,Button,Modal} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import {validateemp} from '../../../Utils/validate';
import 'intl-tel-input/build/css/intlTelInput.css'; // Import the CSS
import intlTelInput from 'intl-tel-input';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";


export const CardForm = (props) => {
	const inputRef = useRef(null);
  const stripe = useStripe();
  const elements = useElements();
  const [loading,setLoading] = useState(false);
	const [loading1,setLoading1] = useState(false);
 const [checkoutError, setCheckoutError] = useState(); 
 
const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);


const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);

const [isChecked, setIsChecked] = useState(false);
const [isSunChecked, setSunIsChecked] = useState(false);
const [isMonChecked, setMonIsChecked] = useState(false);
const [isTueChecked, setTueIsChecked] = useState(false);
const [isWedChecked, setWedIsChecked] = useState(false);
const [isThuChecked, setThuIsChecked] = useState(false);
const [isFriChecked, setFriIsChecked] = useState(false);
const [isSatChecked, setSatIsChecked] = useState(false);

const [inputsf, setInputsF] = useState({day:'sun',start_hours:'12',start_minutes:'00',end_hours:'12',end_minutes:'00',status:0});
const [inputsSunday, setInputsSunday] = useState({day:'sunday',sunstart_hours:'12',sunstart_minutes:'00',sunend_hours:'12',sunend_minutes:'00',status:0});
const [inputsMonday, setInputsMonday] = useState({day:'monday',monstart_hours:'12',monstart_minutes:'00',monend_hours:'12',monend_minutes:'00',status:0});
const [inputsTuesday, setInputsTuesday] = useState({day:'tuesday',tuestart_hours:'12',tuestart_minutes:'00',tueend_hours:'12',tueend_minutes:'00',status:0});
const [inputsWednesday, setInputsWednesday] = useState({day:'wednesday',wedstart_hours:'12',wedstart_minutes:'00',wedend_hours:'12',wedend_minutes:'00',status:0});
const [inputsThursday, setInputsThursday] = useState({day:'thursday',thustart_hours:'12',thustart_minutes:'00',thuend_hours:'12',thuend_minutes:'00',status:0});
const [inputsFriday, setInputsFriday] = useState({day:'friday',fristart_hours:'12',fristart_minutes:'00',friend_hours:'12',friend_minutes:'00',status:0});
const [inputsSaturday, setInputsSaturday] = useState({day:'saturday',satstart_hours:'12',satstart_minutes:'00',satend_hours:'12',satend_minutes:'00',status:0});

const [values, setValues] = useState({password: "",showPassword: false,});
const [values1, setValues1] = useState({password: "",showPassword: false,});
const [inputs,setInputs] = useState({employee_id:'',employee_name:'',employee_email:'',employee_contact_no:'',country_code:'1',country_short_code:'us',date_of_birth:'',commission:'',password:'',password_confirmation:'',accepted_per_time_slot:4,auto_accept_during_working_hours:'auto_accept_during_working_hours',auto_refuse:0});
 
const [error,setError] = useState();
const [success,setSuccess] = useState();
const [errors,setErrors] = useState({});
 
const cardNumberElementOptions = {
    // Set default values here
    placeholder: 'Enter card number',
    style: {
      base: {
        fontSize: '16px',
      },
    },
	
 };
 
useEffect(() => { 
	
	
      const input = inputRef.current;
    const iti = intlTelInput(input, {
	  initialCountry:"us",
      separateDialCode:true,
    });

    // Listen for changes
    input.addEventListener('countrychange', () => {
      const selectedCountryData = iti.getSelectedCountryData();
	  var country_code_lbl='country_code';
	  setInputs(inputs => ({...inputs, [country_code_lbl]: selectedCountryData.dialCode}));
	  var country_short_code_lbl='country_short_code';
	  setInputs(inputs => ({...inputs, [country_short_code_lbl]: selectedCountryData.iso2}));
    });

    // Clean up when the component unmounts
    return () => {
      iti.destroy();
    };
    
  }, []);
 
  const handleSubmit = async () => {
	
const cardElement = elements.getElement(CardNumberElement)
   stripe.createToken(cardElement)
      .then((payload) => {
		  if(payload.error){
			  alert(payload.error.message);
		  }else{
			  setLoading1(true);
			  var stripeToken=payload.token.id;
			//console.log(stripeToken);
				var card_number=payload.token.card.last4;
				var card_month=payload.token.card.exp_month;
				var card_year=payload.token.card.exp_year;
				
				
				
				let employee_id=props.data.employee_id;
			let employee_name=inputs.employee_name;
			let employee_email=inputs.employee_email;
			let employee_contact_no=inputs.employee_contact_no;
			let country_code=inputs.country_code;
			let country_short_code=inputs.country_short_code;
			let date_of_birth=inputs.date_of_birth;
			let commission=inputs.commission;
			let password=inputs.password;
			let password_confirmation=inputs.password_confirmation;
			let accepted_per_time_slot=inputs.accepted_per_time_slot;
			let auto_accept_during_working_hours=inputs.auto_accept_during_working_hours;
			let auto_refuse=inputs.auto_refuse;
			
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('stripeToken', stripeToken);
			formData.append('card_number', card_number);
			formData.append('card_month', card_month);
			formData.append('card_year', card_year);
			formData.append('payment_type', "paid");
			formData.append('employee_id', employee_id);
			formData.append('employee_name', employee_name);
			formData.append('employee_email', employee_email);
			formData.append('employee_contact_no', employee_contact_no);
			formData.append('country_code', country_code);
			formData.append('country_short_code', country_short_code);
			formData.append('date_of_birth', date_of_birth);
			formData.append('commission', commission);
			formData.append('password',password);
			formData.append('password_confirmation', password_confirmation);
			formData.append('accepted_per_time_slot', accepted_per_time_slot);
			formData.append('auto_accept_during_working_hours', auto_accept_during_working_hours);
			formData.append('auto_refuse', auto_refuse);
			formData.append('sunday', JSON.stringify(inputsSunday));
			formData.append('monday', JSON.stringify(inputsMonday));
			formData.append('tuesday', JSON.stringify(inputsTuesday));
			formData.append('wednesday', JSON.stringify(inputsWednesday));
			formData.append('thursday', JSON.stringify(inputsThursday));
			formData.append('friday', JSON.stringify(inputsFriday));
			formData.append('saturday', JSON.stringify(inputsSaturday));
			API.post('add_employee', formData)
                .then(response => {
                if(response.data.status === 0){
                    alert(response.data.message);
                    setLoading1(false); 
                }else{
					setLoading1(false);
					alert(response.data.message);
					setShow1(true);
				   window.location.href = "/mer-employee-management";
                }                  
            }).catch(error => {
                 setLoading1(false);
				  setError("Something went wrong. Please try again later.");
			});	
		  }
	  
	  });
	  
  };
 const handleChange = (e) => {
	 console.log(e.error);
    e.error ? setCheckoutError(e.error.message) : setCheckoutError();
};  
/* Start Submit Emp */
const handleSubmit1 = (event) => {
	event.preventDefault();
		const validationErrors = validateemp(inputs,inputsSunday,inputsMonday,inputsTuesday,inputsWednesday,inputsThursday,inputsFriday,inputsSaturday);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setSuccess('');
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){ setLoading(true);
			let formData3 = new FormData(); 
			formData3.append('token', localStorage.getItem("token"));
			API.post('check_plan_for_add_employee', formData3)
				.then(response => {
					setLoading(false);
				if(response.data.status === 0){
					
				}else{
				  if(response.data.data.status===1)
				  {
					handleSubmit2();
				  }else{
					setShow1(true);
				  }
				}                  
			}).catch(error => {
				  setLoading(false);
					setError("Something went wrong. Please try again later.");
			});  
		}
}
const handleSubmit2 = () => {
	setLoading(true);
				
	let employee_id=props.data.employee_id;
	let employee_name=inputs.employee_name;
	let employee_email=inputs.employee_email;
	let employee_contact_no=inputs.employee_contact_no;
	let country_code=inputs.country_code;
	let country_short_code=inputs.country_short_code;
	let date_of_birth=inputs.date_of_birth;
	let commission=inputs.commission;
	let password=inputs.password;
	let password_confirmation=inputs.password_confirmation;
	let accepted_per_time_slot=inputs.accepted_per_time_slot;
	let auto_accept_during_working_hours=inputs.auto_accept_during_working_hours;
	let auto_refuse=inputs.auto_refuse;
	
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('stripeToken', '');
	formData.append('card_number', '');
	formData.append('card_month', '');
	formData.append('card_year', '');
	formData.append('payment_type', "free");
	formData.append('employee_id', employee_id);
	formData.append('employee_name', employee_name);
	formData.append('employee_email', employee_email);
	formData.append('employee_contact_no', employee_contact_no);
	formData.append('country_code', country_code);
	formData.append('country_short_code', country_short_code);
	formData.append('date_of_birth', date_of_birth);
	formData.append('commission', commission);
	formData.append('password',password);
	formData.append('password_confirmation', password_confirmation);
	formData.append('accepted_per_time_slot', accepted_per_time_slot);
	formData.append('auto_accept_during_working_hours', auto_accept_during_working_hours);
	formData.append('auto_refuse', auto_refuse);
	formData.append('sunday', JSON.stringify(inputsSunday));
	formData.append('monday', JSON.stringify(inputsMonday));
	formData.append('tuesday', JSON.stringify(inputsTuesday));
	formData.append('wednesday', JSON.stringify(inputsWednesday));
	formData.append('thursday', JSON.stringify(inputsThursday));
	formData.append('friday', JSON.stringify(inputsFriday));
	formData.append('saturday', JSON.stringify(inputsSaturday));
	API.post('add_employee', formData)
		.then(response => {
		if(response.data.status === 0){
			setError(response.data.message);
			setLoading(false); 
		}else{
			setLoading(false);
			alert(response.data.message);
			
		   window.location.href = "/mer-employee-management";
		}                  
	}).catch(error => {
		 setLoading(false);
		  setError("Something went wrong. Please try again later.");
	});	
}
/* End Submit Emp */
/* Start Password Visible */
const handleClickShowPassword = () => {setValues({ ...values, showPassword: !values.showPassword });}
const handleClickShowPassword1 = () => {setValues1({ ...values1, showPassword: !values1.showPassword });}
/* End Password Visible */

/* Start Value set */
  const handleInputChange = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));       
    }
	const formatAsPhoneNumber = (event) => {
	event.persist();
	var value=event.target.value;
	value = value.replace(/ /g, '');
	value = value.replace(/\D/g, '');
	if (value.length > 10) {
		value = value.slice(0, 10);
	}
	
	if(value.length > 3 && value.length < 7)
	{
	    value = value.slice(0, 3) + ' ' + value.slice(3, 6);
	}else if(value.length > 6)
	{
		
		value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6);
	}
	setInputs(inputs => ({...inputs, [event.target.name]: value})); 
}
/* End Value set */

/* Start working hour checked */
const handleCheckboxChange = (event) => {
	setIsChecked(!isChecked);
	var lbl='status';
	if(event.target.checked)
	{
		setInputsF(inputsf => ({...inputsf, [lbl]: 1}));
	}else{
		setInputsF(inputsf => ({...inputsf, [lbl]: 0}));
	}
};
const handleSunCheckboxChange = (event) => {
	setSunIsChecked(!isSunChecked);
	var sunlbl='status';
	if(event.target.checked)
	{
		setInputsSunday(inputsSunday => ({...inputsSunday, [sunlbl]: 1}));
	}else{
		setInputsSunday(inputsSunday => ({...inputsSunday, [sunlbl]: 0}));
		
		var starthlbl='sunstart_hours';
		var start_hours='';
		setInputsSunday(inputsSunday => ({...inputsSunday, [starthlbl]: start_hours})); 
		
		var startmlbl='sunstart_minutes';
		var start_minutes='';
		setInputsSunday(inputsSunday => ({...inputsSunday, [startmlbl]: start_minutes}));
		
		var endhlbl='sunend_hours';
		var end_hours='';
		setInputsSunday(inputsSunday => ({...inputsSunday, [endhlbl]: end_hours}));
		
		var endmlbl='sunend_minutes';
		var end_minutes='';
		setInputsSunday(inputsSunday => ({...inputsSunday, [endmlbl]: end_minutes}));
	}
};
const handleMonCheckboxChange = (event) => {
	setMonIsChecked(!isMonChecked);
	var monlbl='status';
	if(event.target.checked)
	{
		setInputsMonday(inputsMonday => ({...inputsMonday, [monlbl]: 1}));
	}else{
		setInputsMonday(inputsMonday => ({...inputsMonday, [monlbl]: 0}));
		
		var starthlbl='monstart_hours';
		var start_hours='';
		setInputsMonday(inputsMonday => ({...inputsMonday, [starthlbl]: start_hours})); 
		
		var startmlbl='monstart_minutes';
		var start_minutes='';
		setInputsMonday(inputsMonday => ({...inputsMonday, [startmlbl]: start_minutes}));
		
		var endhlbl='monend_hours';
		var end_hours='';
		setInputsMonday(inputsMonday => ({...inputsMonday, [endhlbl]: end_hours}));
		
		var endmlbl='monend_minutes';
		var end_minutes='';
		setInputsMonday(inputsMonday => ({...inputsMonday, [endmlbl]: end_minutes}));
	}
};
const handleTueCheckboxChange = (event) => {
	setTueIsChecked(!isTueChecked);
	var tuelbl='status';
	if(event.target.checked)
	{
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [tuelbl]: 1}));
	}else{
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [tuelbl]: 0}));
		
		var starthlbl='tuestart_hours';
		var start_hours='';
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [starthlbl]: start_hours})); 
		
		var startmlbl='tuestart_minutes';
		var start_minutes='';
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [startmlbl]: start_minutes}));
		
		var endhlbl='tueend_hours';
		var end_hours='';
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [endhlbl]: end_hours}));
		
		var endmlbl='tueend_minutes';
		var end_minutes='';
		setInputsTuesday(inputsTuesday => ({...inputsTuesday, [endmlbl]: end_minutes})); 
	}
};
const handleWedCheckboxChange = (event) => {
	setWedIsChecked(!isWedChecked);
	var wedlbl='status';
	if(event.target.checked)
	{
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [wedlbl]: 1}));
	}else{
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [wedlbl]: 0}));
		
		var starthlbl='wedstart_hours';
		var start_hours='';
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [starthlbl]: start_hours})); 
		
		var startmlbl='wedstart_minutes';
		var start_minutes='';
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [startmlbl]: start_minutes}));
		
		
		var endhlbl='wedend_hours';
		var end_hours='';
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [endhlbl]: end_hours}));
		
		var endmlbl='wedend_minutes';
		var end_minutes='';
		setInputsWednesday(inputsWednesday => ({...inputsWednesday, [endmlbl]: end_minutes}));  
	}
};
const handleThuCheckboxChange = (event) => {
	setThuIsChecked(!isThuChecked);
	var thulbl='status';
	if(event.target.checked)
	{
		setInputsThursday(inputsThursday => ({...inputsThursday, [thulbl]: 1}));
	}else{
		setInputsThursday(inputsThursday => ({...inputsThursday, [thulbl]: 0}));
		
		var starthlbl='thustart_hours';
		var start_hours='';
		setInputsThursday(inputsThursday => ({...inputsThursday, [starthlbl]: start_hours})); 
		
		var startmlbl='thustart_minutes';
		var start_minutes='';
		setInputsThursday(inputsThursday => ({...inputsThursday, [startmlbl]: start_minutes}));
		
		var endhlbl='thuend_hours';
		var end_hours='';
		setInputsThursday(inputsThursday => ({...inputsThursday, [endhlbl]: end_hours}));
		
		var endmlbl='thuend_minutes';
		var end_minutes='';
		setInputsThursday(inputsThursday => ({...inputsThursday, [endmlbl]: end_minutes}));
	}
};
const handleFriCheckboxChange = (event) => {
	setFriIsChecked(!isFriChecked);
	var frilbl='status';
	if(event.target.checked)
	{
		setInputsFriday(inputsFriday => ({...inputsFriday, [frilbl]: 1}));
	}else{
		setInputsFriday(inputsFriday => ({...inputsFriday, [frilbl]: 0}));
		
		var starthlbl='fristart_hours';
		var start_hours='';
		setInputsFriday(inputsFriday => ({...inputsFriday, [starthlbl]: start_hours})); 
		
		var startmlbl='fristart_minutes';
		var start_minutes='';
		setInputsFriday(inputsFriday => ({...inputsFriday, [startmlbl]: start_minutes}));
		
		var endhlbl='friend_hours';
		var end_hours='';
		setInputsFriday(inputsFriday => ({...inputsFriday, [endhlbl]: end_hours}));
		
		var endmlbl='friend_minutes';
		var end_minutes='';
		setInputsFriday(inputsFriday => ({...inputsFriday, [endmlbl]: end_minutes})); 
	}
};
const handleFInputChange = (event) => {
  event.persist();
  setInputsF(inputsf => ({...inputsf, [event.target.name]: event.target.value}));         
}
const handleSatCheckboxChange = (event) => {
	setSatIsChecked(!isSatChecked);
	var satlbl='status';
	if(event.target.checked)
	{
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [satlbl]: 1}));
	}else{
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [satlbl]: 0}));
		
		var starthlbl='satstart_hours';
		var start_hours='';
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [starthlbl]: start_hours})); 
		
		var startmlbl='satstart_minutes';
		var start_minutes='';
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [startmlbl]: start_minutes}));
		
		var endhlbl='satend_hours';
		var end_hours='';
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [endhlbl]: end_hours}));
		
		var endmlbl='satend_minutes';
		var end_minutes='';
		setInputsSaturday(inputsSaturday => ({...inputsSaturday, [endmlbl]: end_minutes}));
	}
};
const handleSunInputChange = (event) => {
  event.persist();
  setInputsSunday(inputsSunday => ({...inputsSunday, [event.target.name]: event.target.value}));         
}
const handleMonInputChange = (event) => {
  event.persist();
  setInputsMonday(inputsMonday => ({...inputsMonday, [event.target.name]: event.target.value}));        
}
const handleTueInputChange = (event) => {
  event.persist();
  setInputsTuesday(inputsTuesday => ({...inputsTuesday, [event.target.name]: event.target.value}));         
}
const handleWedInputChange = (event) => {
  event.persist();
  setInputsWednesday(inputsWednesday => ({...inputsWednesday, [event.target.name]: event.target.value}));         
}
const handleThuInputChange = (event) => {
  event.persist();
  setInputsThursday(inputsThursday => ({...inputsThursday, [event.target.name]: event.target.value}));  
}
const handleFriInputChange = (event) => {
  event.persist();
  setInputsFriday(inputsFriday => ({...inputsFriday, [event.target.name]: event.target.value}));        
}
const handleSatInputChange = (event) => {
  event.persist();
  setInputsSaturday(inputsSaturday => ({...inputsSaturday, [event.target.name]: event.target.value}));        
}
/* End working hour checked */
/* start input refuse */
const handleOnChangerefuse = (event) => {
	var refuselbl="auto_refuse";
	if(event.target.checked)
	{	
		setInputs(inputs => ({...inputs, [refuselbl]: 1}));
	}else{
		setInputs(inputs => ({...inputs, [refuselbl]: 0}));
	}
}
/* start input refuse */
/* End Phone Number */
const handleSetVal = () => {
	var start_hours=inputsf.start_hours;
	var start_minutes=inputsf.start_minutes;
	var end_hours=inputsf.end_hours;
	var end_minutes=inputsf.end_minutes;
	
	setSunIsChecked(true);
	setMonIsChecked(true);
	setTueIsChecked(true);
	setWedIsChecked(true);
	setThuIsChecked(true);
	setFriIsChecked(true);
	setSatIsChecked(true);
	
	var sunlbl='status';
	setInputsSunday(inputsSunday => ({...inputsSunday, [sunlbl]: 1}));
	
	var monlbl='status';
	setInputsMonday(inputsMonday => ({...inputsMonday, [monlbl]: 1}));
	
	var tuelbl='status';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [tuelbl]: 1}));
	
	var wedlbl='status';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [wedlbl]: 1}));
	
	var thulbl='status';
	setInputsThursday(inputsThursday => ({...inputsThursday, [thulbl]: 1}));
	
	var frilbl='status';
	setInputsFriday(inputsFriday => ({...inputsFriday, [frilbl]: 1}));
	
	var satlbl='status';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [satlbl]: 1}));
	
	var starthlbl='sunstart_hours';
	setInputsSunday(inputsSunday => ({...inputsSunday, [starthlbl]: start_hours})); 
	
	var startmlbl='sunstart_minutes';
	setInputsSunday(inputsSunday => ({...inputsSunday, [startmlbl]: start_minutes}));
	
	
	var endhlbl='sunend_hours';
	setInputsSunday(inputsSunday => ({...inputsSunday, [endhlbl]: end_hours}));
	
	var endmlbl='sunend_minutes';
	setInputsSunday(inputsSunday => ({...inputsSunday, [endmlbl]: end_minutes}));
	
	
	var starthlbl1='monstart_hours';
	setInputsMonday(inputsMonday => ({...inputsMonday, [starthlbl1]: start_hours})); 
	
	var startmlbl1='monstart_minutes';
	setInputsMonday(inputsMonday => ({...inputsMonday, [startmlbl1]: start_minutes}));

	
	var endhlbl1='monend_hours';
	setInputsMonday(inputsMonday => ({...inputsMonday, [endhlbl1]: end_hours}));
	
	var endmlbl1='monend_minutes';
	setInputsMonday(inputsMonday => ({...inputsMonday, [endmlbl1]: end_minutes}));
	
	
	var starthlbl2='tuestart_hours';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [starthlbl2]: start_hours})); 
	
	var startmlbl2='tuestart_minutes';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [startmlbl2]: start_minutes}));
	
	var endhlbl2='tueend_hours';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [endhlbl2]: end_hours}));
	
	var endmlbl2='tueend_minutes';
	setInputsTuesday(inputsTuesday => ({...inputsTuesday, [endmlbl2]: end_minutes}));
	
	
	var starthlbl3='wedstart_hours';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [starthlbl3]: start_hours})); 
	
	var startmlbl3='wedstart_minutes';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [startmlbl3]: start_minutes}));
	
	var endhlbl3='wedend_hours';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [endhlbl3]: end_hours}));
	
	var endmlbl3='wedend_minutes';
	setInputsWednesday(inputsWednesday => ({...inputsWednesday, [endmlbl3]: end_minutes}));
	
	var starthlbl4='thustart_hours';
	setInputsThursday(inputsThursday => ({...inputsThursday, [starthlbl4]: start_hours})); 
	
	var startmlbl4='thustart_minutes';
	setInputsThursday(inputsThursday => ({...inputsThursday, [startmlbl4]: start_minutes}));
	
	var endhlbl4='thuend_hours';
	setInputsThursday(inputsThursday => ({...inputsThursday, [endhlbl4]: end_hours}));
	
	var endmlbl4='thuend_minutes';
	setInputsThursday(inputsThursday => ({...inputsThursday, [endmlbl4]: end_minutes}));
	
	
	var starthlbl5='fristart_hours';
	setInputsFriday(inputsFriday => ({...inputsFriday, [starthlbl5]: start_hours})); 
	
	var startmlbl5='fristart_minutes';
	setInputsFriday(inputsFriday => ({...inputsFriday, [startmlbl5]: start_minutes}));
	
	var endhlbl5='friend_hours';
	setInputsFriday(inputsFriday => ({...inputsFriday, [endhlbl5]: end_hours}));
	
	var endmlbl5='friend_minutes';
	setInputsFriday(inputsFriday => ({...inputsFriday, [endmlbl5]: end_minutes}));
	
	var starthlbl6='satstart_hours';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [starthlbl6]: start_hours})); 
	
	var startmlbl6='satstart_minutes';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [startmlbl6]: start_minutes}));
	
	var endhlbl6='satend_hours';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [endhlbl6]: end_hours}));
	
	var endmlbl6='satend_minutes';
	setInputsSaturday(inputsSaturday => ({...inputsSaturday, [endmlbl6]: end_minutes}));
}
 
 
  return (
	<Row xs={{ gutterX: 1 }} className="mx-0 mt-4">
	    <Form method="post" onSubmit={handleSubmit1} enctype="multipart/form-data">
                     <Col md={12} xs={12} className="ppinpt">
                       {error && (
					<div className="alert alert-danger">
					  {error}
					</div>)}
				  {success && (
					<div className="alert alert-success">
					  {success}
					</div>)}
                        <Form.Group className="mb-3">
                           <Form.Label>Employee ID</Form.Label>
                           <Form.Control type="text" placeholder="Enter employee ID" name="employee_id"  value={props.data.employee_id} disabled={true} />
							{errors.employee_id && (<p className="text-danger">{errors.employee_id}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Employee Name</Form.Label>
                           <Form.Control type="text" placeholder="Enter employee name" name="employee_name" onChange={handleInputChange} value={inputs.employee_name}/>
							{errors.employee_name && (<p className="text-danger">{errors.employee_name}</p>)}
						</Form.Group>
						<Form.Group className="mb-3">
                           <Form.Label>Email</Form.Label>
                           <Form.Control type="text" placeholder="Enter employee email" name="employee_email" onChange={handleInputChange} value={inputs.employee_email}/>
							{errors.employee_email && (<p className="text-danger">{errors.employee_email}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Employee Contact No.</Form.Label>
                           <Form.Control type="tel" placeholder="984 236 564 0" name="employee_contact_no" ref={inputRef} onChange={formatAsPhoneNumber} value={inputs.employee_contact_no}/>
                           {errors.employee_contact_no && (<p className="text-danger">{errors.employee_contact_no}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Date of Birth</Form.Label>
                           <Form.Control type="date" placeholder="dd/mm/yy" name="date_of_birth" onChange={handleInputChange} value={inputs.date_of_birth} max={new Date().toJSON().slice(0, 10)}/>
							{errors.date_of_birth && (<p className="text-danger">{errors.date_of_birth}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3">
                           <Form.Label>Commission (in %)<span></span></Form.Label>
                           <Form.Control type="Number" placeholder="5%" min="0" name="commission" onChange={handleInputChange} value={inputs.commission}/>
                           {errors.commission && (<p className="text-danger">{errors.commission}</p>)}
						</Form.Group>
                        <div className="custm-add mb-3">
                           <NavLink to="#">
                              <div className="linetp-cutmadd"></div>
                              <Button variant="" onClick={handleShow2}>
                                 Working Hours Setup
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M7.62132 0.0863132C7.43385 0.183171 7.27138 0.373764 7.21826 0.558107C7.19952 0.633095 7.18702 1.7829 7.18702 3.92941V7.18822H3.93134C1.78172 7.18822 0.631923 7.20072 0.556936 7.21947C0.0945177 7.34757 -0.139816 7.92872 0.0882688 8.37864C0.182002 8.56611 0.372594 8.72858 0.556936 8.7817C0.631923 8.80045 1.78172 8.81295 3.93134 8.81295H7.18702V12.0686C7.18702 14.2183 7.19952 15.3681 7.21826 15.4431C7.34637 15.9055 7.92751 16.1398 8.37743 15.9117C8.5649 15.818 8.72737 15.6274 8.78049 15.4431C8.79923 15.3681 8.81173 14.2183 8.81173 12.0686V8.81295H12.0705C14.217 8.81295 15.3668 8.80045 15.4418 8.7817C15.6262 8.72858 15.8167 8.56611 15.9136 8.37864C16.1386 7.92872 15.9042 7.34757 15.4418 7.21947C15.3668 7.20072 14.217 7.18822 12.0705 7.18822H8.81173V3.92941C8.81173 1.7829 8.79923 0.633095 8.78049 0.558107C8.68988 0.233163 8.36181 0.00195217 7.99938 0.00195217C7.83378 0.00195217 7.75567 0.0206995 7.62132 0.0863132Z" fill="#3F9534"/>
                                 </svg>
                              </Button>
                           </NavLink>
						   {errors.sunstart && (<p className="text-danger">{errors.sunstart}</p>)}
							{errors.sunend && (<p className="text-danger">{errors.sunend}</p>)}
							{errors.monstart && (<p className="text-danger">{errors.monstart}</p>)}
							{errors.monend && (<p className="text-danger">{errors.monend}</p>)}
							{errors.tuestart && (<p className="text-danger">{errors.tuestart}</p>)}
							{errors.tueend && (<p className="text-danger">{errors.tueend}</p>)}
							{errors.wedstart && (<p className="text-danger">{errors.wedstart}</p>)}
							{errors.wedend && (<p className="text-danger">{errors.wedend}</p>)}
							{errors.thustart && (<p className="text-danger">{errors.thustart}</p>)}
							{errors.thuend && (<p className="text-danger">{errors.thuend}</p>)}
							{errors.fristart && (<p className="text-danger">{errors.fristart}</p>)}
							{errors.friend && (<p className="text-danger">{errors.friend}</p>)}
							{errors.satstart && (<p className="text-danger">{errors.satstart}</p>)}
							{errors.satend && (<p className="text-danger">{errors.satend}</p>)}
                        </div>
                        <Form.Group className="mb-3 pswinpt">
                           <Form.Label>Password<span></span></Form.Label>
                           <Form.Control type={values.showPassword ? "text" : "password"} id="enpsw" placeholder="At least 8 characters" name="password" onChange={handleInputChange} value={inputs.password}/>
                           {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
                          {errors.password && (<p className="text-danger">{errors.password}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3 pswinpt">
                           <Form.Label>Re-enter Password<span></span></Form.Label>
                           <Form.Control type={values1.showPassword ? "text" : "password"} id="renpsw" placeholder="At least 8 characters" name="password_confirmation" onChange={handleInputChange} value={inputs.password_confirmation}/>
                           {values1.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword1}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword1}></i>}
                           {errors.password_confirmation && (<p className="text-danger">{errors.password_confirmation}</p>)}
						</Form.Group>
                     </Col>
                     <Row className="pp-btn1">
                        <Col>
                       <Button className="blc" type="submit">Add Employee</Button>
                        </Col>
                     </Row>
					 </Form>
		
{/* ========== Employee Management Employee Setup Popup Start ========== */}
               <Modal show={show1} onHide={handleClose1} className="popupwrngcnt">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Subscription Plan</h3>
                  </Modal.Header>
                  <Modal.Body>
				  	<Col md={12} xs={12} className="ppinpt">
		 <h3 className="sbsrptn-tital">Price : $30</h3>
		 <Form>
			<Form.Group className="mb-3">
			   <Form.Label> </Form.Label>
			</Form.Group>
		 </Form>
		 </Col>
		<Col md={12} xs={12} >
		 <div className="mb-3">
			<CardNumberElement  className="mb-3 form-control"  options={cardNumberElementOptions}  onChange={(e) => handleChange(e)} />
			
		 </div>
		</Col>
	   <Col md={12} xs={12} >
		 <div className="mb-3">
			<CardExpiryElement  className="mb-3 form-control"  onChange={(e) => handleChange(e)} />
			
		 </div>
		</Col>
		<Col md={12} xs={12} >
		 <div className="mb-3">
			<CardCvcElement  className="mb-3 form-control" onChange={(e) => handleChange(e)}/>
			
		 </div>
		</Col>
	          <div className="text-danger mb-2">{checkoutError && <div>{checkoutError}</div>}</div>
			
			
			              
							   <Row className="pp-btn1 mx-0">
                              <Col><NavLink to="#" onClick={() => handleSubmit()}><Button className="blc" variant="">Pay Now </Button></NavLink></Col>
                           </Row>
						   {loading1 && (
						   <div className="loadingClass">
								<img src={loader} alt="loader"/>
							</div>
						   )}  
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
            {/* ========== Employee Management Employee Setup Popup End ========== */}
{/* ========== Employee Management Working Hours Setup Popup Start ========== */}
               <Modal show={show2} onHide={handleClose2} className="popupwrngcnt mainpopwork">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Working Hours Setup</h3>
                  </Modal.Header>
                  <Modal.Body>
                     
					   <Row className="chkbtn chkbtnmain mx-0 mb-1">
						   <Col xs={10} className="align-self-center mb-1">
                              <Form.Label>Set timing for all days together</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end mb-1">
                               <Form.Check type="switch" id="custom-switch" label="" checked={inputsf.status} onChange={handleCheckboxChange}/>
                              </Col>
							  
							  {isChecked &&
						     <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center">
                                 <Form.Label>Start </Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="start_hours" className="mb-2" aria-label="Default select example" value={inputsf.start_hours} onChange={handleFInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="start_minutes" className="mb-2" aria-label="Default select example" value={inputsf.start_minutes} onChange={handleFInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="end_hours" className="mb-2" aria-label="Default select example" value={inputsf.end_hours} onChange={handleFInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="end_minutes" className="mb-2" aria-label="Default select example" value={inputsf.end_minutes} onChange={handleFInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								   <Col xs={12} className="align-self-center mb-3">
									<NavLink to="#" onClick={() => handleSetVal()}><Button className="blc profilefirst " variant="">Submit</Button></NavLink>
								   </Col>
								 </Row>
                                
                                 </Col>
						
                              </Row>
                              </Col>
							  
						     }
                              </Row>
					        <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label>Sunday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsSunday.status} onChange={handleSunCheckboxChange} />
                              </Col>
                              {isSunChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start </Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="sunstart_hours" className="mb-2" aria-label="Default select example" value={inputsSunday.sunstart_hours} onChange={handleSunInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="sunstart_minutes" className="mb-2" aria-label="Default select example" value={inputsSunday.sunstart_minutes} onChange={handleSunInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="sunstart" value={inputsSunday.start} onChange={handleSunInputChange} />*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="sunend_hours" className="mb-2" aria-label="Default select example" value={inputsSunday.sunend_hours} onChange={handleSunInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="sunend_minutes" className="mb-2" aria-label="Default select example" value={inputsSunday.sunend_minutes} onChange={handleSunInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="sunend" value={inputsSunday.end} onChange={handleSunInputChange} />*/}
                                 </Col>
                              </Row>
                              </Col>
                              }
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label>Monday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsMonday.status} onChange={handleMonCheckboxChange} />
                              </Col>
                              {isMonChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="monstart_hours" className="mb-2" aria-label="Default select example" value={inputsMonday.monstart_hours} onChange={handleMonInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="monstart_minutes" className="mb-2" aria-label="Default select example" value={inputsMonday.monstart_minutes} onChange={handleMonInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="monstart" value={inputsMonday.start} onChange={handleMonInputChange}  />*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="monend_hours" className="mb-2" aria-label="Default select example" value={inputsMonday.monend_hours} onChange={handleMonInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="monend_minutes" className="mb-2" aria-label="Default select example" value={inputsMonday.monend_minutes} onChange={handleMonInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="monend" value={inputsMonday.end} onChange={handleMonInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }                              
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className="align-self-center">
                              <Form.Label>Tuesday</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsTuesday.status} onChange={handleTueCheckboxChange} />
                              </Col>
                              {isTueChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="tuestart_hours" className="mb-2" aria-label="Default select example" value={inputsTuesday.tuestart_hours} onChange={handleTueInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="tuestart_minutes" className="mb-2" aria-label="Default select example" value={inputsTuesday.tuestart_minutes} onChange={handleTueInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM"  name="tuestart" value={inputsTuesday.start} onChange={handleTueInputChange}/>*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="tueend_hours" className="mb-2" aria-label="Default select example" value={inputsTuesday.tueend_hours} onChange={handleTueInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="tueend_minutes" className="mb-2" aria-label="Default select example" value={inputsTuesday.tueend_minutes} onChange={handleTueInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="tueend" value={inputsTuesday.end} onChange={handleTueInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }                                  
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label>Wednesday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsWednesday.status} onChange={handleWedCheckboxChange}/>
                              </Col>
                              {isWedChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="wedstart_hours" className="mb-2" aria-label="Default select example" value={inputsWednesday.wedstart_hours} onChange={handleWedInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="wedstart_minutes" className="mb-2" aria-label="Default select example" value={inputsWednesday.wedstart_minutes} onChange={handleWedInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>

									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="wedstart" value={inputsWednesday.start} onChange={handleWedInputChange}/>*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="wedend_hours" className="mb-2" aria-label="Default select example" value={inputsWednesday.wedend_hours} onChange={handleWedInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="wedend_minutes" className="mb-2" aria-label="Default select example" value={inputsWednesday.wedend_minutes} onChange={handleWedInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="wedend" value={inputsWednesday.end} onChange={handleWedInputChange} />*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }    
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label>Thursday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsThursday.status} onChange={handleThuCheckboxChange}/>
                              </Col>
                              {isThuChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="thustart_hours" className="mb-2" aria-label="Default select example" value={inputsThursday.thustart_hours} onChange={handleThuInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="thustart_minutes" className="mb-2" aria-label="Default select example" value={inputsThursday.thustart_minutes} onChange={handleThuInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="thustart" value={inputsThursday.start} onChange={handleThuInputChange} />*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="thuend_hours" className="mb-2" aria-label="Default select example" value={inputsThursday.thuend_hours} onChange={handleThuInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="thuend_minutes" className="mb-2" aria-label="Default select example" value={inputsThursday.thuend_minutes} onChange={handleThuInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="thuend" value={inputsThursday.end} onChange={handleThuInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }         
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label>Friday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsFriday.status} onChange={handleFriCheckboxChange}/>
                              </Col>
                              {isFriChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2" >
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="fristart_hours" className="mb-2" aria-label="Default select example" value={inputsFriday.fristart_hours} onChange={handleFriInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="fristart_minutes" className="mb-2" aria-label="Default select example" value={inputsFriday.fristart_minutes} onChange={handleFriInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM"  name="fristart" value={inputsFriday.start} onChange={handleFriInputChange}/>*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="friend_hours" className="mb-2" aria-label="Default select example" value={inputsFriday.friend_hours} onChange={handleFriInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="friend_minutes" className="mb-2" aria-label="Default select example" value={inputsFriday.friend_minutes} onChange={handleFriInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="friend" value={inputsFriday.end} onChange={handleFriInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }    
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={10} className=" mb-2 align-self-center">
                              <Form.Label>Saturday</Form.Label>
                              </Col>
                              <Col xs={2} className=" mb-2 align-self-center text-end">
                              <Form.Check type="switch" id="custom-switch" label="" checked={inputsSaturday.status} onChange={handleSatCheckboxChange}/>
                              </Col>
                              {isSatChecked &&
                              <Col xs={12}>
                              <Row>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>Start</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="satstart_hours" className="mb-2" aria-label="Default select example" value={inputsSaturday.satstart_hours} onChange={handleSatInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="satstart_minutes" className="mb-2" aria-label="Default select example" value={inputsSaturday.satstart_minutes} onChange={handleSatInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="satstart" value={inputsSaturday.start} onChange={handleSatInputChange} />*/}
                                 </Col>
                                 <Col xs={12} className="align-self-center mt-2">
                                 <Form.Label>End</Form.Label>
								 <Row>
								  <Col xs={6}>
									  <Form.Select name="satend_hours" className="mb-2" aria-label="Default select example" value={inputsSaturday.satend_hours} onChange={handleSatInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									  </Form.Select>
								  </Col>
								  <Col xs={6}>
									  <Form.Select name="satend_minutes" className="mb-2" aria-label="Default select example" value={inputsSaturday.satend_minutes} onChange={handleSatInputChange}>
									   <option value="00">00</option>
									   <option value="01">01</option>
									   <option value="02">02</option>
									   <option value="03">03</option>
									   <option value="04">04</option>
									   <option value="05">05</option>
									   <option value="06">06</option>
									   <option value="07">07</option>
									   <option value="08">08</option>
									   <option value="09">09</option>
									   <option value="10">10</option>
									   <option value="11">11</option>
									   <option value="12">12</option>
									   <option value="13">13</option>
									   <option value="14">14</option>
									   <option value="15">15</option>
									   <option value="16">16</option>
									   <option value="17">17</option>
									   <option value="18">18</option>
									   <option value="19">19</option>
									   <option value="20">20</option>
									   <option value="21">21</option>
									   <option value="22">22</option>
									   <option value="23">23</option>
									   <option value="24">24</option>
									   <option value="25">25</option>
									   <option value="26">26</option>
									   <option value="27">27</option>
									   <option value="28">28</option>
									   <option value="29">29</option>
									   <option value="30">30</option>
									   <option value="31">31</option>
									   <option value="32">32</option>
									   <option value="33">33</option>
									   <option value="34">34</option>
									   <option value="35">35</option>
									   <option value="36">36</option>
									   <option value="37">37</option>
									   <option value="38">38</option>
									   <option value="39">39</option>
									   <option value="40">40</option>
									   <option value="41">41</option>
									   <option value="42">42</option>
									   <option value="43">43</option>
									   <option value="44">44</option>
									   <option value="45">45</option>
									   <option value="46">46</option>
									   <option value="47">47</option>
									   <option value="48">48</option>
									   <option value="49">49</option>
									   <option value="50">50</option>
									   <option value="51">51</option>
									   <option value="52">52</option>
									   <option value="53">53</option>
									   <option value="54">54</option>
									   <option value="55">55</option>
									   <option value="56">56</option>
									   <option value="57">57</option>
									   <option value="58">58</option>
									   <option value="59">59</option>
									  </Form.Select>
								  </Col>
								  
								 </Row>
                                 {/*<Form.Control type="time" placeholder="00:00 AM" name="satend" value={inputsSaturday.end} onChange={handleSatInputChange}/>*/}
                                 </Col>
                              </Row>
                              </Col>   
                              }                                  
                           </Row>
                           <Row className="chkbtn mx-0 mb-3">
                              <Col xs={12} className="align-self-center">
                              <h3 className="mdlttltxt float-start">Orders/Appointments settings</h3>
                              <span style={{ }}>
                                 <OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={(props) => (
                                 <Tooltip {...props}>Get more information in FAQs!</Tooltip>
                                 )}placement="bottom">
                                 <NavLink to="/mer-help"> <span className="mdlttltxt text-danger">&nbsp;&#9432; </span></NavLink>
                                 </OverlayTrigger>
                              </span>
                              </Col>
                              <div className="linepop my-1"></div>
                              <Row className="chkbtn mx-0 mb-3 mt-3 px-0">
                                 <Col xs={9} className="align-self-center">
                                 <Form.Label>How Many customers can be accepted per time slot (per hour):</Form.Label>
                                 </Col>
                                 <Col xs={3} className="align-self-center">
                                  <Form.Control type="Number" placeholder="4" name="accepted_per_time_slot"  value={inputs.accepted_per_time_slot} onChange={handleInputChange}/>
                                 </Col>
                              </Row>
                              <Col xs={10} className="align-self-center mb-0">
                              <Form.Label>Auto accept during working hours</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end radio-text-dang mb-2">
                              <Form.Check type="radio" className="chk-radio" name="auto_accept_during_working_hours" id="custom-switch" label="" value="auto_accept_during_working_hours" onChange={handleInputChange} checked={inputs.auto_accept_during_working_hours === 'auto_accept_during_working_hours'}/>
                              </Col>
                              <Col xs={10} className="align-self-center mb-0">
                              <Form.Label>Need Confirmation</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end radio-text-dang mb-2">
								<Form.Check type="radio" className="chk-radio" name="auto_accept_during_working_hours" id="custom-switch" label="" value="need_to_be_confirmed" onChange={handleInputChange} checked={inputs.auto_accept_during_working_hours === 'need_to_be_confirmed'}/>
                              </Col>
                              <Col xs={10} className="align-self-center mb-0">
                              <Form.Label>Auto Refuse (customers in the blocklist)</Form.Label>
                              </Col>
                              <Col xs={2} className="align-self-center text-end radio-text-dang">
								<Form.Check type="switch" name="auto_refuse" id="custom-switch" label="" onChange={handleOnChangerefuse}/>
                              </Col>
                           </Row>
                           <Row className="pp-btn1 mx-0">
                              <Col>
                              <NavLink to="#" onClick={() => handleClose2()}><Button className="blc workhours-confirm" variant="">Confirm</Button></NavLink>
                              </Col>
                           </Row>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
            {/* ========== Employee Management Working Hours Setup Popup End ========== */}			
			
	 {loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
	 </Row>
	
  );
}
