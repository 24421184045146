import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserHome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';


const UserHome = () => {

const [category, setCategory] = useState([]);
//const [product, setProduct] = useState([]);

const [store, setStore] = useState([]);

const [salesstatus,setSalesstatus] = useState(true);
const [totalcoupon, setTotalcoupon] = useState();
const [loading,setLoading] = useState(true);

const [coupon, setCoupon] = useState([]);

const [membercount, setMembercount] = useState(0);

useEffect(() => {
      document.title = `User Home | Blue Faucet`;
      document.getElementById('bodywrap').className='Homepgmain srcblk icnset';
      window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 
	  
	  
	  /* Start get home data */
let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('home', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			   setCategory(response.data.data.category);
			   //setProduct(response.data.data.product);
			   setStore(response.data.data.store);
			   if(response.data.data.sales_status===0)
			   {
				   setSalesstatus(true);
			   }else{
					setSalesstatus(false);
			   }
			   setTotalcoupon(response.data.data.total_coupon);
			   setCoupon(response.data.data.coupon_array);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get home data */
	 
	 /* Start get membership count */
let formData1 = new FormData(); 
		formData1.append('token', localStorage.getItem("token"));
		API.post('member_count', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			 setMembercount(response.data.data);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get membership count */
	  
},[])  
/* Start product like */
const getCoupontype = (val,discount_percentage,minimum_purchase_amount,item_name_buy,product_item_name,buy_qty,get_qty) => {
	var type='';
	if(val==='Buy & Get')
	{
		type='Buy  '+buy_qty+' '+item_name_buy+' and Get '+get_qty+' '+product_item_name+' free of Charge';
	}else if(val==='Discount Offer'){
		type=discount_percentage+'% discount when purchase over $'+minimum_purchase_amount;
	}else{
		type=val;
	}
	return type;
}
 
 
 
    return (
        <>
         {/* ========== Home Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={''}/>
            {/* ========== Navbar End ========== */}
               <Container>
               {/* ========== Category Box Start ========== */}
                  <Row className="hmtpfixdv">
                     <Col md={12} lg={12} xl={12} xxl={12} className="hmtp-scrl">
                     <ul className="hmtp-scldv">
					 {category && category.map((val,index) => {
                        return(
						<NavLink to={`/store-list/${val.id}`} className="active intFilter" data-val="all">
                           <li>
                              <img src={val.image} className="img-fluid" alt="Hm Img"/>
                              <p>{val.name}</p>
                           </li>
                        </NavLink>
                       )
					  })} 
                        <NavLink to="/category-list" className="active intFilter" data-val="all">
                           <li>
                              <img src="assets/images/hm-imgplus.png" className="img-fluid" alt="Hm Img"/>
                              <p>View All</p>
                           </li>
                        </NavLink>
                     </ul>
                     </Col>
                  </Row>
                  {/* ========== Services Box End ========== */}
                  <Row className="mx-0 mianhmdwncnt">
                     <Col className="mnhmcnt">
					 <Col md={12} xs={12} className="btn-hmdwnbnr mt-3">
						 {membercount===0 ? ( <NavLink to="/membership-invitation"><Button variant="" className="home-btn-mem-inv">Membership invitations</Button>{' '}</NavLink>) : (<NavLink to="/membership-invitation"><Button variant="" className="home-btn-mem-inv">You got Invitatioins</Button>{' '}</NavLink>) }
					 </Col>
                     <Col md={12} xs={12} className="py-3 tpbnr">
                     <NavLink to="/coupon-list">
                      <Row className="mx-0 cuscoupn cuscoupnhmtp">
                       <Col className="align-self-center">
                        <p>{totalcoupon} Coupons Available</p>
                        <Button variant="btn">View All</Button>{' '}
                       </Col>
                       <Col className="align-self-end text-end"><img src="assets/images/box.png" className="img-fluid w-100" alt="Hmtp Postr"/></Col>
                      </Row>
                     </NavLink>
                     </Col>
					 {salesstatus && (
                     <Col md={12} xs={12} className="btn-hmdwnbnr mb-3">
                     <NavLink to="/sales-person"><Button variant="">Sales Funnel</Button>{' '}</NavLink>
					 </Col>
					 )}

					<Col md={12} xs={12} className="hmcrdsldr  my-md-2">
                     <div className="tptxtcnt mb-3">
                        <p>Today’s Special</p>
                        <NavLink to="/coupon-list"> View All </NavLink>
                     </div>
                     <Col className="sldrhm home-slider">
					
						{coupon.length >0 &&(
                     <OwlCarousel items={1.2}  className='owl-theme owl-img-coup' loop={coupon.length >1} margin={20} >
							{coupon && coupon.map((val,index) => {
							return (
						<div>
						  {(() => {
							if (val.image==='1'){
							  return (<Col md={12} xs={12} className="cupn-crd coup-height">
							   <div className="coupon">
								  <div className="left">
									 <div>Discount</div>
								  </div>
								  <div className="center">
									 <div>
										<h2>{val.coupon_name}</h2>
										<p>Welcome to {val.store_name}</p>
										<p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
											{val.return_befor ==='' ? (
												<p>Start Date : <span>{val.start_date}</span> &nbsp;&nbsp;End Date :<span>{val.end_date}</span> </p>
											) : (
												<p>Return Befor : <span>{val.return_befor}</span> </p>
											)}
									 </div>
								  </div>
								  
							   </div>
							   </Col> )
							} else if (val.image==='2') {
							  return (<Col className="mb-3 main-copn coup-height">
								  <Row className="mx-0 second-coupan mb-2">
								  <Col className="align-self-center copn-text">
								    <h5>{val.coupon_name}</h5>
										<p>Welcome to {val.store_name}</p>
										<p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
										{val.return_befor ==='' ? (
										  <>
											<p>Start Date : <span>{val.start_date}</span> </p>
											<p>End Date :<span>{val.start_date}</span> </p>
											</>
										) : (
												<p>Return Befor : <span>{val.return_befor}</span> </p>
										)}
								  </Col>
								  <Col className="align-self-end text-end"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/cupn-img.png'} className="img-fluid w-100" alt="Hmtp Postr"/></Col>
								 </Row>
							 </Col>)
							}else if (val.image==='3') {
							  return (<Col className="mb-3 main-copn coup-height">
								<Row className="mx-0 third-copn ">
								   <Col className="align-self-end text-white col copn-text text-end mb-5 px-5">
									<h5 className="text-dark">{val.coupon_name}</h5>
										<p className="text-dark">Welcome to {val.store_name}</p>
										<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
												<p className="text-dark">Start Date : <span>{val.start_date}</span> &nbsp;&nbsp;End Date :<span>{val.end_date}</span> </p>
											) : (
												<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
								   </Col>
								  </Row>
							</Col>)
							}else if (val.image==='4') {
							  return (<Col className="mb-3 main-copn coup-height">
							      {(() => {
								if (val.coupon_type==='Welcome coupon'){
									return (<Row className="mx-0 four-copn ">
									   <Col className="align-self-end text-dark col copn-text text-end">
										<h5 className="text-dark">Welcome to {val.store_name}</h5>
											<p className="text-dark">We appreciate your membership enrollment. Enjoy a {val.discount_percentage}% discount on your upcoming visit as a token of our gratitude. We look forward to providing you with excellent service during your visit.</p>
									   </Col>
									  </Row>)
									}else{
										return (<Row className="mx-0 four-copn ">
									   <Col className="align-self-end text-dark col copn-text text-end">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
										    <p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											<div>
											   {(() => {
												if (val.start_date!=='') {
													return (
													<p className="text-dark">Start Date : <span>{val.start_date}</span> &nbsp;&nbsp;End Date :<span>{val.end_date}</span> </p>
												)
												}    
						                        })()}
												</div>
											) : (
												<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
											
									   </Col>
									  </Row>)
									}										
								})()}  
								</Col>)
							}else if (val.image==='5') {
							  return (<Col className="mb-3 main-copn coup-height">
									<Row className="mx-0 five-copn justify-content-center ">
									   <Col md={6} xs={8} className="align-self-center text-dark col copn-text col-6">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
										    <p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='6') {
							  return (<Col className="mb-3 main-copn coup-height">
									<Row className="mx-0 six-copn justify-content-center ">
									   <Col md={6} xs={8} className="align-self-center text-dark col copn-text col-6">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
										    <p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='7') {
							  return (<Col className="mb-3 main-copn coup-height">
									<Row className="mx-0 seven-copn justify-content-end ">
									   <Col md={6} xs={8} className="align-self-center text-white col copn-text text-end col-6 mt-5">
										<h5 className="">{val.coupon_name}</h5>
										<p>Welcome to {val.store_name}</p>
										    <p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
										{val.return_befor ==='' ? (
										  <>
											<p>Start Date : <span>{val.start_date}</span> </p>
											<p>End Date :<span>{val.start_date}</span> </p>
											</>
										) : (
												<p>Return Befor : <span>{val.return_befor}</span> </p>
										)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='8') {
							  return (<Col className="mb-3 main-copn coup-height">
									<Row className="mx-0 eight-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
										    <p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='9') {
							  return (<Col className="mb-3 main-copn coup-height">
									<Row className="mx-0 nine-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
										    <p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='10') {
							  return (<Col className="mb-3 main-copn coup-height">
									<Row className="mx-0 ten-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
										    <p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='11') {
							  return (<Col className="mb-3 main-copn coup-height">
									<Row className="mx-0 eleven-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
										    <p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}    
						  })()}
						</div>
					  )
					  })} 
                     </OwlCarousel>
					 )}
					 </Col>
                     </Col>
					 { /* 
product.length >0 &&(
                     <Col md={12} xs={12} className="hmcrdsldr  my-md-2">
                     <div className="tptxtcnt mb-3">
                        <p>Today’s Special</p>
                        <NavLink to="/favorite"> View All </NavLink>
                     </div>
                     <Col className="sldrhm">
                     

			
			{<OwlCarousel items={2.2}  className='owl-theme' loop margin={10} id="owlid">
                {product.map((val) => {
                        return ( <Col className="hmone-slrcrd">                        
                           <div className="ginlike">
                              <div className="">
							  
							  {val.status ? <svg className={`lik lik${val.id}`}  xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                                    like(val.id,val.store_id)}>
                                    <circle cx="12" cy="12" r="12" fill="white"/>
                                    <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
                                 </svg>  : <svg className={`dis dis${val.id}`} width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                                    dislike(val.id,val.store_id)}>
                                    <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
                                    <g id="Black">
                                       <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
                                    </g>
                                 </svg>
        } 		 
                              </div>
                           </div>
                           <NavLink to="/favorite">                           
                              <div className="sldtp-imgdv">
                                 <img src={val.image_thuumb} className="img-fluid" alt="Sld Img"/>
                              </div>
                              <div className="sldcntdv">
                                 <h3>{val.name}</h3>
                                 <p>${val.price}</p>
                              </div>
                           </NavLink>
                        </Col>
				)
				})
                }
</OwlCarousel>
                     </Col>
                     </Col>
					 )
*/}
{
				store.length >0 &&(
                     <Col md={12} xs={12} className="hmcrdsldr  my-md-2">
                     <div className="tptxtcnt mb-3">
                        <p>Your Favorite Stores</p>
                        <NavLink to="/favorite"> View All </NavLink>
                     </div>
                     <Col className="sldrhm">
                     {/* ========== OwlCarousel Box Card Start ========== */}
					 
                     <OwlCarousel items={2.2}  className='owl-theme twosld' loop={store.length >2} margin={10} >
					 {store.map((val) => {
                        return (
                        <Col className="hmone-slrcrd">
                        <NavLink to={`/store-details/${val.id}`}>
                           <div className="sldtp-imgdv">
                              <img src= {val.image} className="img-fluid" alt="Sld Img"/>
                           </div>
                           <div className="sldcntdv">
                              <h3>{val.store_name}</h3>
                              
                           </div>
                        </NavLink>
                        </Col>
                      )
					})
					}   
                     </OwlCarousel>
					 
                     {/* ========== OwlCarousel Box Card End ========== */}
                     </Col>
                     </Col>
					 )
					}	
                     <Col className="hmlst-bnr mb-4">
					{salesstatus ? (
                     <NavLink to="/sales-person">
                      <Row className="mx-0 cuscoupn cupndwnhm">
                       <Col className="align-self-start">
					   {/*<h3>Make $60 Commission! Click here to find out more detail</h3>*/}
                       </Col>
                      </Row>
                     </NavLink>
					):(
						<NavLink to="/sales-invite-merchant">
						  <Row className="mx-0 cuscoupn cupndwnhm">
						   <Col className="align-self-start">
						   {/*<h3>Make $60 Commission! Click here to find out more detail</h3>*/}
						   </Col>
						  </Row>
						 </NavLink>
					)}
                      </Col>
                     </Col>
                  </Row>
				  {loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
               </Container>
            </section>
            <Footer/>
         {/* ========== Home Page Start ========== */}
        </>
    )
}
export default UserHome;