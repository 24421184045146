import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button,  Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import './UserCupn.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';

const UserCupn = () => {
const [category, setCategory] = useState([]);
const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);

const [loading,setLoading] = useState(true);

const [coupon, setCoupon] = useState([]);

useEffect(() => {
  document.title = `User Coupons | Blue Faucet`;
  document.getElementById('bodywrap').className='ucupnpgmain';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
	/* Start get category data */
		API.get('category')
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			  setCategory(response.data.data);
			}                  
		}).catch(error => {
			 
		});   
	 /* End get category data */
	 
	 /* Start get coupons data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', '');
		API.post('get_user_coupon_assign', formData)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
			   setCoupon(response.data.data);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get coupons data */
	
},[])  
 
/* Start coupon sorting */
const searchSort = (filter_type) => {	
		setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('type', filter_type);
	API.post('get_user_coupon_assign', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			setCoupon(response.data.data);
		}                  
	}).catch(error => {
		 
	});  
}
/* End coupon sorting */
const getCoupontype = (val,discount_percentage,minimum_purchase_amount,item_name_buy,product_item_name,buy_qty,get_qty) => {
	var type='';
	if(val==='Buy & Get')
	{
		type='Buy  '+buy_qty+' '+item_name_buy+' and Get '+get_qty+' '+product_item_name+' free of Charge';
	}else if(val==='Discount Offer'){
		type=discount_percentage+'% discount when purchase over $'+minimum_purchase_amount;
	}else{
		type=val;
	}
	return type;
}


    return (
        <>
         {/* ========== User Coupons Page Start ========== */}
            <section className="gen-wrap">
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={'Coupons'}/>
            {/* ========== Navbar End ========== */}
               <Container>
                  <Row className="hmtpfixdv">
                     <Col md={12} lg={12} xl={12} xxl={12} className="hmtp-scrl uclsrldv">
                     <ul className="hmtp-scldv">
                        <Row className="mx-0 ucl-sfbtn">
                           <Col>
                           <NavLink to="#">
                              <Dropdown>
                                 <Dropdown.Toggle variant="" id="dropdown-basic"><span><img src="assets/images/svg/ucl-srtimg.svg" className="img-fluid" alt="Usl Img"/></span>Sort</Dropdown.Toggle>
                                 <Dropdown.Menu>
                                    <Dropdown.Item href={void(0)} onClick={() =>searchSort('popular')}>Popular</Dropdown.Item>
                                    <Dropdown.Item href={void(0)} onClick={() =>searchSort('expiration')}>Expiration</Dropdown.Item>
                                 </Dropdown.Menu>
                              </Dropdown>
                           </NavLink>
                           </Col>
                           <Col>
                           
                           <NavLink to="#">
                              <Dropdown>
                                 <Dropdown.Toggle variant="" id="dropdown-basic"><span><img src="assets/images/svg/ucl-fltrimg.svg" className="img-fluid" alt="Usl Img"/></span>Filter</Dropdown.Toggle>
                                 <Dropdown.Menu>
								  {category && category.map((val,index) => {
									return(
                                    <Dropdown.Item href={void(0)}>{val.name}</Dropdown.Item>
									)
								})}
                                 </Dropdown.Menu>
                              </Dropdown>
                           </NavLink>
						   </Col>
                        </Row>
                     </ul>
                     </Col>
                  </Row>
                  <Row className="mx-0 mianhmdwncnt">
                     <Col className="mnhmcnt">
                    
					{coupon.length >0 ? (
					 <Row className="mx-0">
					{coupon && coupon.map((val,index) => {
						
						  return (
						<div>
						  {(() => {
							if (val.image==='1') {
							  return (<Col md={12} xs={12} className="py-2 cupn-crd mb-3">
							   <div className="coupon">
								  <div className="left">
									 <div>Discount</div>
								  </div>
								  <div className="center">
									 <div>
										<h2>{val.coupon_name}</h2>
										<p>Welcome to {val.store_name}</p>
										<p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
											{val.return_befor ==='' ? (
												<p>Start Date : <span>{val.start_date}</span> &nbsp;&nbsp;End Date :<span>{val.end_date}</span> </p>
											) : (
												<p>Return Befor : <span>{val.return_befor}</span> </p>
											)}
									 </div>
								  </div>
								  
							   </div>
							   </Col> )
							} else if (val.image==='2') {
							  return (<Col className="mb-3 main-copn">
								  <Row className="mx-0 second-coupan mb-2">
								  <Col className="align-self-center copn-text">
								    <h5>{val.coupon_name}</h5>
										<p>Welcome to {val.store_name}</p>
										<p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
										{val.return_befor ==='' ? (
										  <>
											<p>Start Date : <span>{val.start_date}</span> </p>
											<p>End Date :<span>{val.start_date}</span> </p>
											</>
										) : (
												<p>Return Befor : <span>{val.return_befor}</span> </p>
										)}
								  </Col>
								  <Col className="align-self-end text-end"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/cupn-img.png'} className="img-fluid w-100" alt="Hmtp Postr"/></Col>
								 </Row>
							 </Col>)
							}else if (val.image==='3') {
							  return (<Col className="mb-3 main-copn">
								<Row className="mx-0 third-copn ">
								   <Col className="align-self-end text-white col copn-text text-end mb-5 px-5">
									<h5 className="text-dark">{val.coupon_name}</h5>
										<p className="text-dark">Welcome to {val.store_name}</p>
										<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
												<p className="text-dark">Start Date : <span>{val.start_date}</span> &nbsp;&nbsp;End Date :<span>{val.end_date}</span> </p>
											) : (
												<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
								   </Col>
								  </Row>
							</Col>)
							}else if (val.image==='4') {
							  return (<Col className="mb-3 main-copn">
									{(() => {
								if (val.coupon_type==='Welcome coupon'){
									return (<Row className="mx-0 four-copn ">
									   <Col className="align-self-end text-dark col copn-text text-end">
										<h5 className="text-dark">Welcome to {val.store_name}</h5>
											<p className="text-dark">We appreciate your membership enrollment. Enjoy a {val.discount_percentage}% discount on your upcoming visit as a token of our gratitude. We look forward to providing you with excellent service during your visit.</p>
									   </Col>
									  </Row>)
									}else{
										return (<Row className="mx-0 four-copn ">
									   <Col className="align-self-end text-dark col copn-text text-end">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											   <div>
											   {(() => {
												if (val.start_date!=='') {
													return (
														<p className="text-dark">Start Date: <span>{val.start_date}</span> &nbsp;&nbsp;End Date :<span>{val.end_date}</span> </p>
														)
												}    
						                        })()}
											   </div>
											) : (
												<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>)
									}										
								})()}  
								</Col>)
							}else if (val.image==='5') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 five-copn justify-content-center ">
									   <Col md={6} xs={8} className="align-self-center text-dark col copn-text col-6">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='6') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 six-copn justify-content-center ">
									   <Col md={6} xs={8} className="align-self-center text-dark col copn-text col-6 ">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='7') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 seven-copn justify-content-end ">
									   <Col md={6} xs={8} className="align-self-center text-white col copn-text text-end col-6 mt-5">
										<h5 className="">{val.coupon_name}</h5>
										<p>Welcome to {val.store_name}</p>
										<p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
										{val.return_befor ==='' ? (
										  <>
											<p>Start Date : <span>{val.start_date}</span> </p>
											<p>End Date :<span>{val.start_date}</span> </p>
											</>
										) : (
												<p>Return Befor : <span>{val.return_befor}</span> </p>
										)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='8') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 eight-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='9') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 nine-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='10') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 ten-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='11') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 eleven-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}    
						  })()}
						</div>
					  )
						  
					  })}  
					 </Row>
					 ) : (
							<Row className="mx-0">
								<p className="text-center mt-4">No data found</p>
							</Row>
					 )}  
				
					 
					 
                     <Col className="d-none">
      {/* ========== Delete Popup Start ========== */}
                     <Modal show={show2} onHide={handleClose2} className="popupwrngcnt">
                        <Modal.Header closeButton>
                           <h3 className="mdlttltxt">Are you sure you want to delete?</h3>
                        </Modal.Header>
                        <Modal.Body>
                           <Row>
                              <Col className="btn-clr">
                              <NavLink to="#" onClick={handleClose2}><Button variant="">Yes</Button></NavLink>
                              </Col>
                              <Col className="btn-dv">
                              <NavLink to="#" onClick={handleClose2}><Button variant="">No</Button></NavLink>
                              </Col>
                           </Row>
                        </Modal.Body>
                        <Modal.Footer className="d-none"></Modal.Footer>
                     </Modal>
      {/* ========== Delete Popup End ========== */}
                     </Col>
                     </Col>
                  </Row>
				  {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )} 
               </Container>
            </section>
         {/* ========== User Coupons Page End ========== */}
        </>
    )
}

export default UserCupn;