import React, { useEffect, useState,useRef } from 'react';
import { Row, Col, Container, Button, Nav, Modal, Table,Stack } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import './MerOrder.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Footer from "../footer";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';
import { useReactToPrint } from 'react-to-print';

const MerOrder = () => {
	const componentRef = useRef();
	const componentRef1 = useRef();
const [loading,setLoading] = useState(true);


const [coupon,setCoupon] = useState([]);
const [show, setShow] = useState(false);
const handleClose = () => { setShow(false);setTextBoxesDisabled(true); }


const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);

const [show4, setShow4] = useState(false);
const handleClose4 = () => { setShow4(false); setTextBoxesDisabled(true); }

const [show2, setShow2] = useState(false);
const handleClose2 = () => setShow2(false);


const [show3, setShow3] = useState(false);
const handleClose3 = () => setShow3(false);

const [show5, setShow5] = useState(false);
const handleClose5 = () => setShow3(false);

const [showre, setShowre] = useState(false);
const handleClosere = () => setShowre(false);


const [pending,setPending] = useState([]);
const [completed,setCompleted] = useState([]);

const [orderdetail,setOrderdetail] = useState([]);

const [textBoxesDisabled, setTextBoxesDisabled] = useState(true);

const [inputs,setInputs] = useState({id:'',order_id:'',order_number:'',tips:'',saving_amount:0,coupon_per:0,amount:'',sales_tax_rate_per:0,sales_tax_rate:0,subtotal:'',total:'',total_due:'',date:'',store_name:'',store_image:'',store_address:'',store_area:'',store_city:'',store_state:'',store_mobile:'',store_zip:'',user_address_title:'',user_address_street_name:'',user_address_state:'',user_address_city:'',user_address_zip_code:'',user_mobile:'',user_name:'',status_name:'Paid',payment_txn_id:'',payment_type:'',payment_card_name:'',payment_card_number:'',payment_card_month:'',payment_card_year:'',status:4,due_date:'',employee_name:'',agree:0});

const [ordid, setOrdid] = useState('');

useEffect(() => {
      document.title = `Merchant Order Appointment | Blue Faucet`;
      document.getElementById('bodywrap').className='ordrapmntpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
	/* Start get order data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_merchant_orders', formData)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
			   setPending(response.data.data.order_pending);
			   setCompleted(response.data.data.order_completed);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get order data */
	
},[])  

const handleShow = (id,status,status_name) => {
	setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	API.post('get_product_or_service_order_with_order_id', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{
			var sales_tax_rate_per=response.data.data.sales_tax_rate_per;
			var sales_tax_rate=response.data.data.sales_tax_rate;
			var coupon_per=parseFloat(response.data.data.coupon_per);
			var saving_amount=0;
			if(response.data.data.saving_amount!=='')
			{
				saving_amount=parseFloat(response.data.data.saving_amount);
			}
				var subtotal=parseFloat(response.data.data.subtotal);
				var total=subtotal-saving_amount;
				
				var tips=parseFloat(response.data.data.tips);
				var total_due=total+tips+sales_tax_rate;
				
				saving_amount=saving_amount.toFixed(2);
				
				sales_tax_rate=sales_tax_rate.toFixed(2);
				
				subtotal=subtotal.toFixed(2);
				
				tips=tips.toFixed(2);
				
				total_due=total_due.toFixed(2);
				
				setInputs({
					id:response.data.data.id,
					order_id:response.data.data.order_id,
					order_number:response.data.data.order_number,
					tips:tips,
					saving_amount:saving_amount,
					coupon_per:coupon_per,
					amount:response.data.data.amount,
					sales_tax_rate_per:sales_tax_rate_per,
					sales_tax_rate:sales_tax_rate,
					subtotal:subtotal,
					total:total,
					total_due:total_due,
					date:response.data.data.order_date,
					store_name:response.data.data.store_name,
					store_image:response.data.data.store_image,
					store_address:response.data.data.store_address,
					store_area:response.data.data.store_area,
					store_city:response.data.data.store_city,
					store_state:response.data.data.store_state,
					store_mobile:response.data.data.store_mobile,
					store_zip:response.data.data.store_zip,
					user_address_title:response.data.data.user_address_title,
					user_address_street_name:response.data.data.user_address_street_name,
					user_address_state:response.data.data.user_address_state,
					user_address_city:response.data.data.user_address_city,
					user_address_zip_code:response.data.data.user_address_zip_code,
					user_mobile:response.data.data.user_mobile,
					user_name:response.data.data.user_name,
					status_name:response.data.data.status_name,
					payment_txn_id:response.data.data.payment_txn_id,
					payment_type:response.data.data.payment_type,
					payment_card_name:response.data.data.payment_card_name,
					payment_card_number:response.data.data.payment_card_number,
					payment_card_month:response.data.data.payment_card_month,
					payment_card_year:response.data.data.payment_card_year,
					status:response.data.data.status,
					due_date:response.data.data.due_date,
					employee_name:response.data.data.employee_name,
					agree:response.data.data.agree,
				});
			 setOrderdetail(response.data.data.order_detail);
			 setCoupon(response.data.data.coupon);
			 if(status===6 && status_name==='Refund Request')
			 {
			    setShow1(true);
			 }else{
				 setShow(true);
			 }
		}                  
	}).catch(error => {
		 
	});  
}

const handleShow4 = (id) => {
	setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	API.post('get_product_or_service_order_with_order_id', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			
		}else{	
				var sales_tax_rate_per=response.data.data.sales_tax_rate_per;
				var sales_tax_rate=response.data.data.sales_tax_rate;
				var coupon_per=parseFloat(response.data.data.coupon_per);
				var saving_amount=0;
				if(response.data.data.saving_amount!=='')
				{
					saving_amount=parseFloat(response.data.data.saving_amount);
				}
				var subtotal=parseFloat(response.data.data.subtotal);
				var total=subtotal-saving_amount;
				
				var tips=parseFloat(response.data.data.tips);
				var total_due=total+tips+sales_tax_rate;
				
				saving_amount=saving_amount.toFixed(2);
				
				sales_tax_rate=sales_tax_rate.toFixed(2);
				
				subtotal=subtotal.toFixed(2);
				
				tips=tips.toFixed(2);
				
				total_due=total_due.toFixed(2);
			
				setInputs({
					id:response.data.data.id,
					order_id:response.data.data.order_id,
					order_number:response.data.data.order_number,
					tips:tips,
					saving_amount:saving_amount,
					coupon_per:coupon_per,
					amount:response.data.data.amount,
					sales_tax_rate_per:sales_tax_rate_per,
					sales_tax_rate:sales_tax_rate,
					subtotal:subtotal,
					total:total,
					total_due:total_due,
					date:response.data.data.order_date,
					store_name:response.data.data.store_name,
					store_image:response.data.data.store_image,
					store_address:response.data.data.store_address,
					store_area:response.data.data.store_area,
					store_city:response.data.data.store_city,
					store_state:response.data.data.store_state,
					store_mobile:response.data.data.store_mobile,
					store_zip:response.data.data.store_zip,
					user_address_title:response.data.data.user_address_title,
					user_address_street_name:response.data.data.user_address_street_name,
					user_address_state:response.data.data.user_address_state,
					user_address_city:response.data.data.user_address_city,
					user_address_zip_code:response.data.data.user_address_zip_code,
					user_mobile:response.data.data.user_mobile,
					user_name:response.data.data.user_name,
					status_name:response.data.data.status_name,
					payment_txn_id:response.data.data.payment_txn_id,
					payment_type:response.data.data.payment_type,
					payment_card_name:response.data.data.payment_card_name,
					payment_card_number:response.data.data.payment_card_number,
					payment_card_month:response.data.data.payment_card_month,
					payment_card_year:response.data.data.payment_card_year,
					status:response.data.data.status,
					due_date:response.data.data.due_date,
					employee_name:response.data.data.employee_name,
					agree:response.data.data.agree,
				});
				
			 setOrderdetail(response.data.data.order_detail);
			 
			 setCoupon(response.data.data.coupon);
			 
			    setShow4(true);
			 
		}                  
	}).catch(error => {
		 
	});  
}

 const enableTextboxes = () => {
    setTextBoxesDisabled(false);
  };
const handleTextChange = (id, value) => {	
	let updateData = orderdetail.map((item, i) => {
		var total=item.total;
		if(id === item.id)
		{
			value = (typeof value !== 'undefined' && !isNaN(value) && value !== '') ? parseFloat(value) : 0;
			total=(parseFloat(value) * item.qty);
			total=total.toFixed(2);
		}
		return id === item.id ? { ...item, product_price: value, total: total } : item;
	});
	setOrderdetail(updateData);
	var total1=0;
	
	updateData.forEach(item => {
		total1=total1+parseFloat(item.total);
	})
	/*updateData.map((item, i) => {
		total1=total1+parseFloat(item.total);
	});*/	
	
	var coupon_per=inputs.coupon_per;
	var dis=0;
	var total2=0;
	if(coupon_per > 0)
	{
	var dis1=total1*coupon_per;
	dis=total1-dis1;
	
	//var dis=total1*coupon_per/100;

	total2=total1-dis;
	}else{
		total2=total1;
	}
	var sales_tax_rate_per=inputs.sales_tax_rate_per;
	var tax=total2*sales_tax_rate_per/100;
	
	tax=tax.toFixed(2);
	
	var tips=parseFloat(inputs.tips);
	var total_due=parseFloat(total2)+tips+parseFloat(tax);
	
	total1=total1.toFixed(2);
	
	
	total_due=total_due.toFixed(2);
	dis=dis.toFixed(2);
	
	var subtotal_lbl='subtotal';
	setInputs(inputs => ({...inputs, [subtotal_lbl]: total1}));
	
	var sav_lbl='saving_amount';
	setInputs(inputs => ({...inputs, [sav_lbl]: dis}));
	
	var sales_tax_rate_lbl='sales_tax_rate';
	setInputs(inputs => ({...inputs, [sales_tax_rate_lbl]: tax}));
	
	var total_due_lbl='total_due';
	setInputs(inputs => ({...inputs, [total_due_lbl]: total_due}));
}

const CancelOrder = (id) => {
	setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	API.post('cancel_order_by_merchant', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			//alert(response.data.message);
			gettoastwarning(response.data.message,'');
		}else{
			//alert(response.data.message);
			 //window.location.href = "/mer-order-appointment";
			 setShow(false);
			 gettoastsuccess(response.data.message,'/mer-order-appointment');
		}                  
	}).catch(error => {
		 
	});  
}
const handleShow3 = (id) => {
	setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	formData.append('order_detail', JSON.stringify(orderdetail));
	API.post('ordre_invoice_confirm', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			//alert(response.data.message);
			gettoastwarning(response.data.message,'');
		}else{
			setShow3(true);
		}                  
	}).catch(error => {
		 
	});  
}
const handleShow5 = (id) => {
	setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	formData.append('order_detail', JSON.stringify(orderdetail));
	API.post('order_confirm_merchant', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			//alert(response.data.message);
			gettoastwarning(response.data.message,'');
		}else{
			setShow5(true);
		}                  
	}).catch(error => {
		 
	});  
}
const handleShow2 = (id) => {
	setOrdid(id);
	setShow2(true);
}
/*const handleShowre = (id) => {
	setOrdid(id);
	setShowre(true);
}*/
const completeorder = () => {
	 setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', ordid);
	API.post('complete_order_by_merchant', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			//alert(response.data.message);
			
			gettoastwarning(response.data.message,'');
		}else{
			//alert(response.data.message);
			//window.location.href = "/mer-order-appointment";
			setShow2(false);
			gettoastsuccess(response.data.message,'/mer-order-appointment');
		}                  
	}).catch(error => {
 
	});  
}
const refund_order = () => {
	 setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', ordid);
	formData.append('status', 1);
	API.post('refund_order_by_merchant', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			//alert(response.data.message);
			
			gettoastwarning(response.data.message,'');
		}else{
			//alert(response.data.message);
			//window.location.href = "/mer-order-appointment";
			setShowre(false);
			gettoastsuccess(response.data.message,'/mer-order-appointment');
		}                  
	}).catch(error => {
 
	});  
}
const refund_order_no = () => {
	 setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', ordid);
	formData.append('status', 0);
	API.post('refund_order_by_merchant', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			//alert(response.data.message);
			
			gettoastwarning(response.data.message,'');
		}else{
			//alert(response.data.message);
			//window.location.href = "/mer-order-appointment";
			setShowre(false);
			gettoastsuccess(response.data.message,'/mer-order-appointment');
		}                  
	}).catch(error => {
 
	});  
}
const refundorder = (id,status) => {
	setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	formData.append('order_detail', JSON.stringify(orderdetail));
	formData.append('status', status);
	API.post('refund_order_by_employee', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			//alert(response.data.message);
			gettoastwarning(response.data.message,'');
		}else{
			//alert(response.data.message);
			//window.location.href = "/mer-order-appointment";
			setShow1(false);
			gettoastsuccess(response.data.message,'/mer-order-appointment');
		}                  
	}).catch(error => {
		 
	});  
}


const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });

const addressview = (user_address_street_name,user_address_state,user_address_city,user_address_zip_code) => {
	var html='';
	if(user_address_street_name!=='')
	{
		html+=user_address_street_name+', ';
	}
	/*if(user_address_state!=='')
	{
		html+=user_address_state+', ';
	}
	if(user_address_city!=='')
	{
		html+=user_address_city+', ';
	}*/
	if(user_address_zip_code!=='')
	{
		html+=user_address_zip_code;
	}
	return <div dangerouslySetInnerHTML={{__html: html}} />
}

    return (
      <>
      {/* ========== Merchant Order Appointment Page Start ========== */}
         <section className="gen-wrap">
		 <ToastContainer />
      {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Orders & Appointments'}/>
      {/* ========== Navbar End ========== */}
         <Container>
      {/* ========== Order Appointment Tab Content Start ========== */}
            <Tab.Container  defaultActiveKey="first" >
               <Nav variant="pills" className="tbgren">
                  <Row className="tbrw">
                     <Col md={6} xs={6} className="">
                     <Nav.Item>
                        <Nav.Link eventKey="first">Pending</Nav.Link>
                     </Nav.Item>
                     </Col>
                     <Col md={6} xs={6} className="">
                     <Nav.Item>
                        <Nav.Link eventKey="second">Completed</Nav.Link>
                     </Nav.Item>
                     </Col>
                  </Row>
               </Nav>
               <Row className="mx-0 mianhmdwncnt">
                  <Col className="mnhmcnt">
                  <Col md={12} xs={12} className="">
                  <Tab.Content id="moatab">
                     <Tab.Pane eventKey="first">
					 {pending.length >0 ? (
					 <div>
					  {pending && pending.map((val,index) => {
						return(
                        <Col md={12} xs={12} className="mcusbx">
						{val.status===0 ? (
                        <NavLink to="#" onClick={() =>handleShow4(val.id)}>
                           <Row className="mx-0 main-ord-box">
                              <Col md={2} xs={2} className="align-self-center px-1">
                              <img src= {val.image} className="img-fluid main-img" alt="Mcust Img"/></Col>
                              <Col md={6} xs={5} className="align-self-center mcusbx-txtcnt ps-md-2 ">
                              <h3>{val.name}</h3>
							  <div className="ord-brd-main">
                              <p>{val.date}</p>
							  </div>
                              </Col>
                              <Col md={4} xs={5} className="align-self-center text-end">
							  <span className="arwtxt">{val.status_name}</span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 10" fill="none">
                                 <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#3F9534"/>
                              </svg>
                              </Col>
                           </Row>
                        </NavLink> 
						) : (
							<NavLink to="#" onClick={() =>handleShow(val.id,val.status,val.status_name)}>
                           <Row className="mx-0 main-ord-box">
                              <Col md={2} xs={2} className="align-self-center px-1">
                              <img src= {val.image} className="img-fluid main-img" alt="Mcust Img"/></Col>
                              <Col md={6} xs={5} className="align-self-center mcusbx-txtcnt ps-md-2 ">
                              <h3>{val.name}</h3>
							  <div className="ord-brd-main">
                              <p>{val.date}</p>
							  </div>
                              </Col>
                              <Col md={4} xs={5} className="align-self-center text-end">
							  <span className="arwtxt">{val.status_name}</span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 10" fill="none">
                                 <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#3F9534"/>
                              </svg>
                              </Col>
                           </Row>
                        </NavLink>
						)}
                        </Col>
                        )
					  })} 
					  </div>
					  ) : (
						<Row className="mx-0">
							<p className="text-center mt-4">No data found</p>
						</Row>
				 )} 
                     </Tab.Pane>
                     <Tab.Pane eventKey="second">
					 {completed.length >0 ? (
					 <div>
					  {completed && completed.map((val,index) => {
						return(
                        <Col md={12} xs={12} className="mcusbx">
                         <Row className="mx-0 main-ord-box">
                           <Col md={2} xs={2} className="align-self-center px-1">
						   {(() => {
								if (val.status===1 || val.status===8) {
									return (<NavLink to={`/mer-receipts/${val.id}`}><img src= {val.image} className="img-fluid main-img" alt="Mcust Img"/></NavLink>)
								}else if (val.status===5) {
									return (<NavLink to={`/mer-refund-receipts/${val.id}`}><img src= {val.image} className="img-fluid main-img" alt="Mcust Img"/></NavLink>)
								}else if (val.status===7) {
									return (<NavLink to={`/mer-refund-request/${val.id}`}><img src= {val.image} className="img-fluid main-img" alt="Mcust Img"/></NavLink>)
								}else{
									return (<NavLink to={`/mer-order-detail/${val.id}`}><img src= {val.image} className="img-fluid main-img" alt="Mcust Img"/></NavLink>)
								}
						   })()}
						   
                           </Col>
                           <Col md={5} xs={5} className="align-self-center mcusbx-txtcnt ps-md-2 ">
                           <h3>
						   {(() => {
								if (val.status===1 || val.status===8) {
									return (<NavLink to={`/mer-receipts/${val.id}`}>{val.name}</NavLink>)
								}else if (val.status===5) {
									return (<NavLink to={`/mer-refund-receipts/${val.id}`}>{val.name}</NavLink>)
								}else if (val.status===7) {
									return (<NavLink to={`/mer-refund-request/${val.id}`}>{val.name}</NavLink>)
								}else{
									return (<NavLink to={`/mer-order-detail/${val.id}`}>{val.name}</NavLink>)
								}
						   })()}
						   </h3>
						   
						   {(() => {
							  if (val.status === 1 || val.status === 8) {
								return (
								  <NavLink to={`/mer-receipts/${val.id}`}>
									<div className="ord-brd-main">
									  <p>Conf. No. {val.order_number}</p>
									  <p>{val.date}</p>
									</div>
								  </NavLink>
								);
							  } else if (val.status === 5) {
								return (
								  <NavLink to={`/mer-refund-receipts/${val.id}`}>
									<div className="ord-brd-main">
									  <p>Conf. No. {val.order_number}</p>
									  <p>{val.date}</p>
									</div>
								  </NavLink>
								);
							  } else if (val.status === 7) {
								return (
								  <NavLink to={`/mer-refund-request/${val.id}`}>
									<div className="ord-brd-main">
									  <p>Conf. No. {val.order_number}</p>
									  <p>{val.date}</p>
									</div>
								  </NavLink>
								);
							  } else {
								return (
								  <NavLink to={`/mer-order-detail/${val.id}`}>
									<div className="ord-brd-main">
									  <p>Conf. No. {val.order_number}</p>
									  <p>{val.date}</p>
									</div>
								  </NavLink>
								);
							  }
							})()}

						   
						   
						   
                           </Col>
                           <Col md={5} xs={5} className="align-self-center text-end">
						   {(() => {
								if (val.status===6) {
									return (<span className="arwtxt arwtxthelp" onClick={() =>handleShow2(val.id)}>{val.status_name}</span>)
								}else if(val.status===7){
									return (<NavLink to={`/mer-refund-request/${val.id}`}><span className="arwtxt">{val.status_name}</span></NavLink>)
								}else if(val.status===1 || val.status===8){
									return (<NavLink to={`/mer-receipts/${val.id}`}><span className="arwtxt">{val.status_name}</span></NavLink>)
						   }else if(val.status===5){
									return (<NavLink to={`/mer-refund-receipts/${val.id}`}><span className="arwtxt">{val.status_name}</span></NavLink>)
							   }else{
									return (<NavLink to={`/mer-order-detail/${val.id}`}><span className="arwtxt">{val.status_name}</span></NavLink>)
								}
							})()}
						   
                           <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 6 10" fill="none">
                              <path d="M0.603935 9.79545C0.686317 9.93182 0.816994 10 0.995963 10C1.07835 10 1.18061 9.98295 1.22323 9.96023C1.31697 9.90909 5.92472 5.29545 5.97017 5.20739C6.00994 5.12784 6.00994 4.87216 5.97017 4.79261C5.92472 4.70455 1.31697 0.0909092 1.22323 0.0397722C1.18061 0.0170452 1.07834 2.15132e-07 0.995962 2.18733e-07C0.720407 2.30778e-07 0.547119 0.173295 0.547119 0.448863C0.547119 0.53125 0.561323 0.627841 0.578367 0.661932C0.595412 0.696022 1.5698 1.6875 2.74588 2.8608L4.87931 5L2.74588 7.13636C1.5698 8.3125 0.595413 9.30398 0.578368 9.33807C0.527234 9.44034 0.541438 9.69034 0.603935 9.79545Z" fill="#3F9534"/>
                           </svg>
                           </Col>
                         </Row>
                        </Col>
                        )
					   })}
						</div>
					  ) : (
						<Row className="mx-0">
							<p className="text-center mt-4">No data found</p>
						</Row>	
                       )} 						
                     </Tab.Pane>
                  </Tab.Content>
                  </Col>
                  </Col>
               </Row>
            </Tab.Container>
      {/* ========== Order Appointment Tab Content End ========== */}
            <Col>
            <div className="madcustmrpop">
      {/* ========== Order Appointment Order Details Popup Start ========== */}
               <Modal show={show} onHide={handleClose} className="popupwrngcnt" backdrop="static">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Order Details</h3>
                  </Modal.Header>
                  <Modal.Body>
                     <Col md={12} xs={12} className="" id="printablediv" ref={componentRef1}>
                     <Row className="mx-0 maincnt-recepts">
                  <Col md={10} xs={8}>
                  <h3>{inputs.store_name}</h3>
                  {(() => {
							if (inputs.store_address!==''){
							  return ( <p>{inputs.store_address} , {inputs.store_zip}</p>)
							}    
						  })()}
                  <p>Phone: {inputs.store_mobile}</p>
				  <div className="left-cnt-rcptr">
					<h4 className="">Customer Name: <span>{inputs.user_name}</span></h4>
					{inputs.agree===1 ?
					 <h4>Contact No: <span>{inputs.user_mobile}</span></h4>
					 : null }
					 {inputs.user_address_street_name!=='' ?
					<h4>Customer address: <span>{addressview(inputs.user_address_street_name,inputs.user_address_state,inputs.user_address_city,inputs.user_address_zip_code)}</span></h4>
						: null }
					
				  </div>
                  <div className="dwncmt">
                     <h5>Information:</h5>
					 <h4>Schedule date & time:<span>{inputs.due_date}</span></h4>
                     <h4>Invoice No.<span>{inputs.order_number}</span></h4>
                     <h4>Invoice Date: <span>{inputs.date}</span></h4>
					 {inputs.employee_name!=='' ?
					<h4>Employee Name: <span>{inputs.employee_name}</span></h4>
						: null }
                  </div>
                  </Col>
                  <Col md={2} xs={4} className="text-end left-cnt-rcpt">
                  <img src={inputs.store_image} className="img-fluid" alt="Hm Img"/>
					  
                  </Col>
                  <Col md={12} xs={12} className="tblinvcpg">
                  {/* ========== Table Content Start ========== */}
                  <Table responsive>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Service/Items</th>
                           <th>Price</th>
                           <th>Q’ty</th>
                           <th>Amount</th>
                        </tr>
                     </thead>
                     <tbody>
					  {orderdetail && orderdetail.map((val,index) => {
						return(
                        <tr>
						   <td>{index+1}</td>
                           <td>{val.product_name}</td>
                           <td>$<input type="text" className="ord-textbox" onChange={(e) => handleTextChange(val.id, e.target.value)} value={val.product_price} disabled={textBoxesDisabled}  /></td>
                           <td>{val.qty===0 ? 1 :val.qty}</td>
                           <td>${val.total}</td>
                        </tr>
                        )
					  })}  
                     </tbody>
                  </Table>
                  {/* ========== Table Content End ========== */}
				  <div className="text-end tbldwndv">
                  <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Subtotal</p>
                        </div>
                        <div className="pb-1"><span>${inputs.subtotal}</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Coupon or discount</p>
                        </div>
                        <div className="pb-1">-<span>${inputs.saving_amount}</span></div>
                     </Stack>
					 <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Tax ({inputs.sales_tax_rate_per}%)</p>
                        </div>
                        <div className="pb-1"><span>${inputs.sales_tax_rate}</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Gratuity</p>
                        </div>
                        <div className="pb-1 ps-3"><span> {inputs.tips} </span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4} className="lstprcdv">
                        <div className=" ms-auto">
                           <h6>Total Due</h6>
                        </div>
                        <div className="">
                           <h6>${inputs.total_due}</h6>
                        </div>
                     </Stack>
					 </div>
                  </Col>
                 {coupon.length >0 &&(
								<div className="mb-3 mt-3">
								<label className="form-label mb-4">Coupons</label>
							  <Row>
							  
								<Col md={12} xs={12}>
									<ul className="sldr-add">
										{coupon.length > 0 && coupon.map((item,index) => {
							        return(<li className="sldr-in-add">
										<div className="row mincrd">
										<Col md={12} xs={12} className="align-self-center mb-2">
											<h4>{item.coupon_type}</h4>
										   </Col>
											
											<Col md={12}>
												{(() => {
												if (item.coupon_type==='Buy & Get') {
													return (<p>Buy {item.buy_quantity} get {item.get_quantity} free,for {item.product_item_name}</p>)
												}else if(item.coupon_type==='Discount Offer'){
													return (  <p>{item.discount_percentage} % Discount When Purchase Over ${item.minimum_purchase_amount}</p>)
												}else if(item.coupon_type==='Loyalty Setup'){
													return (<p>Buy {item.buy_quantity_needed} {item.product_applicable_item_if_any} get {item.product_get_item_name} free</p>)
												}else if(item.coupon_type==='Welcome coupon'){
													return (<p>{item.discount_percentage}% for all new members joining your membership has been set up</p>)
												}								
												})()}
											   {(() => {
												if (item.coupon_type!=='Welcome coupon') {
													return (<p>{item.description}</p>)
												}									
												})()}	
											</Col>
											</div>
										</li>
										)})}
									</ul>
								</Col>
								
								</Row>
								</div>
							  )}
               </Row>
                     </Col>
                     <Col className="apmnt-ordrlstbtn">
                     <Row className="rwd mt-3">
                        <Col md={2} xs={2}>
                        <NavLink to="#" onClick={enableTextboxes}>
                           <Button variant="black" type="submit">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
                                 <path d="M12.8767 0.0441289C12.4517 0.134767 12.3329 0.22228 11.5546 0.991142L10.8389 1.70062L12.5704 3.42587L14.2989 5.15112L14.974 4.47602C15.6991 3.75092 15.8273 3.58214 15.9429 3.20396C15.9992 3.02894 16.0055 2.93518 15.9961 2.64138C15.9711 1.99754 15.8648 1.81626 14.9896 0.947385C14.2989 0.259785 14.1707 0.169147 13.7332 0.0566306C13.455 -0.015255 13.1674 -0.0183802 12.8767 0.0441289Z" fill="#3F9534"/>
                                 <path d="M5.42914 7.11404L0.993936 11.5491L0.496968 13.5306C0.209414 14.687 0 15.5746 0 15.6622C0 15.8778 0.118772 15.9966 0.334437 15.9966C0.421954 15.9966 1.30962 15.7872 2.46609 15.4996L4.44771 15.0027L8.88291 10.5677C11.3209 8.12981 13.315 6.12327 13.315 6.10452C13.315 6.06388 9.9331 2.68214 9.89247 2.68214C9.87372 2.68214 7.86709 4.67618 5.42914 7.11404Z" fill="#3F9534"/>
                              </svg>
                           </Button>
                        </NavLink>
                        </Col>
                        <Col md={5} xs={5} className="">
                        <NavLink to="#" onClick={handlePrint1}>
                           <Button variant="black" type="submit">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
                                 <path d="M3.23281 0.607982C3.11715 0.657998 3.01399 0.770535 2.97022 0.886198C2.94834 0.945592 2.93584 1.51453 2.93584 2.568V4.15915H2.11682C1.66355 4.15915 1.19777 4.17478 1.07585 4.19041C0.70073 4.24667 0.381875 4.44361 0.188062 4.74684C-0.0120039 5.05944 -0.00575189 4.91877 0.00362619 8.18234L0.0130043 11.1458L0.097407 11.324C0.269338 11.696 0.638209 11.9711 1.04459 12.0367C1.14775 12.0524 1.61666 12.068 2.08243 12.068H2.93271L2.94209 13.5966C2.95147 15.0877 2.95459 15.1284 3.01712 15.2128C3.0515 15.2597 3.12027 15.3284 3.16716 15.3628C3.25157 15.4285 3.25469 15.4285 8 15.4285C12.7453 15.4285 12.7484 15.4285 12.8328 15.3628C12.8797 15.3284 12.9485 15.2597 12.9829 15.2128C13.0454 15.1284 13.0485 15.0877 13.0579 13.5966L13.0673 12.068H13.9176C14.3833 12.068 14.8523 12.0524 14.9554 12.0367C15.3618 11.9711 15.7307 11.696 15.9026 11.324L15.987 11.1458L15.9964 8.18234C16.0058 4.91877 16.012 5.05944 15.8119 4.74684C15.6181 4.44361 15.2993 4.24667 14.9241 4.19041C14.8054 4.17478 14.3365 4.15915 13.8863 4.15915H13.0673L13.0579 2.52111C13.0485 0.920584 13.0485 0.879946 12.9829 0.795543C12.9485 0.748652 12.8797 0.67988 12.8328 0.645494C12.7484 0.579847 12.7391 0.579847 8.03751 0.573595C4.15811 0.567343 3.31096 0.573595 3.23281 0.607982ZM11.9701 2.90874V4.15915H8H4.02995V2.90874V1.65833H8H11.9701V2.90874ZM12.6078 6.28484C12.961 6.36612 13.1423 6.74437 12.9673 7.03196C12.8797 7.17576 12.7922 7.24453 12.6359 7.28205C12.4608 7.32894 11.2104 7.32581 11.0479 7.28205C10.8853 7.23516 10.7009 7.05072 10.6728 6.90067C10.6165 6.59745 10.7884 6.34424 11.0948 6.28172C11.2573 6.25046 12.4671 6.25046 12.6078 6.28484ZM11.9701 12.193V14.35H8H4.02995V12.193V10.0361H8H11.9701V12.193Z" fill="#3F9534"/>
                                 <path d="M5.45264 10.855C5.2557 10.9644 5.15254 11.1301 5.14941 11.3489C5.14941 11.549 5.24945 11.7241 5.41825 11.821L5.54642 11.896H8.00035H10.4543L10.5824 11.821C10.9513 11.6115 10.9388 11.0582 10.5574 10.8613C10.4855 10.8238 10.0635 10.8175 7.9941 10.8175C6.24352 10.8206 5.4964 10.83 5.45264 10.855Z" fill="#3F9534"/>
                                 <path d="M5.5558 12.5057C5.29946 12.5838 5.14941 12.7807 5.14941 13.0371C5.15254 13.2559 5.2557 13.4278 5.44326 13.5247C5.5683 13.5904 10.4324 13.5904 10.5574 13.5247C10.745 13.4278 10.8482 13.2559 10.8513 13.0371C10.8513 12.837 10.7513 12.662 10.5824 12.5651L10.4543 12.49L8.04724 12.4838C6.72493 12.4806 5.60269 12.49 5.5558 12.5057Z" fill="#3F9534"/>
                              </svg>
                              Print
                           </Button>
                        </NavLink>
                        </Col>
                        <Col md={5} xs={5}>
                        <NavLink to={`tel:${inputs.user_mobile}`}>
                           <Button variant="black" type="submit">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 18" fill="none">
                                 <path d="M2.69273 1.03486C2.33907 1.1444 2.24831 1.21951 1.29688 2.17407C0.42056 3.05039 0.361096 3.11299 0.242167 3.3571C0.173313 3.49794 0.0888113 3.72328 0.0575143 3.85786C-0.0175986 4.19274 -0.0207283 4.89692 0.0575143 5.32256C0.454987 7.50084 2.03862 10.086 4.46101 12.5084C6.90844 14.9589 9.51235 16.5676 11.6437 16.9526C11.9598 17.0089 12.0975 17.0152 12.5043 17.0026C13.0458 16.9839 13.2336 16.9463 13.5997 16.771C13.8188 16.6646 13.9033 16.5895 14.7765 15.7163C15.5996 14.8932 15.7311 14.7492 15.8187 14.5709C16.0534 14.0889 16.0597 13.5756 15.8406 13.1124C15.7311 12.8808 15.6716 12.8151 14.5449 11.6821C13.8971 11.0312 13.2836 10.4365 13.1835 10.3614C12.9143 10.158 12.7078 10.086 12.3166 10.0703C11.9347 10.0547 11.7031 10.1173 11.4183 10.3051C11.1367 10.4897 11.0177 10.6525 10.8268 11.0969C10.7079 11.3692 10.5702 11.5569 10.3793 11.7009C9.9286 12.0452 9.4435 12.1234 8.90519 11.9419C7.53438 11.485 5.91945 9.99835 5.23092 8.56181C5.06191 8.20502 4.89917 7.6761 4.89917 7.48519C4.90543 6.99383 5.29038 6.42422 5.76609 6.2114C6.26059 5.98606 6.33257 5.94537 6.49845 5.7795C6.86149 5.41645 7.01485 4.84998 6.88653 4.35235C6.78012 3.94236 6.71753 3.86725 5.47816 2.61849C4.11674 1.24455 4.02285 1.16631 3.57843 1.03799C3.36874 0.978525 2.87738 0.975395 2.69273 1.03486Z" fill="#3F9534"/>
                              </svg>
                              Call
                           </Button>
                        </NavLink>
                        </Col>
                     </Row>
                     </Col>
                     <Row className="my-3 ">
                        <Col className="apmnt-btndvpop">
                        <NavLink to="#" onClick={() => CancelOrder(inputs.id)}><Button variant="lft">Cancel Order</Button>{' '}</NavLink>
                        </Col>
                        <Col className="apmnt-btndvpop">
                        <NavLink to="#" ><Button variant="rgt" onClick={() => handleShow3(inputs.id)}>Done</Button>{' '}</NavLink>
                        </Col>
                        <Col md={12} xs={12} className="mt-md-3 mt-2">
                           <p><span className="fw-bold">Note: </span> When you click “Done” the invoice and pick up notification will send to the customer.</p>
                        </Col>
                     </Row>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
      {/* ========== Order Appointment Order Details Popup End ========== */}
	  {/* ========== Order Confirm Appointment Order Details Popup Start ========== */}
               <Modal show={show4} onHide={handleClose4} className="popupwrngcnt" backdrop="static">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Schedule Confirmation</h3>
                  </Modal.Header>
                  <Modal.Body>
                     <Col md={12} xs={12} className="" id="printablediv" ref={componentRef}>
						<Row className="mx-0 maincnt-recepts">
                  <Col md={10} xs={8}>
                  <h3>{inputs.store_name}</h3>
				  {(() => {
							if (inputs.store_address!==''){
							  return (<p>{inputs.store_address} , {inputs.store_zip}</p>)
							}    
						  })()}
                  <p>Phone: {inputs.store_mobile}</p>
				  <div className="left-cnt-rcptr">
				   <h4 className="">Customer Name: <span>{inputs.user_name}</span></h4>
				   {inputs.agree===1 ?
					  <h4>Contact No: <span>{inputs.user_mobile}</span></h4>
					  : null }
					  {inputs.user_address_street_name!=='' ?
					<h4>Customer address: <span>{addressview(inputs.user_address_street_name,inputs.user_address_state,inputs.user_address_city,inputs.user_address_zip_code)}</span></h4>
						: null }
					
						
					</div>
                  <div className="dwncmt">
                     <h5>Information:</h5>
					 <h4>Schedule date & time:<span>{inputs.due_date}</span></h4>
                     <h4>Invoice No.<span>{inputs.order_number}</span></h4>
                     <h4>Invoice Date: <span>{inputs.date}</span></h4>
					 {inputs.employee_name!=='' ?
					<h4>Employee Name: <span>{inputs.employee_name}</span></h4>
						: null }	
                  </div>
                  </Col>
                  <Col md={2} xs={4} className="text-end left-cnt-rcpt">
                  <img src={inputs.store_image} className="img-fluid" alt="Hm Img"/>
					  
                  </Col>
                  <Col md={12} xs={12} className="tblinvcpg">
                  {/* ========== Table Content Start ========== */}
                  <Table responsive>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Service/Items</th>
                           <th>Price</th>
                           <th>Q’ty</th>
                           <th>Amount</th>
                        </tr>
                     </thead>
                     <tbody>
					  {orderdetail && orderdetail.map((val,index) => {
						return(
                        <tr>
					  <td>{index+1}</td>
                           <td>{val.product_name}</td>
                           <td>$<input type="text" className="ord-textbox" onChange={(e) => handleTextChange(val.id, e.target.value)} value={val.product_price} disabled={textBoxesDisabled}  /></td>
                           <td>{val.qty===0 ? 1 :val.qty}</td>
                           <td>${val.total}</td>
                        </tr>
                        )
					  })}  
                     </tbody>
                  </Table>
                  {/* ========== Table Content End ========== */}
                  <div className="text-end tbldwndv">
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Subtotal</p>
                        </div>
                        <div className="pb-1"><span>${inputs.subtotal}</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Coupon or discount</p>
                        </div>
                        <div className="pb-1">-<span>${inputs.saving_amount}</span></div>
                     </Stack>
					 <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Tax ({inputs.sales_tax_rate_per}%)</p>
                        </div>
                        <div className="pb-1"><span>${inputs.sales_tax_rate}</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Gratuity</p>
                        </div>
                        <div className="pb-1 ps-3"><span> {inputs.tips} </span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4} className="lstprcdv">
                        <div className=" ms-auto">
                           <h6>Total Due</h6>
                        </div>
                        <div className="">
                           <h6>${inputs.total_due}</h6>
                        </div>
                     </Stack>
                  </div>
                  </Col>
                 {coupon.length >0 &&(
								<div className="mb-3 mt-3">
								<label className="form-label mb-4">Coupons</label>
							  <Row>
							  
								<Col md={12} xs={12}>
									<ul className="sldr-add">
										{coupon.length > 0 && coupon.map((item,index) => {
							        return(<li className="sldr-in-add">
										<div className="row mincrd">
										<Col md={12} xs={12} className="align-self-center mb-2">
											<h4>{item.coupon_type}</h4>
										   </Col>
											
											<Col md={12}>
												{(() => {
												if (item.coupon_type==='Buy & Get') {
													return (<p>Buy {item.buy_quantity} get {item.get_quantity} free,for {item.product_item_name}</p>)
												}else if(item.coupon_type==='Discount Offer'){
													return (  <p>{item.discount_percentage} % Discount When Purchase Over ${item.minimum_purchase_amount}</p>)
												}else if(item.coupon_type==='Loyalty Setup'){
													return (<p>Buy {item.buy_quantity_needed} {item.product_applicable_item_if_any} get {item.product_get_item_name} free</p>)
												}else if(item.coupon_type==='Welcome coupon'){
													return (<p>{item.discount_percentage}% for all new members joining your membership has been set up</p>)
												}							
												})()}
											   {(() => {
												if (item.coupon_type!=='Welcome coupon') {
													return (<p>{item.description}</p>)
												}									
												})()}	
											</Col>
											</div>
										</li>
										)})}
									</ul>
								</Col>
								
								</Row>
								</div>
							  )}
               </Row>
                     </Col>
                     <Col className="apmnt-ordrlstbtn">
                     <Row className="rwd mt-3">
                        <Col md={2} xs={2}>
                        <NavLink to="#" onClick={enableTextboxes}>
                           <Button variant="black" type="submit">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
                                 <path d="M12.8767 0.0441289C12.4517 0.134767 12.3329 0.22228 11.5546 0.991142L10.8389 1.70062L12.5704 3.42587L14.2989 5.15112L14.974 4.47602C15.6991 3.75092 15.8273 3.58214 15.9429 3.20396C15.9992 3.02894 16.0055 2.93518 15.9961 2.64138C15.9711 1.99754 15.8648 1.81626 14.9896 0.947385C14.2989 0.259785 14.1707 0.169147 13.7332 0.0566306C13.455 -0.015255 13.1674 -0.0183802 12.8767 0.0441289Z" fill="#3F9534"/>
                                 <path d="M5.42914 7.11404L0.993936 11.5491L0.496968 13.5306C0.209414 14.687 0 15.5746 0 15.6622C0 15.8778 0.118772 15.9966 0.334437 15.9966C0.421954 15.9966 1.30962 15.7872 2.46609 15.4996L4.44771 15.0027L8.88291 10.5677C11.3209 8.12981 13.315 6.12327 13.315 6.10452C13.315 6.06388 9.9331 2.68214 9.89247 2.68214C9.87372 2.68214 7.86709 4.67618 5.42914 7.11404Z" fill="#3F9534"/>
                              </svg>
                           </Button>
                        </NavLink>
                        </Col>
                        <Col md={5} xs={5} className="">
                        <NavLink to="#" onClick={handlePrint}>
                           <Button variant="black" type="submit">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
                                 <path d="M3.23281 0.607982C3.11715 0.657998 3.01399 0.770535 2.97022 0.886198C2.94834 0.945592 2.93584 1.51453 2.93584 2.568V4.15915H2.11682C1.66355 4.15915 1.19777 4.17478 1.07585 4.19041C0.70073 4.24667 0.381875 4.44361 0.188062 4.74684C-0.0120039 5.05944 -0.00575189 4.91877 0.00362619 8.18234L0.0130043 11.1458L0.097407 11.324C0.269338 11.696 0.638209 11.9711 1.04459 12.0367C1.14775 12.0524 1.61666 12.068 2.08243 12.068H2.93271L2.94209 13.5966C2.95147 15.0877 2.95459 15.1284 3.01712 15.2128C3.0515 15.2597 3.12027 15.3284 3.16716 15.3628C3.25157 15.4285 3.25469 15.4285 8 15.4285C12.7453 15.4285 12.7484 15.4285 12.8328 15.3628C12.8797 15.3284 12.9485 15.2597 12.9829 15.2128C13.0454 15.1284 13.0485 15.0877 13.0579 13.5966L13.0673 12.068H13.9176C14.3833 12.068 14.8523 12.0524 14.9554 12.0367C15.3618 11.9711 15.7307 11.696 15.9026 11.324L15.987 11.1458L15.9964 8.18234C16.0058 4.91877 16.012 5.05944 15.8119 4.74684C15.6181 4.44361 15.2993 4.24667 14.9241 4.19041C14.8054 4.17478 14.3365 4.15915 13.8863 4.15915H13.0673L13.0579 2.52111C13.0485 0.920584 13.0485 0.879946 12.9829 0.795543C12.9485 0.748652 12.8797 0.67988 12.8328 0.645494C12.7484 0.579847 12.7391 0.579847 8.03751 0.573595C4.15811 0.567343 3.31096 0.573595 3.23281 0.607982ZM11.9701 2.90874V4.15915H8H4.02995V2.90874V1.65833H8H11.9701V2.90874ZM12.6078 6.28484C12.961 6.36612 13.1423 6.74437 12.9673 7.03196C12.8797 7.17576 12.7922 7.24453 12.6359 7.28205C12.4608 7.32894 11.2104 7.32581 11.0479 7.28205C10.8853 7.23516 10.7009 7.05072 10.6728 6.90067C10.6165 6.59745 10.7884 6.34424 11.0948 6.28172C11.2573 6.25046 12.4671 6.25046 12.6078 6.28484ZM11.9701 12.193V14.35H8H4.02995V12.193V10.0361H8H11.9701V12.193Z" fill="#3F9534"/>
                                 <path d="M5.45264 10.855C5.2557 10.9644 5.15254 11.1301 5.14941 11.3489C5.14941 11.549 5.24945 11.7241 5.41825 11.821L5.54642 11.896H8.00035H10.4543L10.5824 11.821C10.9513 11.6115 10.9388 11.0582 10.5574 10.8613C10.4855 10.8238 10.0635 10.8175 7.9941 10.8175C6.24352 10.8206 5.4964 10.83 5.45264 10.855Z" fill="#3F9534"/>
                                 <path d="M5.5558 12.5057C5.29946 12.5838 5.14941 12.7807 5.14941 13.0371C5.15254 13.2559 5.2557 13.4278 5.44326 13.5247C5.5683 13.5904 10.4324 13.5904 10.5574 13.5247C10.745 13.4278 10.8482 13.2559 10.8513 13.0371C10.8513 12.837 10.7513 12.662 10.5824 12.5651L10.4543 12.49L8.04724 12.4838C6.72493 12.4806 5.60269 12.49 5.5558 12.5057Z" fill="#3F9534"/>
                              </svg>
                              Print
                           </Button>
                        </NavLink>
                        </Col>
                        <Col md={5} xs={5}>
                        <NavLink to={`tel:${inputs.user_mobile}`}>
                           <Button variant="black" type="submit">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 18" fill="none">
                                 <path d="M2.69273 1.03486C2.33907 1.1444 2.24831 1.21951 1.29688 2.17407C0.42056 3.05039 0.361096 3.11299 0.242167 3.3571C0.173313 3.49794 0.0888113 3.72328 0.0575143 3.85786C-0.0175986 4.19274 -0.0207283 4.89692 0.0575143 5.32256C0.454987 7.50084 2.03862 10.086 4.46101 12.5084C6.90844 14.9589 9.51235 16.5676 11.6437 16.9526C11.9598 17.0089 12.0975 17.0152 12.5043 17.0026C13.0458 16.9839 13.2336 16.9463 13.5997 16.771C13.8188 16.6646 13.9033 16.5895 14.7765 15.7163C15.5996 14.8932 15.7311 14.7492 15.8187 14.5709C16.0534 14.0889 16.0597 13.5756 15.8406 13.1124C15.7311 12.8808 15.6716 12.8151 14.5449 11.6821C13.8971 11.0312 13.2836 10.4365 13.1835 10.3614C12.9143 10.158 12.7078 10.086 12.3166 10.0703C11.9347 10.0547 11.7031 10.1173 11.4183 10.3051C11.1367 10.4897 11.0177 10.6525 10.8268 11.0969C10.7079 11.3692 10.5702 11.5569 10.3793 11.7009C9.9286 12.0452 9.4435 12.1234 8.90519 11.9419C7.53438 11.485 5.91945 9.99835 5.23092 8.56181C5.06191 8.20502 4.89917 7.6761 4.89917 7.48519C4.90543 6.99383 5.29038 6.42422 5.76609 6.2114C6.26059 5.98606 6.33257 5.94537 6.49845 5.7795C6.86149 5.41645 7.01485 4.84998 6.88653 4.35235C6.78012 3.94236 6.71753 3.86725 5.47816 2.61849C4.11674 1.24455 4.02285 1.16631 3.57843 1.03799C3.36874 0.978525 2.87738 0.975395 2.69273 1.03486Z" fill="#3F9534"/>
                              </svg>
                              Call
                           </Button>
                        </NavLink>
                        </Col>
                     </Row>
                     </Col>
                     <Row className="my-3 ">
                        <Col className="apmnt-btndvpop">
                        <NavLink to="#" onClick={() => CancelOrder(inputs.id)}><Button variant="lft">Cancel Order</Button>{' '}</NavLink>
                        </Col>
                        <Col className="apmnt-btndvpop">
                        <NavLink to="#" onClick={() => handleShow5(inputs.id)}><Button variant="rgt">Schedule Confirmation</Button>{' '}</NavLink>
                        </Col>
                        <Col md={12} xs={12} className="mt-md-3 mt-2">
                           <p><span className="fw-bold">Note: </span> When you click on "Schedule Confirmation", it will notify the customer and save the order schedule to both of your calendard.</p>
                        </Col>
                     </Row>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
			   
			   
			  
			   
			   {/* ========== Delete Popup Start ========== */}
                    <Modal show={show5} onHide={handleClose5} className="popupwrngcnt" backdrop="static">
                       <Modal.Header>
                          
                       </Modal.Header>
                       <Modal.Body>
                         <Row className="cnfmtnpg justify-content-center mx-0">
                           <Col md={12} xs={12} className="px-0 ">
                            <div className="text-center ">
                              <img src= {'assets/images//svg/confrmation-check-mer.svg'} className="img-fluid mb-3" alt="Cnfrmation Img"/> 
                              <h3>You have confirmed your schedule. the confirmation will be sent to the customer and saved to your My Order page and Calendar.</h3>
                            </div>
                           </Col>
                           <Col md={8} xs={8} className="btn-ordrcnfrmation btn-ordrcnfrmation-mer text-center mt-3 mb-5">
                            <NavLink to="#" onClick={() => window.location.href = "/mer-order-appointment"}><Button variant="home">Done</Button>{' '}</NavLink>
                           </Col>
                         </Row>


                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Delete Popup End ========== */}
      {/* ========== Order Confirm Appointment Order Details Popup End ========== */}
            </div>
            <div className="madcustmrpop">
      {/* ========== Order Appointment Refund Order Details Popup Start ========== */}
               <Modal show={show1} onHide={handleClose1} className="popupwrngcnt" backdrop="static">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Refund Order Details</h3>
                  </Modal.Header>
                  <Modal.Body>
                     <Col md={12} xs={12} className="ppinpt ordr-apinmnt">
                     <Row className="mx-0">
                        <Col md={6} xs={6} className="lfttxt">
                        <p>Customer Name</p>
                        </Col>
                        <Col md={6} xs={6} className="text-end rgttxt">
                        <p>{inputs.name}</p>
                        </Col>
                        <Col md={6} xs={6} className="lfttxt">
                        <p>Contact Info</p>
                        </Col>
                        <Col md={6} xs={6} className="text-end rgttxt">
                        <p>{inputs.mobile}</p>
                        </Col>
                        <Col md={6} xs={6} className="lfttxt">
                        <p>Confirmation No.</p>
                        </Col>
                        <Col md={6} xs={6} className="text-end rgttxt">
                        <NavLink to="#"> {inputs.order_number}</NavLink>
                        </Col>
                        <Col md={6} xs={6} className="lfttxt">
                        <p>Order Date</p>
                        </Col>
                        <Col md={6} xs={6} className="text-end rgttxt">
                        <p>{inputs.date}</p>
                        </Col>
                        <Col md={6} xs={6} className="lfttxt">
                        <p>Order Time</p>
                        </Col>
                        <Col md={6} xs={6} className="text-end rgttxt">
                        <p>{inputs.time}</p>
                        </Col>
                        <Col md={6} xs={6} className="lfttxt">
                        <p>Order Details</p>
                        </Col>
                        <Col md={6} xs={6} className="text-end rgttxt">
                        <p>-</p>
                        </Col>
						
						{orderdetail && orderdetail.map((val,index) => {
						return(
						<>
							<Col md={6} xs={6} className="lfttxt">
							<p>{val.product_name}</p>
							</Col>
							<Col md={6} xs={6} className="text-end rgttxt">
							<p>$<input type="text" className="ord-textbox" onChange={(e) => handleTextChange(val.id, e.target.value)} value={val.product_price} disabled={textBoxesDisabled}  /></p>
							</Col>
							</>
                        )
					  })} 
                     </Row>
                     </Col>
                     <Col className="apmnt-ordrlstbtn my-3">
                      <h3 className="mdlttltxt">Are you sure to want to refund for this order?</h3>
                     </Col>
                     <Row className="my-3 ">
                        <Col className="apmnt-btndvpop">
                        <NavLink to="#" onClick={() => refundorder(inputs.id,0)}><Button variant="lft">No</Button>{' '}</NavLink>
                        </Col>
                        <Col className="apmnt-btndvpop">
                        <NavLink to="#" onClick={() => refundorder(inputs.id,1)}><Button variant="rgt">Yes</Button>{' '}</NavLink>
                        </Col>
                     </Row>
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
      {/* ========== Order Appointment Refund Order Details Popup End ========== */}
            </div>
            </Col>

                 <Col className="md={12} xs={12}">
                 <div className="inmodal">
                 {/* ========== Order Status Popup Start ========== */}
                    <Modal show={show2} onHide={handleClose2} className="popupwrngcnt" backdrop="static">
                       <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Please click Yes to send out Receipt to customer?</h3>
                       </Modal.Header>
                       <Modal.Body>
                          <Row>
                             <Col className="btn-clr">
                             <NavLink to="#" onClick={completeorder}><Button variant="">Yes</Button></NavLink>
                             </Col>
                             <Col className="btn-dv">
                             <NavLink to="#" onClick={handleClose2}><Button variant="">No</Button></NavLink>
                             </Col>
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Order Status Popup End ========== */}
				  {/* ========== Order Refund Status Popup Start ========== */}
                    <Modal show={showre} onHide={handleClosere} className="popupwrngcnt" backdrop="static">
                       <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Please click on “YES” to refund, or “No” to refuse</h3>
                       </Modal.Header>
                       <Modal.Body>
                          <Row>
                             <Col className="btn-clr">
                             <NavLink to="#" onClick={refund_order}><Button variant="">Yes</Button></NavLink>
                             </Col>
                             <Col className="btn-dv">
                             <NavLink to="#" onClick={refund_order_no}><Button variant="">No</Button></NavLink>
                             </Col>
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Order Refund Status Popup End ========== */}
                 </div>
                 </Col>

                 <Col className="md={12} xs={12}">
                 <div className="inmodal">
                 {/* ========== Delete Popup Start ========== */}
                    <Modal show={show3} onHide={handleClose3} className="popupwrngcnt" backdrop="static">
                       <Modal.Header >
                       </Modal.Header>
                       <Modal.Body>
                         <Row className="cnfmtnpg justify-content-center mx-0">
                           <Col md={12} xs={12} className="px-0 ">
                            <div className="text-center ">
                              <img src= {'assets/images//svg/confrmation-check-mer.svg'} className="img-fluid mb-3" alt="Cnfrmation Img"/> 
                              <h3>Invoice generated and sent to the customer.</h3>
                            </div>
                           </Col>
                           <Col md={8} xs={8} className="btn-ordrcnfrmation btn-ordrcnfrmation-mer text-center mt-3 mb-5">
                            <NavLink to="/mer-dashboard"><Button variant="home">Done</Button>{' '}</NavLink>
                           </Col>
                         </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Delete Popup End ========== */}
				  
				  
				  
                 </div>
                 </Col>

		{loading && (
		   <div className="loadingClass">
				<img src={loader} alt="loader"/>
			</div>
		   )}  
         </Container>
      </section>
      {/* ========== Footer Start ========== */}
      <Footer/>
      {/* ========== Footer End ========== */}
      {/* ========== Merchant Order Appointment Page End ========== */}
        </>
    )
}
export default MerOrder;